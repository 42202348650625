import Box from "@mui/material/Box";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/* import Typography from '@mui/material/Typography'; */
import { useTranslation } from 'react-i18next';
/* import PerfectScrollbar from 'react-perfect-scrollbar'; */
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { RouteHelper } from "../../../classes/RouteHelper";
import closedIcon from '../../../res/svg/closed.svg';
import escalatorDownIcon from '../../../res/svg/escalator-down.svg';
import escalatorUpIcon from '../../../res/svg/escalator-up.svg';
import notEscalatorDownIcon from '../../../res/svg/not-escalator-down.svg';
import notEscalatorUpIcon from '../../../res/svg/not-escalator-up.svg';
import liftDownIcon from '../../../res/svg/lift-down.svg';
import liftUpIcon from '../../../res/svg/lift-up.svg';
import rampDownIcon from '../../../res/svg/ramp-down.svg';
import rampUpIcon from '../../../res/svg/ramp-up.svg';
import otherIcon from '../../../res/svg/other.svg';
import disabledIcon from '../../../res/svg/disabled.svg';
import stairDownIcon from '../../../res/svg/stair-down.svg';
import stairUpIcon from '../../../res/svg/stair-up.svg';
import Icon from "@mui/material/Icon";
import { GeomBuilder } from "../../../classes/GeomBuilder";
/* import Typography from "@mui/material/Typography"; */


const accordionStyleSx = {
    width: '100%',
    backgroundColor: '#0D3B4D',
    boxShadow: 'none',
    fontSize: '1.4em',
    fontWeight: '800',
    '&:before': {
        display: 'none',
    },
};


/* const listBoxStyle = {
    width: '95%',
    backgroundColor: '#092935',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    marginBottom: '5px',
    borderRadius: '4px',
}; */
const accordionDetailsSx = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#0D3B4D',
    boxShadow: 'none',
};
/* const listFontSx = { fontWeight: '700', fontSize: '1rem' };
const listFontSmallSx = { fontWeight: '500', fontSize: '0.5rem' }; */




interface IRouteNetAccordion {
    pointsList: string[],
    linksList: string[],
    rh: RouteHelper,
    gb: GeomBuilder
}

export default function RouteNetAccordion(props: React.PropsWithChildren<IRouteNetAccordion>) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        console.log("handleChange to ", panel);
        setExpanded(newExpanded ? panel : false);
    };

    const getPointColor = (id: string): string => {
        let colorString: string = "";
        try {
            colorString = props.rh.getPointTypeColor(id);
        } catch (error) {
            // mach nix
        }
        return colorString;
    }

    const getLinkType = (id: string): string => {
        let typeString: string = "";
        try {
            typeString = props.rh.getLinkType(id);
        } catch (error) {
            // mach nix
        }
        return typeString;
    }

    const renderRowPoints = (propsRow: ListChildComponentProps) => {
        const { index, style } = propsRow;

        return (
            <ListItem style={style} key={index} component="div" disableGutters /* disablePadding */ sx={{ backgroundColor: '#092935', height: '41px !important', width: '97% !important' }} onPointerLeave={() => props.gb.deHighlightMesh(props.pointsList[index])}>
                <ListItemButton onPointerOver={() => props.gb.highlightMesh(props.pointsList[index])} onClick={() => props.gb.focusOnMesh(props.pointsList[index])}>
                    <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(props.pointsList[index]), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                    <ListItemText primary={props.pointsList[index]} />
                </ListItemButton>
            </ListItem>
        );
    }

    /* const highlightMesh = (id: string) => {
        props.gb.highlightMesh(id);
    }

    const deHighlightMesh = (id: string) => {
        props.gb.deHighlightMesh(id);
    } */

    const renderRowLinks = (propsRow: ListChildComponentProps) => {
        const { index, style } = propsRow;

        return (
            <ListItem style={style} key={index} component="div" disablePadding sx={{ backgroundColor: '#092935', height: '41px !important', width: '97% !important', justifyContent: 'center !important' }} onPointerLeave={() => props.gb.deHighlightMesh(props.linksList[index])}>
                <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }} onPointerOver={() => props.gb.highlightMesh(props.linksList[index])} onClick={() => props.gb.focusOnMesh(props.linksList[index])}>
                    <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(props.linksList[index].substring(0, 8)), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                    <ListItemText primary={props.linksList[index].substring(0, 8)} sx={{ textAlign: 'center' }} />
                    <ListItemIcon sx={{ textAlign: 'center', justifyContent: 'center' }} >

                        {/* 
                        import closedIcon from '../../../res/svg/closed.svg';
                        import escalatorDownIcon from '../../../res/svg/escalator-down.svg';
                        import escalatorUpIcon from '../../../res/svg/escalator-up.svg';
                        import notEscalatorDownIcon from '../../../res/svg/not-escalator-down.svg';
                        import notEscalatorUpIcon from '../../../res/svg/not-escalator-up.svg';
                        import liftDownIcon from '../../../res/svg/lift-down.svg';
                        import liftUpIcon from '../../../res/svg/lift-up.svg';
                        import rampDownIcon from '../../../res/svg/ramp-down.svg';
                        import rampUpIcon from '../../../res/svg/ramp-up.svg';
                        import otherIcon from '../../../res/svg/other.svg';
                        import disabledIcon from '../../../res/svg/disabledIcon.svg';
                        import stairDownIcon from '../../../res/svg/stair-down.svg';
                        import stairUpIcon from '../../../res/svg/stair-up.svg';
                    */}
                        {(() => {
                            switch (getLinkType(props.linksList[index])) {
                                case "default":
                                    return <SwapHorizIcon />
                                case "other":
                                    return <Icon>
                                        <img alt='Icon' src={otherIcon} height={24} width={24} />
                                    </Icon>
                                case "stair-up":
                                    return <Icon>
                                        <img alt='Icon' src={stairUpIcon} height={24} width={24} />
                                    </Icon>
                                case "stair-down":
                                    return <Icon>
                                        <img alt='Icon' src={stairDownIcon} height={24} width={24} />
                                    </Icon>
                                case "escalator-up":
                                    return <Icon>
                                        <img alt='Icon' src={escalatorUpIcon} height={24} width={24} />
                                    </Icon>
                                case "not-escalator-up":
                                    return <Icon>
                                        <img alt='Icon' src={notEscalatorUpIcon} height={24} width={24} />
                                    </Icon>
                                case "not-escalator-down":
                                    return <Icon>
                                        <img alt='Icon' src={notEscalatorDownIcon} height={24} width={24} />
                                    </Icon>
                                case "escalator-down":
                                    return <Icon>
                                        <img alt='Icon' src={escalatorDownIcon} height={24} width={24} />
                                    </Icon>
                                case "lift-up":
                                    return <Icon>
                                        <img alt='Icon' src={liftUpIcon} height={24} width={24} />
                                    </Icon>
                                case "lift-down":
                                    return <Icon>
                                        <img alt='Icon' src={liftDownIcon} height={24} width={24} />
                                    </Icon>
                                case "ramp-up":
                                    return <Icon>
                                        <img alt='Icon' src={rampUpIcon} height={24} width={24} />
                                    </Icon>
                                case "ramp-down":
                                    return <Icon>
                                        <img alt='Icon' src={rampDownIcon} height={24} width={24} />
                                    </Icon>
                                case "disabled":
                                    return <Icon>
                                        <img alt='Icon' src={disabledIcon} height={24} width={24} />
                                    </Icon>
                                case "closed":
                                    return <Icon>
                                        <img alt='Icon' src={closedIcon} height={24} width={24} />
                                    </Icon>
                                default:
                                    return null
                            }
                        })()}
                    </ListItemIcon>
                    <ListItemText primary={props.linksList[index].substring(9)} sx={{ textAlign: 'center' }} />
                    <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(props.linksList[index].substring(9)), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                </ListItemButton>
            </ListItem>
        );
    }



    return (
        <Box sx={{ maxHeight: '60vh' }}>
            <Accordion expanded={expanded === 'panel1'} disableGutters onChange={handleChange('panel1')} sx={accordionStyleSx}>
                <AccordionSummary sx={{
                    backgroundColor: '#0D3B4D',
                    marginTop: '0 !important',
                    minHeight: '2em',
                    '& .MuiAccordionSummary-content': {
                        marginTop: '0.2em !important',
                        marginBottom: '0.2em !important'
                    }
                }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {t("tools.routes.points") + " (" + props.pointsList.length + ")"}
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsSx}>
                    {/* {props.pointsList.map(point =>
                                    <Box key={"pointID_" + point} sx={listBoxStyle}>
                                        <Typography align='left' sx={listFontSx}>{point}</Typography>
                                    </Box>)
                                } */}
                    <FixedSizeList
                        height={500}
                        width={'18vw'}
                        itemSize={46}
                        itemCount={props.pointsList.length}
                        overscanCount={10}

                        className="virtcontent"
                    >
                        {renderRowPoints}
                    </FixedSizeList>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} disableGutters onChange={handleChange('panel2')} sx={accordionStyleSx}>
                <AccordionSummary sx={{
                    backgroundColor: '#0D3B4D',
                }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    {t("tools.routes.links") + " (" + props.linksList.length + ")"}
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsSx}>
                    {/* {props.linksList.map(link =>
                                    <Box key={"linkID_" + link} sx={listBoxStyle}>
                                        <Typography align='left' sx={listFontSx}>{link}</Typography>                                        
                                    </Box>)                                    
                                } */}
                    <FixedSizeList
                        height={500}
                        width={'100%'}
                        itemSize={46}
                        itemCount={props.linksList.length}
                        overscanCount={10}
                        className="virtcontent"
                    >
                        {renderRowLinks}
                    </FixedSizeList>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}