import { ApiProject, langObject } from '../../interfaces/commonInterfaces';
import { useAppSelector } from '../hooks';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FilterButton from '../smallComponents/FilterButton';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MenuLeftSide from '../smallComponents/menuLeftSide';
import MatchAccordions from './projectComponents/areas_matchAccordions';
import { useTranslation } from 'react-i18next';
import { SortableContext } from '@dnd-kit/sortable';
import { MapData, UploadedFiles } from '../../interfaces/interfaceGuiController';
import { useLazyGetMapQuery, useLazyGetProjectQuery } from '../slices/apiSlice';
import Feature from 'ol/Feature.js';
import AreasMap from './projectComponents/areas_map';
import { RouteHelper } from '../../classes/RouteHelper';
import { AreasHelper } from '../../classes/AreasHelper';
import { BuildAreaIngredients, iAreasData, iAreasDataContext, svgToGeoTransformPropsObject } from '../../interfaces/area_interfaces';



// ANCHOR Interfaces


// ANCHOR Style consts

const areaToolsBox = {
  width: '100%',
  display: 'flex',
  height: '85vh',
  Position: 'relative',
};

const CssWhiteButton = styled(Button)({
  '& .MuiButton-root:hover': {
    color: '#ffffff',
  },
});
const smallBtnSx = {
  color: '#ffffff',
  border: 'solid #ffffff 1px',
  borderRadius: '4px',
  padding: '6px 16px 6px 12px',
  gap: '8px',
}
const filterBtnSmall = {
  color: '#ffffff',
  border: 'solid #ffffff 1px',
  borderRadius: '24px',
  padding: '6px 16px 6px 12px',
  gap: '8px',
  height: '28px',
  marginBottom: '18px',
}



let currentMapUuid: string = "";
let defaultLayerIndex: number = 0;
let oRouteHelper: RouteHelper;
let svgToGeoTransformProperties: svgToGeoTransformPropsObject;
let ignore = false;
let areasHelper: AreasHelper;
export const AreasDataContext = React.createContext<iAreasDataContext>({
  areasData: [],
  setAreasData: () => { },
});



export default function Tool_areas() {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const [netPoints, setNetPoints] = React.useState<string[]>([]);
  const [pointFeatures, setPointFeatures] = React.useState<object[]>([{}]);
  const [areasFeatures, setAreasFeatures] = React.useState<Feature[][] | null>(null);
  const [areasData, setAreasData] = React.useState<iAreasData[]>([]);
  const [areaMapData, setAreaMapData] = React.useState<MapData>();
  const [selectedLayerIndex, setSelectedLayerIndex] = React.useState(0);
  const [getProject] = useLazyGetProjectQuery();
  const [getMap] = useLazyGetMapQuery();
  const [apiProject, setApiProject] = React.useState<ApiProject>({
    "projectId": 0,
    "name": "",
    "shortName": "",
    "description": "",
    "comment": "",
    "city": "",
    "openingTime": "10:00:00",
    "closingTime": "20:00:00",
    "country": "DE",
    "active": true,
    "hasService": false,
    "market": "",
    "tags": [],
    "links": [],
    "products": [],
    "addons": [],
    "demoGalleryEnabled": false,
    "demoGalleryPublic": false,
    "mapUuid": "",
    "languages": [
      {
        "code": "de",
        "name": "German"
      },
    ],
    "createdAt": "2023-08-12T15:29:39.000000Z",
    "modifiedAt": "2023-08-12T15:29:39.000000Z",
    "timezone": null
  })

  // ANCHOR useEffects logs
  // React.useEffect(() => {
  //   if (areaMapData?.layerIds) {
  //     console.log('Aktuelles Layer: ', areaMapData.layerIds[selectedLayerIndex]); //TODO delete     
  //   }
  // }, [selectedLayerIndex, areaMapData?.layerIds]);


  // ANCHOR useEffects
  React.useEffect(() => {
    if (currentProject > 99999 && currentProjectTool === 2 && !ignore) {
      oRouteHelper = new RouteHelper({ debug: true });
      oRouteHelper.initRouteService(currentProject);
      waitForRouteHelper();

      return () => {
        ignore = true;
      };
    }
  }, [currentProjectTool, currentProject]);


  // React.useEffect(() => {
  //   if (areaMapData?.layerIds && oRouteHelper && areasHelper) {

  //   }
  // }, [areaMapData]);


  React.useEffect(() => {
    console.log(areasData);
    if (areasData && areaMapData?.layerIds) {
      const areasFeaturesForLayers: Feature[][] = areasHelper.convertToOLFeatures(areasData, areaMapData.layerIds);
      setAreasFeatures(areasFeaturesForLayers);
      const geoPointFeatures = areasHelper.getNetFeatureCollections(oRouteHelper.getGeoPointObjects(), areaMapData?.layerIds);
      setPointFeatures(geoPointFeatures);
    }

  }, [areasData])


  if (currentProject === 0) {
    return null;
  }


  if (currentProject !== 0 && currentProjectTool !== 2) {
    return null;
  }


  // SECTION get Data

  const waitForRouteHelper = () => {
    console.log("######################## ToolROUTES MOUNTED waitForRouteHelper...");
    window.setTimeout(() => {
      if (oRouteHelper.getInitReady() === true) {
        console.log("######################## ToolROUTES MOUNTED waitForRouteHelper SUCCESS!!!");
        setNetPoints(oRouteHelper.getAllPoints());
        setupAreasHelper()
        queryProject();
      }
      else {
        setNetPoints([]);
        waitForRouteHelper();
      }
    }, 1000);
  }

  const setupAreasHelper = () => {
    svgToGeoTransformProperties = oRouteHelper.getSvgToGeoTransformProperties();
    areasHelper = new AreasHelper(svgToGeoTransformProperties);
  }


  const nullCheck = (str: string | null): boolean => {
    let bNullValue = false;
    if (str === null) {
      bNullValue = true;
    }
    if (str === undefined) {
      bNullValue = true;
    }
    if (str === "") {
      bNullValue = true;
    }
    return bNullValue;
  }


  // ANCHOR init Project
  const queryProject = async () => {
    await getProject(currentProject)
      .then((response: any) => {
        const apiTempProject: ApiProject = response.data.data as ApiProject;
        setApiProject(apiTempProject);
        if (nullCheck(apiTempProject.mapUuid) === true) {
          console.error('No mapUuid found');
        }
        else {
          currentMapUuid = apiTempProject.mapUuid as string;
          console.log("currentMapUuid in Areas LOADED: ", currentMapUuid);
          console.log("calling Map in Areas Tool");
          queryMap();
          // load map
        }
      });
  }

  // ANCHOR map data
  const queryMap = async () => {
    await getMap(currentMapUuid)
      .then((response: any) => {
        //console.log("queryMap response: ", response);
        let mapData: MapData = response.data.data.mapData as MapData;
        setAreaMapData(mapData);
        console.clear()
        defaultLayer(mapData);
      });

  }


  // ANCHOR Layer 
  function defaultLayer(mapData: MapData) {
    let groundLayerIndex: number | undefined;
    if (mapData?.layerNames !== undefined) {
      mapData.layerNames.forEach(name => {
        if (isGroundFloor(name.shortName)) {
          groundLayerIndex = mapData?.layerNames?.indexOf(name)
        }
        groundLayerIndex !== undefined ? defaultLayerIndex = groundLayerIndex : defaultLayerIndex = 0;
      });
    }
    setSelectedLayerIndex(defaultLayerIndex);
  }


  const isGroundFloor = (shortName: string[]) => {
    return shortName.includes('0') || shortName.includes('EG')
  }

  const handlePreviewLayerChange = (index: number) => {
    setSelectedLayerIndex(index);
  }


  const getLayerShortName = (index: number): string => {
    let layerShortName: string = "";
    let germanIndexPosition: number = -1;
    let englishIndexPosition: number = -1;
    let selectedLanguageIndex: number = -1;
    if (areaMapData?.layerNames !== undefined) {
      const langObjectList: langObject[] = apiProject.languages;
      const shortNameList: string[] = areaMapData?.layerNames[index].shortName;
      langObjectList.forEach((element, indexPos) => {
        if (element.code === "de") {
          germanIndexPosition = indexPos;
        }
        if (element.code === "en") {
          englishIndexPosition = indexPos;
        }
      });
      if (germanIndexPosition !== -1) {
        selectedLanguageIndex = germanIndexPosition;
      }
      if (germanIndexPosition === -1 && englishIndexPosition !== -1) {
        selectedLanguageIndex = englishIndexPosition;
      }
      if (germanIndexPosition === -1 && englishIndexPosition === -1) {
        selectedLanguageIndex = 0;
      }
      layerShortName = shortNameList[selectedLanguageIndex]
    }
    return layerShortName;
  }


  // !SECTION get Data

  // ANCHOR svg file input  
  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };


  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('parse File');

    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target?.result;
        typeof fileContent === "string" ? processFile(fileContent) : console.error('invalid file');
      }
      reader.readAsText(selectedFile);
    }
    e.target.value = '';
  };


  const processFile = (fileContent: string) => {
    if (!netPoints || !selectedLayerIndex || !areaMapData?.layerIds) {
      console.error('need Route data and map data');
      return
    }
    const dataForHighlighters: BuildAreaIngredients = {
      netPoints: netPoints,
      selectedLayerIndex: selectedLayerIndex,
      mapLayers: areaMapData.layerIds,
    }
    const areas: iAreasData[] | undefined = areasHelper.parseSVGContent(fileContent, dataForHighlighters);

    if (!areas) {
      console.error('Proccessing SVG failed');
      return
    }
    setAreasData(areas);
  }



  // ANCHOR Return component
  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      margin: '0',
      padding: '0 ',
      background: 'linear-gradient(#092935 0%, #092935 100%) 0% 0% no-repeat padding-box',
      position: 'relative',
    }}>
      <AreasDataContext.Provider value={{ areasData, setAreasData }}>
        {/* ANCHOR Header-bar */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '34px' }}>
          <Typography gutterBottom align='left' sx={{
            fontWeight: '700', fontSize: '36px', padding: '10px 0 10px 52px', marginBottom: '0'
          }}>{t('tools.areas.title')}</Typography>

          <CssWhiteButton
            type="submit"
            /* fullWidth */
            /* disabled={determinDisabledSave()} */
            /* disabled={bDisableSave} */
            variant="contained"
            /* onClick={saveChangesToMap} */
            sx={{
              backgroundColor: 'primary.contrastText',
              color: 'primary.main',
              width: 'fit-content',
              '&:hover': {
                color: 'primary.contrastText',
              },
            }}
          >
            {t('tools.areas.save')}
            <SaveIcon sx={{
              marginLeft: '6px'
            }}></SaveIcon>
          </CssWhiteButton>

        </Box>

        <Box sx={areaToolsBox} >

          <Box sx={{ width: '78.26%', height: '100%', backgroundColor: 'blue' }}>

            {areaMapData !== undefined && pointFeatures !== undefined ?
              <AreasMap mapData={areaMapData} projectId={currentProject} mapUuid={currentMapUuid} layerIndex={selectedLayerIndex} geoPointFeatures={pointFeatures} areasFeatures={areasFeatures}></AreasMap>
              : null
            }
            {areaMapData !== undefined ?
              <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 10, top: '50%', left: '2%', transform: 'translateY(-50%)' }}>
                {areaMapData?.layerIds?.map((item, index) => {
                  return (
                    <Button variant="contained" color={selectedLayerIndex === index ? 'secondary' : 'primary'} key={"buttonFloor_" + index}
                      sx={{ mb: '0.5em', borderRadius: '100%', minWidth: '3em', width: '3.5em', height: '3.5em', padding: '0.5em' }}
                      onClick={() => handlePreviewLayerChange(index)}>{getLayerShortName(index)}</Button>
                  );
                })}
              </Box>
              : null
            }

          </Box>

          {/* ANCHOR Menu on the Left */}
          <MenuLeftSide searchPlaceholder={t('tools.areas.search')}>

            <FilterButton menuFilter={['Kartenbereich', 'BeispielFilter']} />


            {areaMapData !== undefined ? <MatchAccordions areaMapData={areaMapData}></MatchAccordions> : null}


            <Box sx={{ display: 'flex', width: '100%', gap: '10px', justifyContent: 'center', }}>
              <input
                type="file"
                accept=".svg"
                ref={inputFileRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button onClick={handleButtonClick} sx={smallBtnSx} size="small"><FileUploadIcon /> SVG IMPORT</Button>
              <Button sx={smallBtnSx} size="small"><DownloadIcon />SVG EXPORT</Button>
            </Box>


          </MenuLeftSide>
        </Box>
      </AreasDataContext.Provider>
    </Box>

  );
}