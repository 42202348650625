import React, { createContext, useContext } from 'react';
import { Engine } from '@babylonjs/core/Engines/engine.js';
import { WebGPUEngine } from '@babylonjs/core/Engines/webgpuEngine';
import { Nullable } from '@babylonjs/core/types.js';

export type EngineCanvasContextType = {
    engine: Nullable<Engine | WebGPUEngine>
    canvas: Nullable<HTMLCanvasElement | WebGLRenderingContext>
};

export const EngineCanvasContext = createContext<EngineCanvasContextType>({
    engine: null,
    canvas: null
});

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withEngineCanvasContext<
    P extends { engineCanvasContext: EngineCanvasContextType },
    R = Omit<P, 'engineCanvasContext'>
>(
    Component: React.ComponentClass<P> | React.FunctionComponent<P>
): React.FunctionComponent<R> {
    return function BoundComponent(props: R) {


        return (
            <EngineCanvasContext.Consumer>
                {ctx => <Component {...props as any} engineCanvasContext={ctx} />}
            </EngineCanvasContext.Consumer>
        );
    };
}

/**
 * Get the engine from the context.
 */
export const useEngine = (): Nullable<Engine | WebGPUEngine> => useContext(EngineCanvasContext).engine;

/**
 * Get the canvas DOM element from the context.
 */
export const useCanvas = (): Nullable<HTMLCanvasElement | WebGLRenderingContext> => useContext(EngineCanvasContext).canvas;