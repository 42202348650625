import { Box } from "@mui/material";

export default function Route_LoadingScreen() {

    return (
        <Box sx={{
            zIndex: 999,
            position: 'absolute',
            width: '100%',
            height: '86.4vh',
            background: 'linear-gradient(-45deg, #0F4357, #092935)',
        }}>
            {/* <img src="img/LoadCircle.svg" class="circleimg">
            <img src="img/logo.png" class="logo"> */}
            <Box
                component="img"
                sx={{
                    position: 'absolute',
                    zIndex: 999,
                    top: '50%',
                    left: '50%',
                    width: '50vh',
                    height: '50vh',
                    marginTop: '-25vh',
                    marginLeft: '-25vh',
                    WebkitAnimationName: 'spin',
                    WebkitAnimationDuration: '4000ms',
                    WebkitAnimationIterationCount: 'infinite',
                    WebkitAnimationTimingFunction: 'linear',
                    MozAnimationName: 'spin',
                    MozAnimationDuration: '4000ms',
                    MozAnimationIterationCount: 'infinite',
                    MozAnimationTimingFunction: 'linear',
                    animationName: 'spin',
                    animationDuration: '4000ms',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                    '@-moz-keyframes spin': {
                        '0%': { MozTransform: 'rotate(0deg)' },
                        '100%': { MozTransform: 'rotate(360deg)' }
                    },
                    '@-webkit-keyframes spin': {
                        '0%': { WebkitTransform: 'rotate(0deg)' },
                        '100%': { WebkitTransform: 'rotate(360deg)' }
                    },
                    '@keyframes spin': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                    }
                }}
                alt="Loading circle"
                src="./data/LoadCircle.svg"
            />
            <Box
                component="img"
                sx={{
                    position: 'absolute',
                    zIndex: 999,
                    width: '10%',
                    height: 'auto',
                    top: '49%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                alt="G3d logo"
                src="./data/logo.png"
            />
        </Box>
    );
}