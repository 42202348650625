import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";
import styled from '@mui/material/styles/styled';
/* import TextField from '@mui/material/TextField'; */
import { RouteHelper } from "../../../classes/RouteHelper";
import { GeomBuilder } from "../../../classes/GeomBuilder";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import WeightsDropDown from "./route_weightsDropDown";
import RouteModeDisplay from "./route_modeDisplay";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { SxProps, Theme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ISnackProvider } from "../../../interfaces/commonInterfaces";


/* const CssTextField = styled(TextField)({
    width: '4.7em',
    marginRight: '0.5em',
    marginTop: '0',
    marginBottom: '12px',
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
}); */

interface IRouteEditLink {
    linkId: string,
    pointMesh: Mesh,
    linksList: string[],
    rh: RouteHelper,
    gb: GeomBuilder,
    setLinkSelected: React.Dispatch<React.SetStateAction<string>>,
    setPointSelected: React.Dispatch<React.SetStateAction<boolean>>,
    attachGizmo: () => void,
    updateLinks: () => void,
    setSnackState: React.Dispatch<React.SetStateAction<ISnackProvider>>
}

/* interface IactiveSx {
    opacity: string,
    pointerEvents?: string,
    cursor?: string
} */

let linkToDelete: string = "";

export default function RouteEditLink(props: React.PropsWithChildren<IRouteEditLink>) {
    const [autoLink, setAutoLink] = useState<boolean>(true);
    const [openDeleteLinkWarning, setOpenDeleteLinkWarning] = useState<boolean>(false);
    const [backLinkSx, setBacklinkSx] = useState<SxProps<Theme>>({
        opacity: '0.7',
        pointerEvents: "none",
        cursor: "not-allowed"
    })
    const { t } = useTranslation();

    useEffect(() => {
        if (autoLink === false) {
            setBacklinkSx(sxActiveObj);
        }
        else {
            setBacklinkSx(sxInActiveObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoLink]);

    const sxInActiveObj: object = {
        opacity: '0.6',
        pointerEvents: "none",
        cursor: "not-allowed"
    }

    const sxActiveObj: object = {
        opacity: '1',
    }

    const getPointColor = (id: string): string => {
        let colorString: string = "";
        try {
            colorString = props.rh.getPointTypeColor(id);
        } catch (error) {
            // mach nix
        }
        return colorString;
    }

    /* const getLinkType = (id: string): string => {
        let typeString: string = "";
        try {
            typeString = props.rh.getLinkType(id);
        } catch (error) {
            // mach nix
        }
        return typeString;
    } */

    const getAutoColor = (): string => {
        let color: string = "";
        if (autoLink === true) {
            color = '#FFFFFF';
        }
        else {
            color = '#e1e77b';
        }
        return color;
    }

    const highlightLink = (link: string) => {
        props.gb.highlightMesh(link);
    }

    const deHighlightLink = (link: string) => {
        props.gb.deHighlightMesh(link);
    }


    const handleCloseEditLink = () => {
        props.setLinkSelected("");
        props.setPointSelected(true);
        props.attachGizmo();
    }

    const createBackLinkString = (linkId: string): string => {
        const fromId: string = linkId.substring(0, 8);
        const toId: string = linkId.substring(9);
        let backLink: string = toId + "-" + fromId;

        return backLink;
    }

    const handleChangeAutoLink = () => {
        setAutoLink(!autoLink);
    };

    const handleLinkDeleteDialog = (link: string) => {
        linkToDelete = link;
        setOpenDeleteLinkWarning(true);
    }

    const handleLinkDelete = (link: string) => {
        const fromId: string = link.substring(0, 8);
        const toId: string = link.substring(9);
        props.rh.deleteLink(link);
        props.rh.deleteLink(toId + "-" + fromId);

        const routeCheck: string[] = props.rh.checkPath(fromId, toId) as string[];

        console.log("routeCheck: ", routeCheck);

        if (routeCheck !== undefined) {
            if (routeCheck.length > 1) {
                // all good valid net

                props.gb.deleteMesh(link);
                props.gb.deleteMesh(toId + "-" + fromId);
                props.gb.deleteLinkFromMetadata(link);
                props.attachGizmo();
                linkToDelete = "";
                handleCloseEditLink();
                props.setSnackState({
                    open: true,
                    message: t('tools.routes.link_delete_success'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'success'
                })
            }
            else {
                // net broken with unreachable points! Rollback...
                props.rh.addLink(link);
                props.rh.addLink(toId + "-" + fromId);
                props.setSnackState({
                    open: true,
                    message: t('tools.routes.link_delete_fail'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'warning'
                })
                handleClose();
            }
        }


    }

    const handleClose = () => {
        setOpenDeleteLinkWarning(false);
    };

    const renderLinkDisplay = (link: string) => {
        const fromId: string = link.substring(0, 8);
        const toId: string = link.substring(9);

        return (
            <Box onPointerOver={() => highlightLink(link)} onPointerOut={() => deHighlightLink(link)} sx={{ display: 'flex', mt: '0.5em', justifyContent: 'center', alignItems: 'center', width: '96%' }}>
                <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(fromId), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                <Typography gutterBottom align='left' color='secondary' sx={{
                    fontWeight: '700', fontSize: '1.4rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{fromId}</Typography>
                <ArrowRightAltIcon color='secondary' sx={{ fontSize: '3em', marginLeft: '0.2em', marginRight: '0.2em' }}></ArrowRightAltIcon>
                <Typography gutterBottom align='left' color='secondary' sx={{
                    fontWeight: '700', fontSize: '1.4rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{toId}</Typography>
                <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(toId), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginLeft: '0.6em' }}></Box>
            </Box>
        );
    }

    const renderBackLinkDisplay = (link: string) => {
        const fromId: string = link.substring(0, 8);
        const toId: string = link.substring(9);

        return (
            <Box onPointerOver={() => highlightLink(link)} onPointerOut={() => deHighlightLink(link)} sx={{ display: 'flex', mt: '0.5em', justifyContent: 'center', alignItems: 'center', width: '96%' }}>
                <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(fromId), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                <Typography gutterBottom align='left' color={autoLink === true ? '#FFFFFF' : 'secondary'} sx={{
                    fontWeight: '700', fontSize: '1.4rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{fromId}</Typography>
                <ArrowRightAltIcon sx={{ fontSize: '3em', marginLeft: '0.2em', marginRight: '0.2em', color: getAutoColor() }}></ArrowRightAltIcon>
                <Typography gutterBottom align='left' color={autoLink === true ? '#FFFFFF' : 'secondary'} sx={{
                    fontWeight: '700', fontSize: '1.4rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{toId}</Typography>
                <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(toId), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginLeft: '0.6em' }}></Box>
            </Box>
        );
    }

    const CssWhiteButton = styled(Button)({
        '& .MuiButton-root:hover': {
            color: '#ffffff',
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20vw', alignItems: 'flex-start', pl: '1em' }}>
            <Dialog
                open={openDeleteLinkWarning}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        backgroundColor: 'info.main',
                        backgroundImage: 'unset',
                        /* top: '-11%',
                        left: '-20%',*/
                        width: '50%'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    backgroundColor: 'info.main',
                    color: 'success.main',
                }}>
                    {t('tools.routes.link_delete_dialog_heading')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{
                        color: 'text.primary'
                    }}>
                        {t('tools.routes.link_delete_dialog_msg')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    backgroundColor: 'info.main'
                }}>
                    {/* <Button onClick={handleClose}>{t('tools.cancel')}</Button>
                    <Button onClick={() => handleLinkDelete(linkToDelete)} autoFocus>
                        {t('tools.save')}
                    </Button> */}
                    <CssWhiteButton
                        onClick={() => handleLinkDelete(linkToDelete)}
                        /* type="submit" */
                        /* fullWidth */
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CheckCircleIcon sx={{
                            marginRight: '6px'
                        }}></CheckCircleIcon>
                        {t('tools.save')}
                    </CssWhiteButton>
                    <CssWhiteButton
                        onClick={handleClose}
                        /* type="submit" */
                        /* fullWidth */
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CancelIcon sx={{
                            marginRight: '6px'
                        }}></CancelIcon>
                        {t('tools.cancel')}
                    </CssWhiteButton>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '19vw' }}>
                <IconButton aria-label="delete" sx={{ width: '1.5em', height: '1.5em', padding: '1em' }} onClick={handleCloseEditLink}>
                    <ClearIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-1.0em' }} onPointerOver={() => highlightLink(props.linkId)} onPointerOut={() => deHighlightLink(props.linkId)} >
                <Typography gutterBottom align='left' color='#ffffff' sx={{
                    fontWeight: '700', fontSize: '1.8rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{t('tools.routes.link')}</Typography>
                <Button onClick={() => handleLinkDeleteDialog(props.linkId)} variant="contained" startIcon={<DeleteIcon />} sx={{ ml: '2em', pt: '0.0vh'/* , pl: '1vh' */, pb: '0vh'/* , pr: '1vh' */, height: '2em' }}>
                    {t('tools.routes.delete')}
                </Button>
            </Box>
            {/* TOLINK: */}
            {renderLinkDisplay(props.linkId)}
            <Box onPointerOver={() => highlightLink(props.linkId)} onPointerOut={() => deHighlightLink(props.linkId)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-1.0em' }}>
                <WeightsDropDown rh={props.rh as RouteHelper} linkId={props.linkId} updateLinks={props.updateLinks}></WeightsDropDown>
            </Box>
            <RouteModeDisplay linkId={props.linkId} rh={props.rh}></RouteModeDisplay>
            {/* BACKLINK: */}
            <Box sx={{ height: '4em' }}></Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '19vw' }}>
                <FormControlLabel
                    value="start"
                    control={<Checkbox color="secondary" checked={autoLink} onChange={handleChangeAutoLink} />}
                    label={t('tools.routes.auto_backlink').toUpperCase()}
                    labelPlacement="start"
                    sx={{ ml: '0' }}
                />
            </Box>
            <Box sx={backLinkSx}>
                {renderBackLinkDisplay(createBackLinkString(props.linkId))}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-1.0em' }}>
                    <WeightsDropDown rh={props.rh as RouteHelper} linkId={createBackLinkString(props.linkId)} updateLinks={props.updateLinks}></WeightsDropDown>
                </Box>
                <RouteModeDisplay linkId={createBackLinkString(props.linkId)} rh={props.rh}></RouteModeDisplay>
            </Box>
        </Box>
    )
}