import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import {
    MuiColorInput,
    type MuiColorInputValue,
    type MuiColorInputColors,
    type MuiColorInputFormat
} from 'mui-color-input'
import { useState } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { GeomBuilder } from "../../../classes/GeomBuilder";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

interface IRouteDisplaySettings {
    openDispSettings: boolean;
    setOpenDispSettings: React.Dispatch<React.SetStateAction<boolean>>;
    gb: GeomBuilder;
}

const MuiColorInputStyled = styled(MuiColorInput)`
   & input {
    color: #ffffff;
    padding: 11px 10px;
  },
  label {
    color: #ffffff;
  },
  fieldset {
    border:none; outline:none;
  }
`


export default function RouteDisplaySettings(props: React.PropsWithChildren<IRouteDisplaySettings>) {
    const { t } = useTranslation();
    const [colorStartPoint, setColorStartPoint] = useState<MuiColorInputValue>('#ff0000');
    const [colorEndPoint, setColorEndPoint] = useState<MuiColorInputValue>('#001c80');
    const [colorWayPoint, setColorWayPoint] = useState<MuiColorInputValue>('#276511');
    const [colorConnection, setColorConnection] = useState<MuiColorInputValue>('#061c25');
    const [scaleStartPoint, setScaleStartPoint] = useState<number>(1);
    const [scaleEndPoint, setScaleEndPoint] = useState<number>(1);
    const [scaleWayPoint, setScaleWayPoint] = useState<number>(1);

    const handleResetDisplaySettings = () => {
        setColorStartPoint('#ff0000');
        props.gb.setMaterialColor("start", '#ff0000');
        setColorEndPoint('#001c80');
        props.gb.setMaterialColor("end", '#001c80');
        setColorWayPoint('#276511');
        props.gb.setMaterialColor("way", '#276511');
        setColorConnection('#061c25');
        props.gb.setMaterialColor("connection", '#061c25');
        props.gb.scalePoint("start", 1);
        setScaleStartPoint(1);
        props.gb.scalePoint("end", 1);
        setScaleEndPoint(1);
        props.gb.scalePoint("way", 1);
        setScaleWayPoint(1);
    }

    const handleChangeStart = (color: MuiColorInputValue) => {
        setColorStartPoint(color);
        props.gb.setMaterialColor("start", color.toString());
    }
    const handleStartZoomIn = () => {
        const scaleVal: number = scaleStartPoint + 0.25;
        props.gb.scalePoint("start", scaleVal);
        setScaleStartPoint(scaleVal);
    }
    const handleStartZoomOut = () => {
        const scaleVal: number = scaleStartPoint - 0.25;
        props.gb.scalePoint("start", scaleVal);
        setScaleStartPoint(scaleVal);
    }

    const handleChangeEnd = (color: MuiColorInputValue) => {
        setColorEndPoint(color);
        props.gb.setMaterialColor("end", color.toString());
    }
    const handleEndZoomIn = () => {
        const scaleVal: number = scaleEndPoint + 0.25;
        props.gb.scalePoint("end", scaleVal);
        setScaleEndPoint(scaleVal);
    }
    const handleEndZoomOut = () => {
        const scaleVal: number = scaleEndPoint - 0.25;
        props.gb.scalePoint("end", scaleVal);
        setScaleEndPoint(scaleVal);
    }

    const handleChangeWay = (color: MuiColorInputValue) => {
        setColorWayPoint(color);
        props.gb.setMaterialColor("way", color.toString());
    }
    const handleWayZoomIn = () => {
        const scaleVal: number = scaleWayPoint + 0.25;
        props.gb.scalePoint("way", scaleVal);
        setScaleWayPoint(scaleVal);
    }
    const handleWayZoomOut = () => {
        const scaleVal: number = scaleWayPoint - 0.25;
        props.gb.scalePoint("way", scaleVal);
        setScaleWayPoint(scaleVal);
    }

    const handleChangeConnection = (color: MuiColorInputValue) => {
        setColorConnection(color);
        props.gb.setMaterialColor("connection", color.toString());
    }

    const handleClose = () => {
        props.setOpenDispSettings(false);
    };

    return (
        <Dialog
            open={props.openDispSettings}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    backgroundColor: 'info.main',
                    backgroundImage: 'unset',
                    top: '-15vh',
                    left: '-27.5%',
                    width: '50%'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{
                backgroundColor: 'info.main',
                color: '#FFFFFF',
                display: 'flex',
                /* justifyContent: 'space-between' */
                alignItems: 'center'
            }}>
                {t('tools.routes.disp_settings')}
                <Tooltip title={t('tools.routes.set_default_settings')} sx={{ marginLeft: '0.5em' }}>
                    <IconButton aria-label="add to shopping cart" onClick={handleResetDisplaySettings}>
                        <HistoryIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                    </IconButton>
                </Tooltip>
                <IconButton aria-label="delete" onClick={handleClose} sx={{ position: 'absolute', top: '0', right: '0' }}>
                    <CloseIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }}></CloseIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent>

                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '14vw', justifyContent: 'space-between' }}>
                        {/* BEGIN Startpoint settings */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '25vw' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '17em' }}>
                                <Typography gutterBottom align='center' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                                }}>{t('tools.routes.startpoints') + ": "}</Typography>
                                <MuiColorInputStyled sx={{ width: '8.0em' }} variant={"standard"} size="small" isAlphaHidden={true} value={colorStartPoint} onChange={handleChangeStart} format={'hex'} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '1em' }}>
                                <IconButton aria-label="add to shopping cart" onClick={handleStartZoomOut}>
                                    <ZoomOutIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                                <Typography gutterBottom align='center' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh', width: '2.5em'
                                }}>{scaleStartPoint}</Typography>
                                <IconButton aria-label="add to shopping cart" onClick={handleStartZoomIn}>
                                    <ZoomInIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* END Startpoint settings */}
                        {/* BEGIN Endpoint settings */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '25vw' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '17em' }}>
                                <Typography gutterBottom align='left' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                                }}>{t('tools.routes.endpoints') + ": "}</Typography>
                                <MuiColorInputStyled sx={{ width: '8.0em' }} variant={"standard"} size="small" isAlphaHidden={true} value={colorEndPoint} onChange={handleChangeEnd} format={'hex'} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '1em' }}>
                                <IconButton aria-label="add to shopping cart" onClick={handleEndZoomOut}>
                                    <ZoomOutIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                                <Typography gutterBottom align='center' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh', width: '2.5em'
                                }}>{scaleEndPoint}</Typography>
                                <IconButton aria-label="add to shopping cart" onClick={handleEndZoomIn}>
                                    <ZoomInIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* END Endpoint settings */}
                        {/* BEGIN Waypoint settings */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '25vw' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '17em' }}>
                                <Typography gutterBottom align='left' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                                }}>{t('tools.routes.waypoints') + ": "}</Typography>
                                <MuiColorInputStyled sx={{ width: '8.0em' }} variant={"standard"} size="small" isAlphaHidden={true} value={colorWayPoint} onChange={handleChangeWay} format={'hex'} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '1em' }}>
                                <IconButton aria-label="add to shopping cart" onClick={handleWayZoomOut}>
                                    <ZoomOutIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                                <Typography gutterBottom align='center' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh', width: '2.5em'
                                }}>{scaleWayPoint}</Typography>
                                <IconButton aria-label="add to shopping cart" onClick={handleWayZoomIn}>
                                    <ZoomInIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* END Waypoint settings */}
                        {/* BEGIN Connection settings */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '25vw' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '17em' }}>
                                <Typography gutterBottom align='left' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                                }}>{t('tools.routes.connections') + ": "}</Typography>
                                <MuiColorInputStyled sx={{ width: '8.0em' }} variant={"standard"} size="small" isAlphaHidden={true} value={colorConnection} onChange={handleChangeConnection} format={'hex'} />
                            </Box>
                            {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '1em' }}>
                                <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                                    <ZoomOutIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                                <Typography gutterBottom align='left' color='#ffffff' sx={{
                                    fontSize: '1.0rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                                }}>{1}</Typography>
                                <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                                    <ZoomInIcon sx={{ color: '#FFFFFF', fontSize: '1.0em' }} />
                                </IconButton>
                            </Box> */}
                        </Box>
                        {/* END Connection settings */}
                    </Box>
                    <Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}