import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import FileUploadIcon from '@mui/icons-material/FileUpload';
/* import DeleteIcon from '@mui/icons-material/Delete'; */
import MapsLoadingElement from './maps_loading_element';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NotesImage from "../../../maps_note.svg";
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import React from "react";
import { UploadedFile, UploadedFiles, UploadedImage } from '../../../interfaces/interfaceGuiController';
import { useAppDispatch, useAppSelector } from './../../hooks';
import { setCurrentGuiController_mapsAssistant_note, setCurrentGuiController_mapsAssistant_uploads } from '../../slices/guiControllerSlice';
import { setCurrentGuiController_mapsAssistant_layerImages } from '../../slices/guiControllerSlice';
import { setCurrentGuiController_mapsAssistant_note_read } from '../../slices/guiControllerSlice';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import { useUploadPngToMapMutation } from "../../slices/apiSlice";
/* import { UploadFile } from "@mui/icons-material"; */

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

interface IUploadProps {
    uploadProp: {
        layernameString: string | null;
        layerId: string;
        layerNamesArray: string[];
        mapUuid: string;
        requeryMap: () => Promise<void>;
        mapFiles: UploadedFiles;
    }
}

const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_PATH_TO_API) {
        case 'production ':
            url = 'https://creator.guide3d.com';
            break;
        case 'development ':
        default:
            url = 'http://api.creator.local';
    }

    return url;
}

export default function MapsUploadElement(props: React.PropsWithChildren<IUploadProps> | null) {
    const layerName: string = props?.uploadProp.layernameString as string;
    const layerId: string = props?.uploadProp.layerId as string;
    const layerNamesArray: string[] = props?.uploadProp.layerNamesArray as string[];
    const mapUuid: string = props?.uploadProp.mapUuid as string;
    const dispatch = useAppDispatch();
    const [loadedImageIndex, setLoadedImageIndex] = React.useState<number>(-1);
    /* const [selectedFile, setSelectedFile] = React.useState<File | null>(null); */
    const [error, setError] = React.useState<string>('');
    const iconRef = React.useRef<HTMLInputElement>(null!);
    const [loading, setLoading] = React.useState('none');
    const [srcString, setSrcString] = React.useState<string>('');
    const [readValue, setReadValue] = React.useState<boolean>(false);
    // const [fileReference, setFileReference] = React.useState<File | null>(null);
    const mapsAssistant_uploads = useAppSelector((state) => state.guicontroller.value.mapsAssistant_uploads);
    const mapsAssistant_layerImages = useAppSelector((state) => state.guicontroller.value.mapsAssistant_layers);
    // const mapsAssistant_layerPaths = useAppSelector((state) => state.guicontroller.value.mapsLayerPaths);
    const mapsAssistant_note = useAppSelector((state) => state.guicontroller.value.mapsAssistant_note);
    const mapsAssistant_note_read = useAppSelector((state) => state.guicontroller.value.mapsAssistant_note_read);
    /* const mapsFiles = useAppSelector((state) => state.guicontroller.value.mapsFiles); */
    /* const gc = useAppSelector((state) => state.guicontroller.value); */
    const [clickInitiated, setclickInitiated] = React.useState<string>('none');
    const [uploadPngToMap] = useUploadPngToMapMutation();

    const apiUrl: string = getBaseUrl(); // http://api.creator.local | https://creator.guide3d.com

    /* const MapsLoadingElementSx = styled(MapsLoadingElement)(); */
    const { t } = useTranslation();

    const handleReadValue = () => {
        setReadValue(!readValue);
        console.log("handleReadValue readValue: ", !readValue);
        if (!readValue === true) {
            dispatch(setCurrentGuiController_mapsAssistant_note_read(true));
        }
    }

    const handleAcceptClick = () => {
        console.log("handleAcceptClick readValue: ", readValue);
        dispatch(setCurrentGuiController_mapsAssistant_note('click'));
    }

    React.useEffect(() => {
        console.log("UPLOAD ELEMENT layerName: ", layerName);
        if (props?.uploadProp.mapFiles !== undefined) {
            console.log("UPLOAD ELEMENT mapsFiles.files", props?.uploadProp.mapFiles.files);
            /* console.log("UPLOAD ELEMENT gc", gc); */
            let settedLayerID: string = "";
            props?.uploadProp.mapFiles.files.forEach((file: UploadedFile, index) => {
                /* console.log("MapsUploadElement LayerID: ", layerId);
                console.log("MapsUploadElement loadedImageIndex: ", loadedImageIndex); */

                if (file !== undefined) {
                    /* console.log("file: ", file);
                    console.log("file.layer: ", file.layer); */
                    if (file.layer === layerId) {
                        /* console.log("!!!!!!!!!!!!!!!!!!!!! SOURCESET"); */
                        settedLayerID = layerId;
                        setSrcString(apiUrl + file.png.miniThumbnail);
                        // saveImagePathsApi("http://api.creator.local" + file.png.thumbnail, "http://api.creator.local" + file.png.miniThumbnail, file.png.width);
                    }
                }
                else {
                    if (settedLayerID === "") {
                        /* console.log("??????????????? SOURCE NOT SET"); */
                        setSrcString('');
                    }

                }
            });
        }
        else {
            setSrcString('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.uploadProp.mapFiles]);

    React.useEffect(() => {
        if (mapsAssistant_note === "click" && clickInitiated === 'true') {

            setTimeout(() => {
                setclickInitiated('none');
                iconRef?.current.click();
            }, 10);
        }
    }, [mapsAssistant_note, mapsAssistant_note_read, clickInitiated, dispatch]);

    React.useEffect(() => {
        if (mapsAssistant_layerImages.images.length > 0) {
            if (layerNamesArray.indexOf(layerName) !== -1) {
                setLoadedImageIndex(layerNamesArray.indexOf(layerName));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapsAssistant_layerImages]);

    /* const checkForLoadedImage = () => {

    }  */

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        /* console.log(selectedFile); */
    };

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading('block');
        const file = event.target.files ? event.target.files[0] : null;
        /* setFileReference(file); */
        callUploadPngToMap(file as File);
        // console.log(apiP)
        // validateFile(file);

    };

    const callUploadPngToMap = async (file: File) => {
        let formdata = new FormData();
        formdata.append('mapImage', file);
        formdata.append('layer', layerId);
        console.log("Uploading MAP to Server...")
        await uploadPngToMap({ "bodyFormData": formdata, "mapUuid": mapUuid })
            .then((response: any) => {
                if (response.success === false) {
                    console.log("ERROR: " + response.error.data.message.mapImage[0]);
                    setLoading('none');
                }
                else {
                    console.log("Uploading MAP to Server DONE! Response: ", response);
                    setSrcString(apiUrl + response.data.data.miniThumbnail);
                    setLoading('none');
                    // saveImagePaths("http://api.creator.local" + response.data.data.thumbnail, "http://api.creator.local" + response.data.data.miniThumbnail, response.data.data.width)
                    if (mapsAssistant_layerImages.images[loadedImageIndex] === undefined) {
                        /* console.log("Upload 1"); */
                        setTimeout(() => {
                            handleLayerImageSave(file.name, apiUrl + response.data.data.thumbnail, response.data.data.width);
                            setLoading('none');
                            setError('');
                            dispatch(setCurrentGuiController_mapsAssistant_uploads(mapsAssistant_uploads + 1));
                            props?.uploadProp.requeryMap();
                        }, 5000);
                    }
                    else {
                        /* console.log("Upload 2"); */
                        setTimeout(() => {
                            setLoading('none');
                            setError('');
                            let newimagesObject = [...mapsAssistant_layerImages.images];
                            let image: UploadedImage = { name: file.name, imagesrc: apiUrl + response.data.data.thumbnail, width: response.data.data.width };
                            newimagesObject[loadedImageIndex] = image;
                            dispatch(setCurrentGuiController_mapsAssistant_uploads(mapsAssistant_uploads + 1));
                            dispatch(setCurrentGuiController_mapsAssistant_layerImages({ images: newimagesObject }));
                            props?.uploadProp.requeryMap();
                        }, 5000);
                    }

                }

            }
            );
    }

    /* const saveImagePaths = (imgPath: string, thnPath: string, imageWidth: number) => {
        let bReplaced: boolean = false;
        let newImagesPathsObject = [...mapsAssistant_layerPaths];
        let PathsObject: LayerPath = {
            layerId: layerId,
            imagePath: imgPath,
            thumbnailPath: thnPath,
            width: imageWidth
        }
        newImagesPathsObject.forEach(element => {
            if (element.layerId === layerId) {
                element = PathsObject;
                bReplaced = true;
            }
        });
        if (bReplaced === false) {
            newImagesPathsObject.push(PathsObject);
        }
        dispatch(setCurrentGuiController_mapsLayerPaths(newImagesPathsObject));
    } */

    /* const getThumbnailPath = (): string => {
        console.log("mapsAssistant_layerPaths: ", mapsAssistant_layerPaths);
        console.log("mapsAssistant_layerImages: ", mapsAssistant_layerImages);
        let thnPath: string = "";
        mapsAssistant_layerPaths.forEach((element: { layerId: string; thumbnailPath: string; }) => {
            if (element.layerId === layerId) {
                thnPath = element.thumbnailPath;
            }
        });
        return thnPath;
    } */

    const handleLayerImageSave = (filename: string, imgsrc: string, imgwidth: number) => {
        let newimagesObject = [...mapsAssistant_layerImages.images];
        let image: UploadedImage = { name: filename, imagesrc: imgsrc, width: imgwidth };
        newimagesObject.push(image);
        // imagesObject.images.set(filename , imgsrc);
        dispatch(setCurrentGuiController_mapsAssistant_layerImages({ images: newimagesObject }));
    };



    /* const validImageWidthHeight = (w: number, h: number): boolean => {
        let validWidths: number[] = [8192, 16384, 32768];
        let validHeights: number[] = [8192, 16384, 32768]
        let bValidImageSize: boolean = false;
        if (w === validWidths[0] && h === validHeights[0]) {
            bValidImageSize = true;
        }
        if (w === validWidths[1] && h === validHeights[1]) {
            bValidImageSize = true;
        }
        return bValidImageSize
    }
 */
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5vh', justifyContent: 'center' }}>
            <form onSubmit={handleSubmit}>
                <input ref={iconRef} className="file-input" type="file" onChange={handleFileInput} name="file" hidden />
                {srcString === '' ?
                    <Box sx={{ backgroundColor: 'rgba(255,255,255,0.2)', width: '10vh', height: '5vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', mr: '2vh' }}
                        onClick={() => {
                            /* console.log("+++++++++++ Upload click, readValue: ", readValue); */

                            if (mapsAssistant_note_read === false) {
                                console.log("+++++++++++ Upload click, readValue FALSE");
                                setclickInitiated('true');
                                dispatch(setCurrentGuiController_mapsAssistant_note('block'));
                            }
                            else {
                                console.log("+++++++++++ Upload click, readValue ELSE: ", mapsAssistant_note_read);
                                iconRef?.current.click();
                            }
                        }}
                    >
                        <FileUploadIcon></FileUploadIcon>
                    </Box>
                    : null
                }
            </form>
            {error}
            {loading === 'block' ?
                <MapsLoadingElement></MapsLoadingElement>
                : null
            }
            {/* {mapsAssistant_note === 'block' && mapsAssistant_note_read === false ?  */}
            {mapsAssistant_note === 'block' ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
                    open
                >
                    <Box sx={{ backgroundColor: 'black', padding: '1vh' }}>
                        <Typography gutterBottom variant="h4" align="left" component="div">
                            {t('tools.maps.floorsetup_3_note_1')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_2')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_3')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_4')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_5')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_6')}
                        </Typography>
                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                            {t('tools.maps.floorsetup_3_note_7')}
                        </Typography>
                        <Box sx={{ padding: '1vh' }}>
                            <Box sx={{ width: '50vh', height: '50vh', position: 'relative' }}>
                                <Box>
                                    <img src={NotesImage} alt='preview' height={'100%'} width={'auto'} style={{ position: 'absolute', left: 0 }}></img>
                                    <Box sx={{ width: '20%', right: '2%', top: '1%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="right" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_1')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '20%', left: '2%', bottom: '0.5%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="left" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_1')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '20%', left: '10%', bottom: '30%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="left" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_2')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '20%', right: '15%', top: '20%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="left" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_2')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '50%', left: '25%', top: '55%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="center" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_3')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '50%', left: '25%', top: '59%', position: 'absolute' }}>
                                        <Typography gutterBottom variant="body2" align="center" component="div" >
                                            {t('tools.maps.floorsetup_3_note_image_4')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ padding: '1vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <FormControlLabel control={
                                <Checkbox color='success'
                                    checked={readValue}
                                    required={true}
                                    onChange={handleReadValue} />
                            }
                                label={t('tools.maps.floorsetup_3_note_8')} />
                            <CssWhiteButton
                                type="submit"
                                /* fullWidth */
                                variant="contained"
                                onClick={handleAcceptClick}
                                sx={{
                                    /* position: 'absolute',
                                    bottom: '4vh',
                                    left: '7vh', */
                                    mt: '0.5vh',
                                    ml: '2vh',
                                    backgroundColor: 'primary.contrastText',
                                    color: 'primary.main',
                                    width: 'fit-content',
                                    '&:hover': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                            >
                                {t('tools.maps.floorsetup_3_note_9')}
                                <NavigateNextIcon sx={{
                                    marginLeft: '6px'
                                }}></NavigateNextIcon>
                            </CssWhiteButton>
                        </Box>

                    </Box>
                </Backdrop>
                : null
            }
            {srcString !== ''
                ?
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Box sx={{ backgroundColor: '#04141A', width: '45vh', height: '5vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', mr: '2vh' }}>
                        {/* <img src={mapsAssistant_layerImages.images[loadedImageIndex].imagesrc + "?" + Math.random()} alt='preview' height={42} width={'auto'} onLoad={() => { */}
                        <img src={srcString + "?" + Math.random()} alt='preview' height={42} width={'auto'} onLoad={() => {
                            /* console.timeEnd('imageLoad'); */
                            /* setTimeout(() => {
                                setLoading('none');
                            }, 1000); */
                        }}
                        />
                        <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                            pl: '2vh', width: '20vh'
                        }}>
                            {layerId + "-map.png"}
                        </Typography>
                    </Box>

                    <Box>
                        {/* <DeleteIcon cursor='pointer' sx={{
                            ml: '0vh'
                        }} onClick={() => { handleAssistantDeleteImage() }}>
                        </DeleteIcon> */}
                        <form onSubmit={handleSubmit}>
                            <input ref={iconRef} className="file-input" type="file" onChange={handleFileInput} name="file" hidden />
                            <Box sx={{ backgroundColor: 'rgba(255,255,255,0.2)', width: '10vh', height: '5vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', mr: '2vh' }}
                                onClick={() => {
                                    /* console.log("+++++++++++ Upload click, readValue: ", readValue); */

                                    if (mapsAssistant_note_read === false) {
                                        console.log("+++++++++++ Upload click, readValue FALSE");
                                        setclickInitiated('true');
                                        dispatch(setCurrentGuiController_mapsAssistant_note('block'));
                                    }
                                    else {
                                        console.log("+++++++++++ Upload click, readValue ELSE: ", mapsAssistant_note_read);
                                        iconRef?.current.click();
                                    }
                                }}
                            >
                                <FileUploadIcon></FileUploadIcon>
                            </Box>
                        </form>
                    </Box>

                </Box>
                : null}
        </Box>
    );
}