import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { ISnackProvider } from "../../interfaces/commonInterfaces";


export default function SnackProvider(props: React.PropsWithChildren<ISnackProvider>) {

    const snOrigin: SnackbarOrigin = { vertical: props.vertical, horizontal: props.horizontal }
    /* const [openSnack, setOpenSnack] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>(""); */

    /* const handleClose = () => {
        setOpenSnack(false);
        setSnackMessage("");
    }; */

    /* useEffect(() => {
        console.log("props: ", props);
        if (props.message !== snackMessage) {
            setOpenSnack(true);
            setSnackMessage(props.message);
        }
    }, [props.message]); */

    return (
        <Snackbar
            anchorOrigin={snOrigin}
            open={props.open}
            autoHideDuration={10000}
            onClose={props.handleClose}
        >
            <Alert
                onClose={props.handleClose}
                severity={props.alert}
                /* variant="filled" */
                sx={{ width: '100%' }}
            >
                {props.message}
            </Alert>
        </Snackbar>
    );
}