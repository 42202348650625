import Map from 'ol/Map';
import TileLayer from "ol/layer/WebGLTile";
import { IAreasMap } from '../../../interfaces/area_interfaces';
import { useEffect } from "react";
import React from "react";
import OSM from "ol/source/OSM";
import XYZ from 'ol/source/XYZ';
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Select from 'ol/interaction/Select.js';
import { Style } from "ol/style";
import Icon from 'ol/style/Icon';
import { transform } from "ol/proj";
import GeoJSON from 'ol/format/GeoJSON.js';
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke"
import Fill from "ol/style/Fill";
import Attribution from "ol/control/Attribution"
import Fullscreen from "ol/control/FullScreen"
import Zoom from "ol/control/Zoom"
import { ScaleLine } from "ol/control";
import { defaults } from "ol/interaction/defaults";
import BaseLayer from "ol/layer/Base";
import Collection from "ol/Collection";
import Feature from 'ol/Feature.js';
import { AreasDataContext } from '../tool_areas';
import PointPlusSVG from '../../../res/svg/areas_point_plus.svg';
import PointMinusSVG from '../../../res/svg/areas_point_minus.svg';
import { features } from 'process';


let mbTilesSource: XYZ;
let currentTileBaseUrl: string;
let netLayerGeoJSON: BaseLayer;
let areasLayerGeoJSON: BaseLayer;
let currentLayer: number;


export default function AreasMap(props: IAreasMap) {
    const { areasData, setAreasData } = React.useContext(AreasDataContext);
    const shiftPressed = React.useRef(false);
    const selectedAreas = React.useRef<Feature[]>([]);
    const ref = React.useRef<HTMLDivElement>(null)
    const mapRef = React.useRef<Map>();
    let pointScale = 0.6;


    // const zoomLevel = mapRef.current?.getView().getZoom();
    // zoomLevel ? pointScale = Math.max(0.5, zoomLevel / 20) : pointScale = 0.6

    const areaStyles = [
        new Style({
            stroke: new Stroke({
                color: '#000',
                width: 0.5,
            }),
            fill: new Fill({
                color: '#F67474',
            }),
        }),
    ];


    const areaStylesMatch = [
        new Style({
            stroke: new Stroke({
                color: '#000',
                width: 0.5,
            }),
            fill: new Fill({
                color: '#114F66',
            }),
        }),
    ];


    const areaStylesSelected = [
        new Style({
            stroke: new Stroke({
                color: '#000',
                width: 0.5,
            }),
            fill: new Fill({
                color: '#E1E77B',
            }),
        }),
    ];


    const pointStylePlus = new Style({
        image: new Icon({
            src: PointPlusSVG,
            scale: pointScale,
        }),
    });


    const pointStyleMinus = new Style({
        image: new Icon({
            src: PointMinusSVG,
            scale: pointScale,
        }),
    });


    const pointStyleBlue = new Style({
        image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: '#0139FF' }),
            stroke: new Stroke({ color: '#000', width: 1 }),
            scale: pointScale,
        })
    });


    const pointStyleArea = new Style({
        image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: '##114F66' }),
            stroke: new Stroke({ color: '#000', width: 1 }),
            scale: pointScale,
        })
    });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                shiftPressed.current = true;
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                shiftPressed.current = false;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);


    useEffect(() => {

        createMap();

    }, []);


    useEffect(() => {
        if (mbTilesSource !== undefined) {

            displayMapOfCurrentLayer();
            removePreviousPointLayer();

        }
    }, [props.layerIndex, props.geoPointFeatures, props.mapData]);


    useEffect(() => {
        addAreasToMap();

    }, [props.areasFeatures, props.layerIndex]);


    useEffect(() => {

        currentLayer = props.layerIndex;

    }, [props.layerIndex]);



    const createMap = () => {
        const centerPoint = getCenterPoint();
        currentTileBaseUrl = getBasicTileUrl();
        const mbLayer = create3dbTileLayer();
        if (ref.current && !mapRef.current) {
            mapRef.current = new Map({
                layers: [new TileLayer({ source: new OSM() }), mbLayer],
                view: new View({
                    projection: 'EPSG:3857',
                    center: transformLatLonToMercator(centerPoint),
                    rotation: degrees_to_radians(props.mapData.rotation as number),
                    zoom: 18,
                    constrainRotation: false
                }),
                target: 'vectorLayerMap',
                controls: [new Attribution(), new Fullscreen(), new Zoom(), new ScaleLine()],
                interactions: defaults({
                    altShiftDragRotate: false,
                    shiftDragZoom: false,
                }),

            });
        }
    }


    const removePreviousPointLayer = () => {
        if (netLayerGeoJSON) {
            mapRef.current?.removeLayer(netLayerGeoJSON);
        }
        mapRef.current?.un('click', e => checkFeatures(e));
    }


    const removePreviousAreasLayer = () => {
        if (areasLayerGeoJSON) {
            mapRef.current?.removeLayer(areasLayerGeoJSON)
        }
        mapRef.current?.un('click', e => checkFeatures(e));
    }



    const getCenterPoint = (): [number, number] => {
        const centerLat = ((props.mapData.rawPoints?.pointA_geoLatitude as number) + (props.mapData.rawPoints?.pointB_geoLatitude as number)) / 2;
        const centerLon = ((props.mapData.rawPoints?.pointA_geoLongitude as number) + (props.mapData.rawPoints?.pointB_geoLongitude as number)) / 2;
        /* console.log("centerLat: ", centerLat);
        console.log("centerLon: ", centerLon); */
        return [centerLon, centerLat];
    }


    const getBasicTileUrl = () => {
        let baseUrl = "https://tiles.guide3d.com/" + props.projectId + "/beta/";
        /* TODO: Wo sollen die Tiles herkommen?  */
        /* if (bTileGen === "true") {
            // https://creator.guide3d.com/storage/map/4cefa85f-096b-4fb0-aadf-bf4f454c3181/output/thumbnail-L05-map.png
            // https://creator.guide3d.com/mbviewer/tileserver/cdec65f8-9ea2-4fc6-a20d-dde57546b9cb/L03/17/69209/42296.png
            baseUrl = "https://creator.guide3d.com/mbviewer/tileserver/" + props.mapUuid + "/"
        }
        if (bLive === "true") {
            baseUrl = "https://tiles.guide3d.com/" + props.projectId + "/live/";
        } */
        return baseUrl;
    }



    const displayMapOfCurrentLayer = () => {
        let mapLayerIds: string[] = [];
        if (props.mapData.layerIds !== undefined) {
            mapLayerIds = props.mapData.layerIds;
        }

        /* mbTilesSource.setUrl(currentTileBaseUrl + mapLayerIds[props.layerIndex] + "/{z}/{x}/{y}.png"); */

        const newMBsource: XYZ = new XYZ({
            tilePixelRatio: 1,
            url: (currentTileBaseUrl + mapLayerIds[props.layerIndex] + "/{z}/{x}/{y}.png")
        });

        const coll: Collection<BaseLayer> = mapRef.current?.getLayers() as Collection<BaseLayer>;
        if (!mapRef.current?.getLayers()) {
            return
        }

        coll.forEach((element: any) => {
            if (element instanceof TileLayer === true) {
                if (element.get("id") === "tiles") {
                    element.setSource(newMBsource);
                }
            }
        });
    }


    const checkFeatures = (e: any) => {
        e.stopPropagation();
        mapRef.current?.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
            if (feature instanceof Feature && feature.getProperties().pointID) {
                if (selectedAreas.current.length > 0) linkAreasToPoint(feature.getProperties().pointID);
                console.log(feature.getProperties());
            }
            if (feature instanceof Feature && feature.getProperties().uID) {
                console.log(feature.getProperties());
                selectedAreas.current.includes(feature) ? deSelectArea(feature) : selectArea(feature);
            }
        });
    }


    const linkAreasToPoint = (point: string) => {        
        selectedAreas.current.forEach(feature => {
            console.log(feature.getProperties().uID, 'gets waypoint', point);
            setAreasData(prevAreasData => prevAreasData.map(area =>
                (area.uID === feature.getProperties().uID) ? { ...area, wayPoint: point, match: true, layer: point.substring(0, 3) } : area));
            deSelectArea(feature);    
        });
    }


    const selectArea = (feature: Feature) => {
        feature.setStyle(areaStylesSelected);
        if (!shiftPressed.current) selectedAreas.current.forEach(feature => deSelectArea(feature));
        selectedAreas.current = [...selectedAreas.current, feature];
        console.log('With new selected: ', selectedAreas);
        checkIfPointsNeeded();
    }


    const deSelectArea = (feature: Feature) => {
        feature.getProperties().match ? feature.setStyle(areaStylesMatch) : feature.setStyle(areaStyles);
        selectedAreas.current = selectedAreas.current.filter(f => f !== feature);
        console.log('After un-select: ', selectedAreas);
        checkIfPointsNeeded();
    }


    const checkIfPointsNeeded = () => {
        if (selectedAreas.current.length === 0) {
            removePreviousPointLayer();
        } else {
            removePreviousPointLayer();
            addNetPointsToMap();
        }
    }


    const addNetPointsToMap = () => {
        removePreviousPointLayer();

        const featuresForLayer = props.geoPointFeatures[currentLayer];
        //TODO console.log(featuresForLayer); 
        if (featuresForLayer === undefined) return;
        netLayerGeoJSON = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON().readFeatures(featuresForLayer, {
                    featureProjection: 'EPSG:3857'
                })
            }),
            style: pointStylePlus,
            visible: true // TODO Etagen Selection über visible Attribut?
        });
        if (selectedAreas.current.length > 0) {
            mapRef.current?.addLayer(netLayerGeoJSON);
        }
        mapRef.current?.on('click', e => checkFeatures(e));
    }



    const addAreasToMap = () => {
        if (!props.areasFeatures) {
            return
        }
        removePreviousAreasLayer();
        const featuresForLayer = props.areasFeatures[props.layerIndex];
        if (featuresForLayer === undefined) {
            console.error('Areas openLayers features: layer error');
            return
        }
        const geojsonFeatureCollection = new GeoJSON().writeFeaturesObject(featuresForLayer);
        areasLayerGeoJSON = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON().readFeatures(geojsonFeatureCollection, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            }),
            style: (feature) => {
                if (selectedAreas.current.filter(area => area.getProperties().uID === feature.getProperties().uID).length > 0) {
                    return areaStylesSelected
                } 
                if (feature.getProperties().match) {
                    return areaStylesMatch
                } else {
                    return areaStyles
                }
            },
            visible: true
        });
        console.log('GEO JSON: ', areasLayerGeoJSON.getSourceState());
        mapRef.current?.addLayer(areasLayerGeoJSON);
        mapRef.current?.on('click', e => checkFeatures(e));
    }


    const create3dbTileLayer = () => {
        let mapLayerIds: string[] = [];
        if (props.mapData.layerIds !== undefined) {
            mapLayerIds = props.mapData.layerIds;
        }
        mbTilesSource = new XYZ({
            tilePixelRatio: 1,
            /* url: "https://tiles.guide3d.com/" + projectId + "/beta/" + layerIds[layerIds.length - 1] + "/{z}/{x}/{y}.png" */
            url: (currentTileBaseUrl + mapLayerIds[mapLayerIds.length - 1] + "/{z}/{x}/{y}.png")
        });

        const mbLayer = new TileLayer({
            preload: 19,
            source: mbTilesSource
        });
        mbLayer.set("id", "tiles");

        return mbLayer;
    }


    const transformLatLonToMercator = (coord: [number, number]) => {
        let mercCoord = [0, 0];
        const tempCoord = [coord[0], coord[1]];
        const tempCoord2 = transform(tempCoord, 'EPSG:4326', 'EPSG:3857');
        mercCoord[0] = tempCoord2[0];
        mercCoord[1] = tempCoord2[1];
        return mercCoord;
    };


    const degrees_to_radians = (radians: number): number => {
        /* const pi = Math.PI; */
        return radians * (Math.PI / 180);
    }


    return (
        <div id="vectorLayerMap" style={{ backgroundColor: 'blue', width: '100%', height: '100%' }} className="map-container" ref={ref}>
        </div>
    );
}