import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Scene } from "@babylonjs/core/scene";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { VertexData } from "@babylonjs/core/Meshes/mesh.vertexData";
import { InstancedMesh } from "@babylonjs/core/Meshes/instancedMesh";
import { Axis } from "@babylonjs/core/Maths/math.axis";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { PBRMetallicRoughnessMaterial } from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { Line } from "@babylonjs/gui/2D/controls/line";
import { Ray } from "@babylonjs/core/Culling/ray";
import { RayHelper } from "@babylonjs/core/Debug/rayHelper";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Animation } from "@babylonjs/core/Animations/animation";
/* import { Angle } from "@babylonjs/core/Maths/math.path"; */
/* import { DataVault } from "./DataVault"; */
/* import earcut from "earcut"
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo"; */
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { LayersBabylonDataObject, PointObject } from "../interfaces/commonInterfaces"
import { RouteHelper } from "./RouteHelper";
import { LayerFile, MapData } from "../interfaces/interfaceGuiController";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import "@babylonjs/core/Animations/animatable";
import { Plane } from "@babylonjs/core/Maths/math.plane";
/* import { FloatArray } from "@babylonjs/core/types"; */
import { AbstractMesh, HighlightLayer } from "@babylonjs/core"



export class GeomBuilder {

    private scene!: Scene;
    private startPointMat!: StandardMaterial;
    private endPointMat!: StandardMaterial;
    private defaultpointMat!: StandardMaterial;
    private defaultconnectionMat!: StandardMaterial;
    private hlMat!: PBRMetallicRoughnessMaterial;
    private defaultBox!: Mesh;
    private defaultHighlightBox!: Mesh;
    private endHighlightBox!: Mesh;
    private startHighlightBox!: Mesh;
    private defaultStartBox!: Mesh;
    private defaultEndBox!: Mesh;
    private pointsArray!: InstancedMesh[];
    private pointsPositions!: Vector3[];
    private layerIdsArray!: string[];
    private layerNodesArray!: TransformNode[];
    private guiTexture!: AdvancedDynamicTexture;
    private guiRayTexture!: AdvancedDynamicTexture;
    private defaultTube!: Mesh;
    private specialTube!: Mesh;
    private specialTube_blue!: Mesh;
    private specialTube_pink!: Mesh;
    private specialTube_red!: Mesh;
    private hlTube!: Mesh;
    private hlTube_special!: Mesh;
    private hlTube_blue!: Mesh;
    private hlTube_pink!: Mesh;
    private hlTube_red!: Mesh;
    /* private dataVault: DataVault; */
    private raycastHitForLink: boolean = false;
    private gizmoManager!: GizmoManager;
    private startPoints: string[] = [];
    private endPoints: string[] = [];
    private wayPoints: string[] = [];
    private select_from!: HTMLInputElement;
    private select_to!: HTMLInputElement;
    private layerPositions: Map<string, Vector3> = new Map();
    public oRouteHelper!: RouteHelper;
    public userMode: string = "net";
    private lastRayCastName!: string;
    private rayOverlayClean: boolean = false;
    public currentRayCastMesh!: Mesh | null;
    /* private masterPlane!: Mesh; */
    /* private layerPointsCountMap!: Map<string, number>; */
    /* private pathsParent!: TransformNode; */
    private pointIdLists: string[][] = [];
    /* private layerNodes!: TransformNode[]; */
    private projectID!: number;
    private apiUrl!: string;
    private mainMapData!: MapData;
    private layerFiles!: { [key: string]: LayerFile };
    public lastGizmoMesh?: Mesh;
    private bShiftDown: boolean = false;
    private bStarted: boolean = false;
    private localMeshDirectionNorth: Vector3 = new Vector3(1, 0, 0);
    private localMeshDirectionSouth: Vector3 = new Vector3(-1, 0, 0);
    private localMeshDirectionEast: Vector3 = new Vector3(0, 0, 1);
    private localMeshDirectionWest: Vector3 = new Vector3(0, 0, -1);
    private rRayHelperNorth!: RayHelper;
    private rRayHelperSouth!: RayHelper;
    private rRayHelperEast!: RayHelper;
    private rRayHelperWest!: RayHelper;
    public currentDragPlaneVector!: Vector3;
    private layerMaterials!: Map<string, StandardMaterial>;
    private testPlaneMat!: StandardMaterial;
    private hl_Layer!: HighlightLayer;
    private lastHlMesh!: Mesh;
    private displayPointData!: (selectedMesh: Mesh) => void;
    private linkColors: Map<string, string> = new Map();

    public getStartStatus(): boolean {
        return this.bStarted;
    }

    public scalePoint(scope: string, val: number) {
        /* console.log("scalePoint, scope: " + scope + " / color: " + val); */
        switch (scope) {
            case "start":
                this.scene.meshes.forEach((point: AbstractMesh) => {
                    if (point.material?.name === "startMat") {
                        point.scaling = new Vector3(val, val, val);
                    }
                });
                break;
            case "end":
                this.scene.meshes.forEach((point: AbstractMesh) => {
                    if (point.material?.name === "endMat") {
                        point.scaling = new Vector3(val, val, val);
                    }
                });
                break;
            case "way":
                this.scene.meshes.forEach((point: AbstractMesh) => {
                    if (point.material?.name === "defaultMat1") {
                        point.scaling = new Vector3(val, val, val);
                    }
                });
                break;
            default:
                break;
        }
    }

    public setMaterialColor(scope: string, color: string) {
        /* console.log("setMaterialColor, scope: " + scope + " / color: " + color); */
        switch (scope) {
            case "start":
                this.startPointMat.diffuseColor = Color3.FromHexString(color);
                this.startPointMat.emissiveColor = Color3.FromHexString(color);
                break;
            case "end":
                this.endPointMat.diffuseColor = Color3.FromHexString(color);
                this.endPointMat.emissiveColor = Color3.FromHexString(color);
                break;
            case "way":
                this.defaultpointMat.diffuseColor = Color3.FromHexString(color);
                this.defaultpointMat.emissiveColor = Color3.FromHexString(color);
                break;
            case "connection":
                this.defaultconnectionMat.emissiveColor = Color3.FromHexString(color);
                break;
            default:
                break;
        }
    }


    public init(currentScene: Scene, oRH: RouteHelper, currentProject: number, mainMapData: MapData, layerFiles: { [key: string]: LayerFile }) {
        this.bStarted = true;
        console.log("################### GEOMBUILDER INIT CALLED! ###################");

        this.oRouteHelper = oRH;
        this.projectID = currentProject;
        this.mainMapData = mainMapData;
        this.layerFiles = layerFiles;

        this.linkColors.set("default", "#ffffff");
        this.linkColors.set("other", "#d9a336");
        this.linkColors.set("stair-up", "#0288d1");
        this.linkColors.set("stair-down", "#0288d1");
        this.linkColors.set("escalator-up", "#0288d1");
        this.linkColors.set("not-escalator-up", "#0288d1");
        this.linkColors.set("not-escalator-down", "#0288d1");
        this.linkColors.set("escalator-down", "#0288d1");
        this.linkColors.set("lift-up", "#0288d1");
        this.linkColors.set("lift-down", "#0288d1");
        this.linkColors.set("ramp-up", "#0288d1");
        this.linkColors.set("ramp-down", "#0288d1");
        this.linkColors.set("disabled", "#d0bcfe");
        this.linkColors.set("closed", "#d32f2f");

        /* this.layerPositions = new Map(); */
        /* this.layerNodes = []; */

        const getBaseUrl = () => {
            let url;
            switch (process.env.REACT_APP_PATH_TO_API) {
                case 'production ':
                    url = 'https://creator.guide3d.com';
                    break;
                case 'development ':
                default:
                    url = 'http://api.creator.local';
            }

            return url;
        }

        this.apiUrl = getBaseUrl(); // http://api.creator.local | https://creator.guide3d.com/

        this.select_from = window.document.getElementById("fromlist") as HTMLInputElement;
        this.select_to = window.document.getElementById("tolist") as HTMLInputElement;

        this.testPlaneMat = new StandardMaterial("testPlaneMaterial", currentScene);
        /* this.testPlaneMat.diffuseColor = new Color3(1, 1, 1); */
        this.testPlaneMat.emissiveColor = new Color3(1, 1, 1);
        this.testPlaneMat.alpha = 0.5;

        this.scene = currentScene;

        this.hl_Layer = new HighlightLayer("hl", currentScene);

        this.startPointMat = new StandardMaterial("startMat", currentScene);
        this.startPointMat.diffuseColor = new Color3(0.5, 0, 0);
        this.startPointMat.specularColor = new Color3(0, 0, 0);
        this.startPointMat.emissiveColor = new Color3(0.5, 0, 0);

        this.endPointMat = new StandardMaterial("endMat", currentScene);
        this.endPointMat.diffuseColor = new Color3(0.0015, 0.1115, 0.5); // 0.003, 0.223, 1
        this.endPointMat.specularColor = new Color3(0, 0, 0);
        this.endPointMat.emissiveColor = new Color3(0.0015, 0.1115, 0.5);

        this.defaultpointMat = new StandardMaterial("defaultMat1", currentScene);
        this.defaultpointMat.diffuseColor = new Color3(0.1545, 0.396, 0.0685); // 0.309, 0.792, 0.137
        this.defaultpointMat.specularColor = new Color3(0, 0, 0);
        this.defaultpointMat.emissiveColor = new Color3(0.1545, 0.396, 0.0685); // 0.1545,0.396,0.0685

        this.hlMat = new PBRMetallicRoughnessMaterial("pbr", currentScene);
        this.hlMat.baseColor = new Color3(0.847, 0.933, 0.933);
        this.hlMat.metallic = 0.5;
        this.hlMat.roughness = 0.15;
        this.hlMat.alpha = 0.7;
        this.hlMat.environmentTexture = new CubeTexture("res/environment/skybox", currentScene);

        this.defaultStartBox = MeshBuilder.CreateBox("box", { size: 0.2 }, currentScene);
        this.defaultStartBox.material = this.startPointMat;
        this.defaultStartBox.alwaysSelectAsActiveMesh = true;
        this.defaultStartBox.doNotSyncBoundingInfo = true;
        this.defaultStartBox.renderingGroupId = 0;

        this.defaultEndBox = MeshBuilder.CreateBox("box", { size: 0.2 }, currentScene);
        this.defaultEndBox.material = this.endPointMat;
        this.defaultEndBox.alwaysSelectAsActiveMesh = true;
        this.defaultEndBox.doNotSyncBoundingInfo = true;
        this.defaultEndBox.renderingGroupId = 0;

        this.defaultBox = MeshBuilder.CreateBox("box", { size: 0.2 }, currentScene);
        this.defaultBox.material = this.defaultpointMat;
        this.defaultBox.alwaysSelectAsActiveMesh = true;
        this.defaultBox.doNotSyncBoundingInfo = true;
        this.defaultBox.renderingGroupId = 0;

        this.defaultHighlightBox = this.defaultBox.clone();
        this.endHighlightBox = this.defaultEndBox.clone();
        this.startHighlightBox = this.defaultStartBox.clone();

        this.hl_Layer.addMesh(this.defaultHighlightBox, Color3.Magenta());
        this.hl_Layer.addMesh(this.endHighlightBox, Color3.Magenta());
        this.hl_Layer.addMesh(this.startHighlightBox, Color3.Magenta());


        // this.defaultTube = MeshBuilder.CreateBox("box", { width: 1, height: 0.06, depth: 0.06 }, this.scene);
        this.defaultconnectionMat = new StandardMaterial("linkMat", currentScene);
        this.defaultconnectionMat.diffuseColor = new Color3(0, 0, 0);
        this.defaultconnectionMat.specularColor = new Color3(0, 0, 0);
        this.defaultconnectionMat.emissiveColor = new Color3(0.023, 0.109, 0.145);
        this.defaultconnectionMat.backFaceCulling = true;

        /* const planeMat = new StandardMaterial("linkMat", currentScene);
        planeMat.diffuseColor = new Color3(0, 0, 0);
        planeMat.specularColor = new Color3(0, 0, 0);
        planeMat.emissiveColor = new Color3(0.023, 0.109, 0.145); */ //rgb(6,28,37) => 0.023, 0.109, 0.145
        /*         planeMat.emissiveColor = new Color3(0.394, 0.414, 0.029); 
                planeMat.specularColor = new Color3(0.3, 0.3, 0.3); */
        /* planeMat.backFaceCulling = true; */
        /* planeMat.wireframe =true; */
        // this.defaultTube.material = planeMat;
        // this.defaultTube.bakeTransformIntoVertices(Matrix.Translation(-0.5, 0, 0).multiply(Matrix.RotationY(Math.PI / 2)));

        this.defaultTube = new Mesh("box", currentScene);
        const sizeValue: number = 0.075;
        const lengthValue: number = 1;
        const x1 = -sizeValue;
        const x2 = sizeValue;
        const y1 = -sizeValue;
        const y2 = sizeValue;
        const positions = [x2, y1, 0, 0, 0, lengthValue, 0, y2, 0, x1, y1, 0, 0, y2, 0, 0, 0, lengthValue, x1, y1, 0, 0, 0, lengthValue, x2, y1, 0];
        const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8];

        const vertexData = new VertexData();
        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.applyToMesh(this.defaultTube);
        this.defaultTube.convertToFlatShadedMesh();
        this.defaultTube.material = this.defaultconnectionMat;
        this.defaultTube.alwaysSelectAsActiveMesh = true;
        this.defaultTube.doNotSyncBoundingInfo = true;

        this.defaultTube.renderingGroupId = 0;





        /* this.specialTube = MeshBuilder.CreateBox("box", { width: 1, height: 0.06, depth: 0.06 }, this.scene); */
        this.specialTube = new Mesh("box", currentScene);
        const specialMat = new StandardMaterial("linkSpecialMat", currentScene);
        specialMat.diffuseColor = new Color3(0, 0, 0);
        specialMat.specularColor = new Color3(0, 0, 0);
        /* specialMat.emissiveColor = new Color3(1, 0, 0); */
        specialMat.emissiveColor = Color3.FromHexString('#d9a336').toLinearSpace();
        vertexData.applyToMesh(this.specialTube);
        this.specialTube.material = specialMat;
        this.specialTube.alwaysSelectAsActiveMesh = true;
        this.specialTube.doNotSyncBoundingInfo = true;

        this.specialTube_blue = new Mesh("box", currentScene);
        const specialMat_blue = new StandardMaterial("linkspecialMat_blue", currentScene);
        specialMat_blue.diffuseColor = new Color3(0, 0, 0);
        specialMat_blue.specularColor = new Color3(0, 0, 0);
        /* specialMat_blue.emissiveColor = new Color3(1, 0, 0); */
        specialMat_blue.emissiveColor = Color3.FromHexString('#0288d1').toLinearSpace();
        vertexData.applyToMesh(this.specialTube_blue);
        this.specialTube_blue.material = specialMat_blue;
        this.specialTube_blue.alwaysSelectAsActiveMesh = true;
        this.specialTube_blue.doNotSyncBoundingInfo = true;

        this.specialTube_pink = new Mesh("box", currentScene);
        const specialMat_pink = new StandardMaterial("linkspecialMat_pink", currentScene);
        specialMat_pink.diffuseColor = new Color3(0, 0, 0);
        specialMat_pink.specularColor = new Color3(0, 0, 0);
        /* specialMat_pink.emissiveColor = new Color3(1, 0, 0); */
        specialMat_pink.emissiveColor = Color3.FromHexString('#d0bcfe').toLinearSpace();
        vertexData.applyToMesh(this.specialTube_pink);
        this.specialTube_pink.material = specialMat_pink;
        this.specialTube_pink.alwaysSelectAsActiveMesh = true;
        this.specialTube_pink.doNotSyncBoundingInfo = true;

        this.specialTube_red = new Mesh("box", currentScene);
        const specialMat_red = new StandardMaterial("linkspecialMat_red", currentScene);
        specialMat_red.diffuseColor = new Color3(0, 0, 0);
        specialMat_red.specularColor = new Color3(0, 0, 0);
        /* specialMat_red.emissiveColor = new Color3(1, 0, 0); */
        specialMat_red.emissiveColor = Color3.FromHexString('#d32f2f').toLinearSpace();
        vertexData.applyToMesh(this.specialTube_red);
        this.specialTube_red.material = specialMat_red;
        this.specialTube_red.alwaysSelectAsActiveMesh = true;
        this.specialTube_red.doNotSyncBoundingInfo = true;

        this.hlTube = this.defaultTube.clone();
        this.hl_Layer.addMesh(this.hlTube, Color3.Magenta());
        this.hlTube.renderingGroupId = 1;

        this.hlTube_special = this.specialTube.clone();
        this.hl_Layer.addMesh(this.hlTube_special, Color3.FromHexString('#d9a336').toLinearSpace());
        this.hlTube_special.renderingGroupId = 1;

        this.hlTube_blue = this.specialTube_blue.clone();
        this.hl_Layer.addMesh(this.hlTube_blue, Color3.FromHexString('#0288d1').toLinearSpace());
        this.hlTube_blue.renderingGroupId = 1;

        this.hlTube_pink = this.specialTube_pink.clone();
        this.hl_Layer.addMesh(this.hlTube_pink, Color3.FromHexString('#d0bcfe').toLinearSpace());
        this.hlTube_pink.renderingGroupId = 1;

        this.hlTube_red = this.specialTube.clone();
        this.hl_Layer.addMesh(this.hlTube_red, Color3.FromHexString('#d32f2f').toLinearSpace());
        this.hlTube_red.renderingGroupId = 1;


        // this.specialTube.bakeTransformIntoVertices(Matrix.Translation(-0.5, 0, 0).multiply(Matrix.RotationY(Math.PI / 2)));


        /* this.pointsArray = [];
        this.pointsPositions = [];

        this.layerIdsArray = [];
        this.layerNodesArray = []; */

        this.layerIdsArray = [];
        this.pointsArray = [];
        this.pointsPositions = [];

        // GUI
        /* this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.guiTexture.idealWidth = 600; */

    }

    /* public setLayerPositions(layPosMap: Map<string, Vector3>) {
        this.layerPositions = new Map();
        this.layerPositions = layPosMap;
    } */

    /* public provideDatavault(obj: DataVault) {
        this.dataVault = obj;
    } */

    public initLinkAdd() {
        this.gizmoManager.usePointerToAttachGizmos = false;
        this.raycastHitForLink = true;
    }

    public deleteMesh(id: string) {
        const mesh: Mesh = this.scene.getMeshByName(id) as Mesh;
        mesh.dispose();
    }

    public deleteLinkFromMetadata(id: string) {
        const fromId: string = id.substring(0, 8);
        const toId: string = id.substring(9);
        const box1: Mesh = this.scene.getMeshByName(fromId) as Mesh;
        const box2: Mesh = this.scene.getMeshByName(toId) as Mesh;

        if (box1) {
            let box1LinkToDelete: number = -1;
            box1.metadata.links.forEach((element: string, index: number) => {
                if (element === toId) {
                    box1LinkToDelete = index;
                }
            });
            if (box1LinkToDelete !== -1) {
                box1.metadata.links.splice(box1LinkToDelete, 1);
            }

            const box1MetadataLinks: string[] = [...box1.metadata.links] as string[];

            const newPointObjectBox1: PointObject = {
                id: fromId,
                links: box1MetadataLinks,
                position: { x: box1.position.x, y: box1.position.y, z: box1.position.z }
            }

            box1.dispose();

            this.buildPoint(newPointObjectBox1, false);
        }

        if (box2) {
            let box2LinkToDelete: number = -1;
            box2.metadata.links.forEach((element: string, index: number) => {
                if (element === fromId) {
                    box2LinkToDelete = index;
                }
            });
            if (box2LinkToDelete !== -1) {
                box2.metadata.links.splice(box2LinkToDelete, 1);
            }

            const box2MetadataLinks: string[] = [...box2.metadata.links] as string[];

            const newPointObjectBox2: PointObject = {
                id: toId,
                links: box2MetadataLinks,
                position: { x: box2.position.x, y: box2.position.y, z: box2.position.z }
            }

            box2.dispose();

            this.buildPoint(newPointObjectBox2, false);
        }


        /* const box1MetadataLinks: string[] = [...box1.metadata.links] as string[];
        const box2MetadataLinks: string[] = [...box2.metadata.links] as string[];


        const newPointObjectBox1: PointObject = {
            id: fromId,
            links: box1MetadataLinks,
            position: { x: box1.position.x, y: box1.position.y, z: box1.position.z }
        }

        const newPointObjectBox2: PointObject = {
            id: toId,
            links: box2MetadataLinks,
            position: { x: box2.position.x, y: box2.position.y, z: box2.position.z }
        }

        box1.dispose();
        box2.dispose();

        this.buildPoint(newPointObjectBox1, false);
        this.buildPoint(newPointObjectBox2, false); */

    }

    public highlightMesh(id: string) {
        this.lastHlMesh?.setEnabled(true);
        this.defaultHighlightBox.setEnabled(false);
        this.endHighlightBox.setEnabled(false);
        this.startHighlightBox.setEnabled(false);
        this.hlTube.setEnabled(false);
        this.hlTube_special.setEnabled(false);
        this.hlTube_blue.setEnabled(false);
        this.hlTube_pink.setEnabled(false);
        this.hlTube_red.setEnabled(false);
        if (id.length > 8) {
            const meshTube: Mesh = this.scene.getMeshByName(id) as Mesh;
            this.lastHlMesh = meshTube;
            let hl_tube_now: Mesh;
            const linkType: string = this.oRouteHelper.getLinkType(id);
            switch (linkType) {
                case "default":
                    hl_tube_now = this.hlTube;
                    break;
                case "other":
                    hl_tube_now = this.hlTube_special;
                    break;
                case "stair-up":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "stair-down":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "escalator-up":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "not-escalator-up":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "not-escalator-down":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "escalator-down":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "lift-up":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "lift-down":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "ramp-up":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "ramp-down":
                    hl_tube_now = this.hlTube_blue;
                    break;
                case "disabled":
                    hl_tube_now = this.hlTube_pink;
                    break;
                case "closed":
                    hl_tube_now = this.hlTube_red;
                    break;

                default:
                    hl_tube_now = this.hlTube;
                    break;
            }

            hl_tube_now.setEnabled(true);
            meshTube.setEnabled(false);
            hl_tube_now.position = meshTube.position;
            hl_tube_now.rotation = meshTube.rotation;
            hl_tube_now.scaling = meshTube.scaling;
            this._addOverlay(meshTube as Mesh);
        }
        else {
            const mesh: Mesh = this.scene.getMeshByName(id) as Mesh;
            this.lastHlMesh = mesh;
            /* console.log("HL mesh: ", mesh.material); */
            /* this.defaultHighlightBox = mesh.clone(); */
            switch (mesh.material?.id) {
                case "endMat":
                    this.endHighlightBox.setEnabled(true);
                    this.endHighlightBox.position = mesh.position;
                    this.endHighlightBox.rotation = mesh.rotation;
                    this.endHighlightBox.scaling = mesh.scaling;
                    break;
                case "defaultMat1":
                    this.defaultHighlightBox.setEnabled(true);
                    this.defaultHighlightBox.position = mesh.position;
                    this.defaultHighlightBox.rotation = mesh.rotation;
                    this.defaultHighlightBox.scaling = mesh.scaling;
                    break;
                case "startMat":
                    this.startHighlightBox.setEnabled(true);
                    this.startHighlightBox.position = mesh.position;
                    this.startHighlightBox.rotation = mesh.rotation;
                    this.startHighlightBox.scaling = mesh.scaling;
                    break
                default:
                    break;
            }
            mesh.setEnabled(false);
            this._addOverlay(mesh as Mesh);
        }
    }

    public deHighlightLastTube() {
        try {
            this._addOverlay(null);
            this.hlTube.setEnabled(false);
            this.hlTube_special.setEnabled(false);
            this.hlTube_blue.setEnabled(false);
            this.hlTube_pink.setEnabled(false);
            this.hlTube_red.setEnabled(false);
            this.lastHlMesh.setEnabled(true);
        } catch (error) {
            // console.log("deHighlightLastTube ERROR: ", error);
        }

    }

    public deHighlightMesh(id: string) {
        if (id.length > 8) {
            const meshTube: Mesh = this.scene.getMeshByName(id) as Mesh;
            this._addOverlay(null);
            this.hlTube.setEnabled(false);
            this.hlTube_special.setEnabled(false);
            this.hlTube_blue.setEnabled(false);
            this.hlTube_pink.setEnabled(false);
            this.hlTube_red.setEnabled(false);
            meshTube.setEnabled(true);
        }
        else {
            const mesh: Mesh = this.scene.getMeshByName(id) as Mesh;
            this._addOverlay(null);
            /* console.log("deHL mesh: ", mesh); */
            this.defaultHighlightBox.setEnabled(false);
            this.endHighlightBox.setEnabled(false);
            this.startHighlightBox.setEnabled(false);
            mesh.setEnabled(true);
        }
    }

    public focusOnMesh(id: string) {
        if (id.length > 8) {
            const fromMesh: Mesh = this.scene.getMeshByName(id.substring(0, 8)) as Mesh;
            const toMesh: Mesh = this.scene.getMeshByName(id.substring(9)) as Mesh;

            /* console.log("fromMesh.position: ", fromMesh.position);
            console.log("toMesh.position: ", toMesh.position); */
            const tubeCenterPre: Vector3 = (fromMesh.position.subtract(toMesh.position));
            /* console.log("tubeCenterPre: ", tubeCenterPre); */
            const tubeCenter: Vector3 = toMesh.position.add(tubeCenterPre.scale(1 / 2));
            /* console.log("tubeCenter: ", tubeCenter); */
            Animation.CreateAndStartAnimation("camtarget", this.scene.activeCamera, "target", 30, 15, (this.scene.activeCamera as ArcRotateCamera).target, (tubeCenter), Animation.ANIMATIONLOOPMODE_RELATIVE);
        }
        else {
            Animation.CreateAndStartAnimation("camtarget", this.scene.activeCamera, "target", 30, 15, (this.scene.activeCamera as ArcRotateCamera).target, (new Vector3(this.lastHlMesh.position.x, this.lastHlMesh.position.y, this.lastHlMesh.position.z)), Animation.ANIMATIONLOOPMODE_RELATIVE);
        }


        // Animation.CreateAndStartAnimation("camAlpha", this.scene.activeCamera, "alpha", 30, 15, (this.scene.activeCamera as ArcRotateCamera).alpha, alpha, Animation.ANIMATIONLOOPMODE_RELATIVE);
        // Animation.CreateAndStartAnimation("camRadius", this.camera, "radius", 30, 15, this.camera.radius, 15, Animation.ANIMATIONLOOPMODE_RELATIVE);
        // Animation.CreateAndStartAnimation("camBeta", this.scene.activeCamera, "beta", 30, 15, (this.scene.activeCamera as ArcRotateCamera).beta, beta, Animation.ANIMATIONLOOPMODE_RELATIVE); */
    }

    public setLayerArrays(layerIDs: string[], layerNodes: TransformNode[]) {
        this.layerIdsArray = layerIDs;
        this.layerNodesArray = layerNodes;
    }

    public getPointsArray(): InstancedMesh[] {
        return this.pointsArray;
    }

    public removeMesh(mesh: InstancedMesh) {
        this.pointsArray = this.pointsArray.filter(item => item !== mesh);
    }

    public toggleDefaultLinks() {
        if (this.defaultTube.isEnabled()) {
            console.log("this.defaultTube.setEnabled(false);");
            this.defaultTube.setEnabled(false);
        }
        else {
            this.defaultTube.setEnabled(true);
        }
    }

    public buildPoint(point: PointObject, start: boolean) {
        let addIndex: number = -1;

        addIndex = this.layerIdsArray.indexOf(point.id.substring(0, 3));

        if (start) {
            if (this.startPoints.indexOf(point.id) === -1) {
                this.startPoints.push(point.id);
            }
            const startbox = this.defaultStartBox.createInstance(point.id);
            startbox.metadata = { "links": point.links };

            this.pointsArray.push(startbox);
            startbox.position = new Vector3(point.position.x, point.position.y, point.position.z);
            this.pointsPositions.push(startbox.position);
            if (addIndex !== -1) {
                startbox.parent = this.layerNodesArray[addIndex];
            }
        }
        else {
            if (point.links.length === 1) {
                if (this.endPoints.indexOf(point.id) === -1) {
                    this.endPoints.push(point.id);
                }
                const endbox = this.defaultEndBox.createInstance(point.id);
                endbox.metadata = { "links": point.links };

                // const positionLink: Vector3 = new Vector3(Number(element.position.x._text), Number(element.position.y._text), Number(element.position.z._text));

                this.pointsArray.push(endbox);
                endbox.position = new Vector3(point.position.x, point.position.y, point.position.z);
                /* endbox.lookAt(this.dataVault.getPointPosition(linkIds[0])); */
                this.pointsPositions.push(endbox.position);
                if (addIndex !== -1) {
                    endbox.parent = this.layerNodesArray[addIndex];
                }
            }
            else {
                if (this.wayPoints.indexOf(point.id) === -1) {
                    this.wayPoints.push(point.id);
                }
                const linkbox = this.defaultBox.createInstance(point.id);
                linkbox.metadata = { "links": point.links };
                // console.log("linkbox.metadata: ", linkbox.metadata);

                this.pointsArray.push(linkbox);
                linkbox.position = new Vector3(point.position.x, point.position.y, point.position.z);
                this.pointsPositions.push(linkbox.position);
                if (addIndex !== -1) {
                    linkbox.parent = this.layerNodesArray[addIndex];
                }
            }
        }
    }

    /*  public buildHighlighter(id: string, coords: Vector3[]) {
         let addIndex: number = -1;
         addIndex = this.layerIdsArray.indexOf(id.substring(0, 3));
         const extruded = MeshBuilder.ExtrudePolygon("polygon", { shape: coords, depth: 2, sideOrientation: Mesh.DOUBLESIDE }, this.scene, earcut);
         extruded.material = this.hlMat;
         extruded.parent = this.layerNodesArray[addIndex];
 
         const layerPos: Vector3 = this.layerPositions?.get(id.substring(0, 3)) as Vector3;
         extruded.position.y = layerPos.y + 1.9;
         extruded.bakeCurrentTransformIntoVertices();
 
         extruded.enableEdgesRendering();
         extruded.edgesWidth = 16.0;
         extruded.edgesColor = new Color4(0.6, 0.6, 0.6, 1);
         let scaleFactor: number = 0.15;
 
         const bi: BoundingInfo = extruded.getBoundingInfo();
         const scaleFactorX: number = 1 - (1 / bi.boundingBox.extendSize._x) * scaleFactor;
         const scaleFactorZ: number = 1 - (1 / bi.boundingBox.extendSize._z) * scaleFactor;
         const pivotPoint: Vector3 = bi.boundingBox.centerWorld;
         const _sx = scaleFactorX / extruded.scaling.x;
         const _sy = 1 / extruded.scaling.y;
         const _sz = scaleFactorZ / extruded.scaling.z;
 
         extruded.scaling = new Vector3(scaleFactorX, 1, scaleFactorZ);
         extruded.position = new Vector3(pivotPoint.x + _sx * (extruded.position.x - pivotPoint.x), pivotPoint.y + _sy * (extruded.position.y - pivotPoint.y), pivotPoint.z + _sz * (extruded.position.z - pivotPoint.z));
 
     } */

    public populateDropdowns() {
        this.select_from.innerHTML = '';
        this.select_to.innerHTML = '';


        const labelSp: HTMLOptGroupElement = window.document.createElement("optgroup");
        labelSp.setAttribute("style", "background-color: #ff0000; color: #ffffff;");
        labelSp.label = "Startpoints:";

        this.startPoints.sort();

        this.startPoints.forEach(element => {
            const optSp: HTMLOptionElement = window.document.createElement("option");
            optSp.setAttribute("style", "background-color: #ff0000; color: #ffffff;");
            optSp.value = element;
            optSp.innerHTML = element;
            labelSp.appendChild(optSp);
        });

        const labelSp2: HTMLOptGroupElement = labelSp.cloneNode(true) as HTMLOptGroupElement;

        this.select_from.appendChild(labelSp);
        this.select_to.appendChild(labelSp2);

        /* this.select_from.setAttribute("size","30");
        this.select_to.setAttribute("size","30"); */

        const labelEp: HTMLOptGroupElement = window.document.createElement("optgroup");
        labelEp.setAttribute("style", "background-color: #0000ff; color: #ffffff;");
        labelEp.label = "Endpoints:";

        this.endPoints.sort();

        this.endPoints.forEach(element => {
            const optEp: HTMLOptionElement = window.document.createElement("option");
            optEp.setAttribute("style", "background-color: #0000ff; color: #ffffff;");
            optEp.value = element;
            optEp.innerHTML = element;
            labelEp.appendChild(optEp);
        });

        const labelEp2: HTMLOptGroupElement = labelEp.cloneNode(true) as HTMLOptGroupElement;

        this.select_from.appendChild(labelEp);
        this.select_to.appendChild(labelEp2);


        const labelWp: HTMLOptGroupElement = window.document.createElement("optgroup");
        labelWp.setAttribute("style", "background-color: #00ff00; color: #ffffff;");
        labelWp.label = "Waypoints:";

        this.wayPoints.sort();

        this.wayPoints.forEach(element => {
            const optWp: HTMLOptionElement = window.document.createElement("option");
            optWp.setAttribute("style", "background-color: #00ff00;");
            optWp.value = element;
            optWp.innerHTML = element;
            labelWp.appendChild(optWp);
        });

        const labelWp2: HTMLOptGroupElement = labelWp.cloneNode(true) as HTMLOptGroupElement;

        this.select_from.appendChild(labelWp);
        this.select_to.appendChild(labelWp2);
    }

    public buildLinkFromPoint(point: PointObject, link: string, linkPos?: IPositionX3dType) {
        const positionbox: Vector3 = new Vector3(point.position.x, point.position.y, point.position.z);

        let linkPositionX3D: IPositionX3dType;
        if (linkPos !== undefined) {
            linkPositionX3D = linkPos;
        }
        else {
            linkPositionX3D = this.oRouteHelper.getPointPositionForId(link);
        }

        // const linkPositionX3D: IPositionX3dType = this.oRouteHelper.getPointPositionForId(link);
        const positionlink: Vector3 = new Vector3(linkPositionX3D.x, linkPositionX3D.y, linkPositionX3D.z);

        let addIndex: number = -1;
        addIndex = this.layerIdsArray.indexOf(point.id.substring(0, 3));

        let linkBox: InstancedMesh;

        const linkType: string = this.oRouteHelper.getLinkType(point.id + "-" + link);

        /* if (linkType !== "default") {
            linkBox = this.specialTube.createInstance(point.id + "-" + link);
        }
        else {
            linkBox = this.defaultTube.createInstance(point.id + "-" + link);
        } */

        switch (linkType) {
            case "default":
                linkBox = this.defaultTube.createInstance(point.id + "-" + link);
                break;
            case "other":
                linkBox = this.specialTube.createInstance(point.id + "-" + link);
                break;
            case "stair-up":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "stair-down":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "escalator-up":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "not-escalator-up":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "not-escalator-down":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "escalator-down":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "lift-up":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "lift-down":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "ramp-up":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "ramp-down":
                linkBox = this.specialTube_blue.createInstance(point.id + "-" + link);
                break;
            case "disabled":
                linkBox = this.specialTube_pink.createInstance(point.id + "-" + link);
                break;
            case "closed":
                linkBox = this.specialTube_red.createInstance(point.id + "-" + link);
                break;

            default:
                linkBox = this.defaultTube.createInstance(point.id + "-" + link);
                break;
        }

        const dist = Vector3.Distance(positionbox, positionlink);
        linkBox.name = point.id + "-" + link;
        linkBox.position = positionbox;
        linkBox.scaling.z = dist;
        linkBox.lookAt(positionlink);
        if (addIndex !== -1) {
            linkBox.parent = this.layerNodesArray[addIndex];
        }
    }

    public buildLinksForPoint(point: PointObject/* , wightsDefault, weightsSpecial, posBox: Vector3, posLink: Vector3, box: Mesh, link */) {
        /* const movingWeightsDefault = wightsDefault;
        const movingWeightsSpecial = weightsSpecial; */
        // const positionbox: Vector3 = new Vector3(point.position.x,point.position.y,point.position.z);//posBox;

        point.links.forEach(link => {
            this.buildLinkFromPoint(point, link)
        });



        /* const positionlink: Vector3 = posLink;
        let addIndex: number = -1;
        addIndex = this.layerIdsArray.indexOf(box.name.substring(0, 3));
        let linkBox: InstancedMesh;
        const defaultSpecial: string = link["cost-factor"].moving.default.type._text;
        let bDefaultSpecial: boolean = false;
        if (defaultSpecial !== "default") {
            bDefaultSpecial = true
        }
        if (movingWeightsSpecial || (bDefaultSpecial === true)) {
            linkBox = this.specialTube.createInstance("link");
        }
        else {
            linkBox = this.defaultTube.createInstance("link");
        }
        const dist = Vector3.Distance(positionbox, positionlink);
        linkBox.name = box.name + "_" + link._attributes.point;
        linkBox.position = positionbox;
        linkBox.scaling.z = dist;
        linkBox.lookAt(positionlink);
        if (addIndex !== -1) {
            linkBox.parent = this.layerNodesArray[addIndex];
        } */
    }

    // const [selectedNetIds, setSelectedNetIds] = useState<string[]>([]);
    public buildMasterPlane(
        allPoints: PointObject[],
        allLayers: string[],
        layerFiles: { [key: string]: LayerFile },
        layerNodes: TransformNode[],
        setSelectedLayer: React.Dispatch<React.SetStateAction<string>>,
        selectedNetIds: string[],
        setSelectedNetIds: React.Dispatch<React.SetStateAction<string[]>>,
        setNetLoaded: React.Dispatch<React.SetStateAction<boolean>>,
        bjsScene: Scene,
        camera: ArcRotateCamera
    ): LayersBabylonDataObject {
        this.scene = bjsScene;
        let _layerPositions: Map<string, Vector3> = new Map();
        let _layerMaterials: Map<string, StandardMaterial> = new Map();
        let _masterPlane: Mesh;

        const coordsA: number[] = this.oRouteHelper.getX3dPositionFromSVGCoords([0, 4096]);
        const coordsB: number[] = this.oRouteHelper.getX3dPositionFromSVGCoords([4096, 0]);

        const aX: number = coordsA[0];
        const aZ: number = coordsA[1];

        const bX: number = coordsB[0];
        const bZ: number = coordsB[1];
        let pointA: Vector3 = new Vector3(aX, 0, aZ);
        /* let pointA2: Vector3 = new Vector3(aX,0,bZ); */
        let pointB: Vector3 = new Vector3(bX, 0, bZ);

        let planewidth: number = Math.abs(pointA.x) + Math.abs(pointB.x);
        if (pointA.x > 0) {
            planewidth = Math.abs(pointB.x) - Math.abs(pointA.x);
        }


        _masterPlane = MeshBuilder.CreatePlane("plane", { width: planewidth, height: planewidth, sideOrientation: Mesh.DOUBLESIDE }, bjsScene);
        /* this.masterPlane.position = new Vector3((pointA.x + (planewidth / 2)), yValue, (pointA.z - (planewidth / 2))); */
        _masterPlane.rotation.x = 4.712389;
        _masterPlane.isPickable = false;


        /* this.pathsParent = new TransformNode("paths_root"); */

        let _layerPointsCountMap: Map<string, number> = new Map();

        let lastLayerHeight: number = 0;

        const reverseLayers: string[] = [...allLayers];
        reverseLayers.reverse();

        reverseLayers.forEach(layer => {
            const layerPointIds: string[] = [];
            let sumY: number = 0.0123;
            let counter: number = 0;
            const layerId: string = layer;
            allPoints.forEach((point, index) => {
                const pointId: string = point.id;
                if (pointId.indexOf(layerId) === 0) {
                    layerPointIds.push(pointId);
                    const yCoord: number = Number(point.position.y);
                    if (sumY === 0.0123) {
                        sumY = yCoord;
                    }
                    else {
                        sumY += yCoord;
                    }
                    counter++
                }
            });
            _layerPointsCountMap.set(layerId, counter);
            this.pointIdLists.push(layerPointIds);
            let yValue: number = 0;
            if (sumY === 0.0123) {
                yValue = lastLayerHeight + 5;
            }
            else {
                yValue = (sumY / counter) - 0.2;
            }
            lastLayerHeight = yValue;
            const vec3: Vector3 = new Vector3((pointA.x + (planewidth / 2)), yValue, (pointA.z - (planewidth / 2)));
            _layerPositions.set(layer, vec3);
            /* console.log("added layerPos! => ", _layerPositions); */
            // mapFiles.files[mapsAssistant_layerImages_index].png.thumbnail


            if (sumY !== 987654) {
                /* console.log("layer: ", layer);
                console.log("this.mainMapData.layerIds: ", this.mainMapData.layerIds); */
                /* if (this.mainMapData.layerIds?.indexOf(layer) !== -1) {
                    this.layerIDs.push(layer); */
                const myMaterial = new StandardMaterial("myMaterial", bjsScene);

                /* apiUrl + props?.referenceProp.mapFiles.files[mapsAssistant_layerImages_index].png.thumbnail; */
                /* this.apiUrl + this.layerFiles[layer].png.thumbnail */
                /* console.log("layer: ", layer);
                console.log("this.layerFiles: ", this.layerFiles); */

                myMaterial.diffuseTexture = new Texture((this.apiUrl + layerFiles[layer].png.thumbnail), bjsScene);

                myMaterial.specularColor = new Color3(0, 0, 0);
                myMaterial.emissiveColor = new Color3(1, 1, 1);
                _layerMaterials.set(layer, myMaterial);
                /* const transformRoot = new TransformNode((layer + "_root"));
                this.layerNodes.push(transformRoot); */

            }
        });
        /* this.setLayerPositions(layerPositions); */
        /* this.setLayerArrays(this.layerIDs, this.layerNodes); */

        /* this.setStartupListVisibility(
            allLayers,
            _layerPositions,
            _masterPlane,
            layerNodes,
            _layerMaterials,
            _layerPointsCountMap,
            setSelectedLayer,
            selectedNetIds,
            setSelectedNetIds,
            setNetLoaded,
            bjsScene,
            camera
        ); */

        /* setTimeout(() => {
            setNetLoaded(true);
        }, 2500); */

        const layPosMat: LayersBabylonDataObject = {
            layerPositions: _layerPositions,
            layerMaterials: _layerMaterials,
            layerPointsCountMap: _layerPointsCountMap,
            masterPlane: _masterPlane,
            setSelectedLayer: setSelectedLayer,
        }
        return layPosMat;
    }

    public setStartupListVisibility(
        allLayers: string[],
        layerPositions: Map<string, Vector3>,
        masterPlane: Mesh,
        layerNodes: TransformNode[],
        layerMaterials: Map<string, StandardMaterial>,
        _layerPointsCountMap: Map<string, number>,
        setSelectedLayer: React.Dispatch<React.SetStateAction<string>>,
        selectedNetIds: string[],
        setSelectedNetIds: React.Dispatch<React.SetStateAction<string[]>>,
        setNetLoaded: React.Dispatch<React.SetStateAction<boolean>>,
        bjsScene: Scene,
        camera: ArcRotateCamera
    ) {
        this.layerMaterials = layerMaterials;
        let startUpIndex: number = 0;
        let highestCount: number = 0;
        allLayers.forEach((layerID, index) => {
            const layerCount: number = _layerPointsCountMap.get(layerID) as number;
            /* console.log("layerCount for " + layerID + " = " + layerCount); */
            if (layerCount > highestCount) {
                highestCount = layerCount;
                startUpIndex = index;
            }
        });


        /* masterPlane.position = layerPositions.get(allLayers[startUpIndex]) as Vector3;
        masterPlane.material = layerMaterials.get(allLayers[startUpIndex]) as StandardMaterial; */
        /* const netcheckboxChecked: HTMLInputElement = window.document.getElementById((this.layerIDs[startUpIndex] + "_net")) as HTMLInputElement;
        netcheckboxChecked.checked = true; */

        /* console.log("masterPlane.position: ", masterPlane.position); */

        // this.scene.activeCamera
        try {
            const cam: ArcRotateCamera = bjsScene.activeCamera as ArcRotateCamera;

            cam.setTarget(layerPositions.get(allLayers[startUpIndex]) as Vector3);
            cam.alpha = -Math.PI / 2;
            cam.beta = 1;
            cam.radius = 150;
        } catch (error) {
            // mach nix
        }


        let activeNetIds: string[] = [];
        allLayers.forEach(element => {
            if (element === allLayers[startUpIndex]) {
                this._setNetVisibility(element, true, layerNodes);
                activeNetIds.push(element);
            }
            else {
                this._setNetVisibility(element, false, layerNodes);
            }
        });
        setSelectedNetIds(activeNetIds);

        setTimeout(() => {
            this.setLayerVisibility(allLayers[startUpIndex], masterPlane, layerPositions, layerMaterials, setSelectedLayer);
            setNetLoaded(true);
        }, 500);
    }

    public setLayerVisibility(
        id: string,
        masterPlane: Mesh,
        layerPositions: Map<string, Vector3>,
        layerMaterials: Map<string, StandardMaterial>,
        setSelectedLayer: React.Dispatch<React.SetStateAction<string>>
    ) {
        if (id !== "") {
            if (masterPlane.position !== layerPositions.get(id)) {
                masterPlane.position = layerPositions.get(id) as Vector3;
                masterPlane.material = layerMaterials.get(id) as StandardMaterial;
                setSelectedLayer(id);
            }
        }
    }

    public updateNetVis(ids: string[], layerNodes: TransformNode[]) {
        let visIds: string[] = [];
        let inVisIds: string[] = [];

        layerNodes.forEach((node, index) => {
            const nodeID: string = node.name.substring(0, 3);
            if (ids.indexOf(nodeID) >= 0) {
                visIds.push(nodeID);
            }
            else {
                inVisIds.push(nodeID);
            }
        });

        visIds.forEach(id => {
            layerNodes.forEach((node) => {
                if (node.name.indexOf(id) >= 0) {
                    if (node.isEnabled() === false) {
                        node.setEnabled(true);
                    }
                }
            });
        });
        inVisIds.forEach(id => {
            layerNodes.forEach((node) => {
                if (node.name.indexOf(id) >= 0) {
                    if (node.isEnabled() === true) {
                        node.setEnabled(false);
                    }
                }
            });
        });
    }

    public _setNetVisibility(id: string, view: boolean, layerNodes: TransformNode[]) {
        /* console.log("_setNetVisibility for " + id + " to " + view); */
        /* let dirty: boolean = false; */
        layerNodes.forEach((element, index) => {
            if (element.name.indexOf(id) >= 0 && view) {
                if (element.isEnabled() === false) {
                    element.setEnabled(true);
                }
                /* this.masterPlane.position = this.layerPositions.get(id);
                this.masterPlane.material = this.layerMaterials.get(id); */
            }
            if (element.name.indexOf(id) >= 0 && !view) {
                if (element.isEnabled() === true) {
                    element.setEnabled(false);
                }
            }
            /* if (index === (this.layerNodes.length - 1) && dirty === true) {
                this.guiTexture.dispose();
                this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
                this.guiTexture.idealWidth = 600;
            } */
        });
    }

    public setlastGizomMesh(s: string, scene: Scene) {
        this.lastGizmoMesh = scene.getMeshByName(s) as Mesh;
    }

    public setupGizmoManager(scene: Scene, camera: ArcRotateCamera, displayPointData: (selectedMesh: Mesh) => void) {
        this.scene = scene;
        this.displayPointData = displayPointData;
        const placedPoints: InstancedMesh[] = this.getPointsArray();
        // console.log("setupGizmoManager, placedPoints: ", placedPoints);

        /* const gizmoManager = new GizmoManager(scene);
        gizmoManager.clearGizmoOnEmptyPointerEvent = false;

        gizmoManager.attachableMeshes = placedPoints;
        gizmoManager.positionGizmoEnabled = true;
        if (gizmoManager.gizmos.positionGizmo !== null) {
            gizmoManager.gizmos.positionGizmo.planarGizmoEnabled = false;
        }

        this.gizmoManager = gizmoManager; */

        this.gizmoManager = new GizmoManager(scene);
        this.gizmoManager.clearGizmoOnEmptyPointerEvent = false;

        this.gizmoManager.attachableMeshes = placedPoints;
        this.gizmoManager.positionGizmoEnabled = true;
        if (this.gizmoManager.gizmos.positionGizmo !== null) {
            this.gizmoManager.gizmos.positionGizmo.planarGizmoEnabled = false;
        }



        this.gizmoManager.onAttachedToMeshObservable.add((e) => {
            if (e !== null && this.userMode === "net") {
                /* this.lastGizmoMesh = e as Mesh; */
                this.setlastGizomMesh(e.name, scene);
                console.log("this.lastGizmoMesh: ", this.lastGizmoMesh);
                /* pointDiv.removeAttribute("style");
                pointIdSpan.innerHTML = "Selected Point: "; 
                this.currentId.value = e.name;*/

                this._copyIDtoclipboard(e.name);
                /* const alpha: number = camera.alpha;
                const beta: number = camera.beta; */

                /* this._updateLinkInfo(); */
                this._showSelectedPoint(e as Mesh);

                /* if (this.dataVault.getPointStartValue(e.name) === "true") {
                    startCheckbox.checked = true;
                }
                else {
                    startCheckbox.checked = false;
                } */

                /* console.log("pos from dataVault: " + this.dataVault.getPointPosition(e.name)); */


                /* this.posX.value = "" + e.position.x.toFixed(4);
                this.posY.value = "" + e.position.y.toFixed(4);
                this.posZ.value = "" + e.position.z.toFixed(4); */

                displayPointData(e as Mesh);

                Animation.CreateAndStartAnimation("camtarget", camera, "target", 30, 15, camera.target, (new Vector3(e.position.x, e.position.y, e.position.z)), Animation.ANIMATIONLOOPMODE_RELATIVE);
                //  Animation.CreateAndStartAnimation("camAlpha", camera, "alpha", 30, 15, camera.alpha, alpha, Animation.ANIMATIONLOOPMODE_RELATIVE);
                // Animation.CreateAndStartAnimation("camRadius", this.camera, "radius", 30, 15, this.camera.radius, 15, Animation.ANIMATIONLOOPMODE_RELATIVE);
                //  Animation.CreateAndStartAnimation("camBeta", camera, "beta", 30, 15, camera.beta, beta, Animation.ANIMATIONLOOPMODE_RELATIVE);


            }
            else {
                /* pointDiv.setAttribute("style", "display: none;")
                pointIdSpan.innerHTML = null;

                const linksDiv: HTMLDivElement = window.document.getElementById("linkinfo") as HTMLDivElement;
                while (linksDiv.firstChild) {
                    linksDiv.removeChild(linksDiv.firstChild);
                }
                if (e !== null && this.userMode === "paths") {
                    if (this.bControlDown) {
                        // set From
                        this.lastGizmoMesh = e as Mesh;
                        this.select_from.value = this.lastGizmoMesh.name;
                        this._checkPathsValidity();
                    }
                    if (this.bShiftDown) {
                        // set Start
                        this.lastGizmoMesh = e as Mesh;
                        this.select_to.value = this.lastGizmoMesh.name;
                        this._checkPathsValidity();
                    }
                } */
            }



        });

        // Planar Drag behaviour:
        this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragStartObservable.add(() => {
            this._addVisibleRays(scene);
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
            // TODO:
            /* this._savePositionForUndo(selectedMesh.name); */
        });
        this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragObservable.add(() => {
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
            this._updateLinks(selectedMesh, scene);
        });
        this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragEndObservable.add(() => {
            this._hideRays();
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
            this.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
            /* this.dataVault.setPointPosition(selectedMesh.name, selectedMesh.position);
            // TODO:
            this._updateRotationForSingleLinks();
            this._renewGraphs(); */
        });

        // X-axis Drag behaviour:
        this.gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragStartObservable.add(() => {
            this._addVisibleRays(scene);
            /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo.xGizmo.attachedMesh as Mesh;
            this._savePositionForUndo(selectedMesh.name);
            if (this.bControlDown) {
                this._createNewPointMesh();
            } */
        });
        this.gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragObservable.add((e) => {
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.xGizmo.attachedMesh as Mesh;
            if (this.currentRayCastMesh) {
                // calculate angled position
                this.currentDragPlaneVector = e.dragPlaneNormal;
                this._calcAngles(selectedMesh, scene);
            }
            else {
                this._removeSnapPlane(selectedMesh.name, scene);
            }
            this._updateLinks(selectedMesh, scene);
        });
        this.gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragEndObservable.add(() => {
            this._hideRays();
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.xGizmo.attachedMesh as Mesh;
            this._removeSnapPlane(selectedMesh.name, scene);
            this.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
            /* 
            TODO:
            this._updateRotationForSingleLinks();
            this._renewGraphs(); */
        });


        // Z-axis Drag behaviour:
        this.gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragStartObservable.add(() => {
            this._addVisibleRays(scene);
            /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
            this._savePositionForUndo(selectedMesh.name);
            if (this.bControlDown) {
                this._createNewPointMesh();
            } */
        });
        this.gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragObservable.add((e) => {
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
            if (this.currentRayCastMesh) {
                this.currentDragPlaneVector = e.dragPlanePoint;
                this._calcAngles(selectedMesh, scene);
            }
            else {
                this._removeSnapPlane(selectedMesh.name, scene);
            }
            this._updateLinks(selectedMesh, scene);
        });
        this.gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragEndObservable.add(() => {
            this._hideRays();
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
            this._removeSnapPlane(selectedMesh.name, scene);
            this.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
            // TODO:

            /* this._removeSnapPlane(selectedMesh.name);            
            this._updateRotationForSingleLinks();
            window.setTimeout(() => {
                this._renewGraphs();
            }, 250); */
        });


        // Y-axis Drag behaviour:
        this.gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragStartObservable.add(() => {
            this._addVisibleRays(scene);
            /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo.yGizmo.attachedMesh as Mesh;
            this.dataVault.setPointPosition(selectedMesh.name, selectedMesh.position);
            this._savePositionForUndo(selectedMesh.name);
            if (this.bControlDown) {                
                this._createNewPointMesh();
            } */
        });
        this.gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragObservable.add(() => {
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.yGizmo.attachedMesh as Mesh;
            if (this.currentRayCastMesh) {
                selectedMesh.position.y = this.currentRayCastMesh.position.y;
                // calculate angled position
            }
            this._updateLinks(selectedMesh, scene);
        });
        this.gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragEndObservable.add(() => {
            this._hideRays();
            const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.yGizmo.attachedMesh as Mesh;
            this.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });

            /*  this._updateRotationForSingleLinks();
             window.setTimeout(() => {
                 this._renewGraphs();
             }, 250); */
        });
    }

    public _addVisibleRays(scene: Scene) {
        this.scene = scene;
        const length = 70;

        if (this.lastGizmoMesh) {
            const matrixN = Matrix.RotationAxis(Axis.Y, this.lastGizmoMesh.rotation.y);
            const v2N = Vector3.TransformCoordinates(this.localMeshDirectionNorth, matrixN);
            let rayNorth = new Ray(this.lastGizmoMesh.position, v2N, length);

            const matrixS = Matrix.RotationAxis(Axis.Y, this.lastGizmoMesh.rotation.y);
            const v2S = Vector3.TransformCoordinates(this.localMeshDirectionSouth, matrixS);
            let raySouth = new Ray(this.lastGizmoMesh.position, v2S, length);

            const matrixE = Matrix.RotationAxis(Axis.Y, this.lastGizmoMesh.rotation.y);
            const v2E = Vector3.TransformCoordinates(this.localMeshDirectionEast, matrixE);
            let rayEast = new Ray(this.lastGizmoMesh.position, v2E, length);

            const matrixW = Matrix.RotationAxis(Axis.Y, this.lastGizmoMesh.rotation.y);
            const v2W = Vector3.TransformCoordinates(this.localMeshDirectionWest, matrixW);
            let rayWest = new Ray(this.lastGizmoMesh.position, v2W, length);


            if (!this.rRayHelperNorth) {
                // mach nix                               
            }
            else {
                this.rRayHelperNorth.dispose();
                this.rRayHelperSouth.dispose();
                this.rRayHelperEast.dispose();
                this.rRayHelperWest.dispose();
            }

            this.rRayHelperNorth = RayHelper.CreateAndShow(rayNorth, scene, new Color3(1, 0, 0));
            this.rRayHelperSouth = RayHelper.CreateAndShow(raySouth, scene, new Color3(1, 0, 0));
            this.rRayHelperEast = RayHelper.CreateAndShow(rayEast, scene, new Color3(0, 0, 1));
            this.rRayHelperWest = RayHelper.CreateAndShow(rayWest, scene, new Color3(0, 0, 1));

        }




    }

    public _hideRays() {
        this.rRayHelperNorth.dispose();
        this.rRayHelperSouth.dispose();
        this.rRayHelperEast.dispose();
        this.rRayHelperWest.dispose();
    }

    public _calcAngles(selectedMesh: Mesh, scene: Scene) {
        this.scene = scene;
        /* const testPlane: Mesh = this.scene.getMeshByName("snapPlane_" + selectedMesh.name) as Mesh; */
        this._removeSnapPlane(selectedMesh.name, scene);
        const tempB: IPositionX3dType = this.oRouteHelper.getPointPositionForId(selectedMesh.name);
        const posB: Vector3 = new Vector3(tempB.x, tempB.y, tempB.z);
        /* const posB: Vector3 = selectedMesh.position; */
        const posA: Vector3 = this.currentRayCastMesh?.position as Vector3;
        const posCtemp: Vector3 = selectedMesh.position;

        /* const newVec: Vector3 = new Vector3(posCtemp.x, posCtemp.y, posCtemp.z);
        const newOrigin: Vector3 = new Vector3(posB.x, posB.y, posB.z); */
        const newPosA: Vector3 = new Vector3(posA.x, posA.y, posA.z);
        const newPosB: Vector3 = new Vector3(posB.x, posB.y, posB.z);
        /* const newNormal: Vector3 = newPosA.subtract(newPosB); */

        let plane: Mesh;
        /* if(testPlane === null){ */
        const box: Mesh = MeshBuilder.CreateBox("tempBox", { size: 0.1 }, scene);
        box.position = posB;
        box.lookAt(selectedMesh.position);
        const neededRotation: Vector3 = box.rotation;

        plane = MeshBuilder.CreatePlane("plane", { height: 200, width: 200, sideOrientation: Mesh.DOUBLESIDE }, scene);
        plane.material = this.testPlaneMat;
        plane.isPickable = false;
        plane.name = ("snapPlane_" + selectedMesh.name);
        plane.position = posA;
        plane.rotation = neededRotation;
        box.dispose();

        plane.computeWorldMatrix(true);
        const plane_worldMatrix = plane.getWorldMatrix();
        const plane_vertexData = plane.getVerticesData("normal");
        let planeNormal = new Vector3(0, 0, 0);
        if (plane_vertexData !== null) {
            planeNormal = new Vector3(plane_vertexData[0], plane_vertexData[1], plane_vertexData[2]);
        }
        let planeNormal2 = Vector3.TransformNormal(planeNormal, plane_worldMatrix)
        const reflector: Plane = Plane.FromPositionAndNormal(plane.position, planeNormal2.scale(-1));
        const hitPoint2: Vector3 = posB.projectOnPlane(reflector, posCtemp);
        selectedMesh.position.x = hitPoint2.x;
        selectedMesh.position.z = hitPoint2.z;

    }

    public _removeSnapPlane(str: string, scene: Scene) {
        this.scene = scene;
        const testPlane: Mesh = scene.getMeshByName("snapPlane_" + str) as Mesh;
        if (testPlane) {
            testPlane.dispose();
        }
    }


    public _updateLinks(box: Mesh, scene: Scene) {
        this.scene = scene;
        /* console.log("_updateLinks, name: ", box.name);
        console.log("_updateLinks, box: ", box); */
        /* this.posX.value = "" + box.position.x.toFixed(4);
        this.posY.value = "" + box.position.y.toFixed(4);
        this.posZ.value = "" + box.position.z.toFixed(4); */



        if (Array.isArray(box.metadata.links)) {
            // First take care of link that goes from box to neighbour:
            box.metadata.links.forEach((link: string) => {
                // Create connection from box to link: 
                try {
                    (scene.getMeshByName((box.name + "-" + link)) as Mesh).dispose();
                } catch (error) {
                    // nothing found
                }
                /* if (this.logOutput === true) {
                    console.log("working on link " + box.name + " - " + link._attributes.point);
                } */
                this.buildLinkFromPoint({ id: box.name, links: box.metadata.links, position: { x: box.position.x, y: box.position.y, z: box.position.z } }, link)

                // Create connection from link to box: 
                try {
                    (scene.getMeshByName((link + "-" + box.name)) as Mesh).dispose();
                } catch (error) {
                    // nothing found
                }
                const box2: Mesh = (scene.getMeshByName(link) as Mesh);
                this.buildLinkFromPoint({ id: box2.name, links: box2.metadata.links, position: { x: box2.position.x, y: box2.position.y, z: box2.position.z } }, box.name, { x: box.position.x, y: box.position.y, z: box.position.z })
            });

        }
        else {
            // no links found, should never happen
        }
    }

    public _isValidPointId(sPointId: string): boolean {
        if (/^L[0-9]{2}P[0-9]{4}$/.test(sPointId)) {
            return true;
        } else {
            return false;
        }
    }

    public _isMeshNameALink(meshName: string) {
        if (/(L[0-9]{2}P[0-9]{4}_L[0-9]{2}P[0-9]{4})/.test(meshName)) {
            return true;
        } else {
            return false;
        }
    }

    public setupRayCaster(scene: Scene, camera: ArcRotateCamera) {
        this.scene = scene;
        scene.onPointerDown = () => {
            if (this.raycastHitForLink) { // used for creating new link between points
                const rayDown = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera);
                const hitDown = scene.pickWithRay(rayDown);
                if (hitDown?.pickedMesh && this._isValidPointId(hitDown.pickedMesh.name)) {
                    if (this.lastGizmoMesh) {
                        console.log("establish link between " + this.lastGizmoMesh.name + " and " + hitDown.pickedMesh.name);
                        this._createNewLink(this.lastGizmoMesh.name, hitDown.pickedMesh.name);
                    }
                }
                else {
                    const addDiv: HTMLDivElement = window.document.getElementById("linkadd") as HTMLDivElement;
                    const addSpan: HTMLSpanElement = addDiv.children[0] as HTMLSpanElement;
                    const addImg: HTMLImageElement = addDiv.children[1] as HTMLImageElement;
                    addSpan.innerHTML = "Add Link";
                    addImg.removeAttribute("style");
                }
                this.raycastHitForLink = false;

                window.setTimeout(() => {
                    this.gizmoManager.usePointerToAttachGizmos = true;
                }, 100);

            }
            else {
                const rayDown2 = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera);
                const hitDown2 = scene.pickWithRay(rayDown2);
                if (hitDown2?.pickedMesh) {
                    const meshName: string = hitDown2.pickedMesh.name;
                    if (this._isMeshNameALink(meshName) && this.bShiftDown && this.userMode === "net") { //used for link editing with shift pressed
                        console.log("hitDown.pickedMesh name check for shift down: " + meshName);
                        // TODO Link edit
                        /* this.editModal.style.display = "block";
                        this._createLinkEditPage(meshName.substr(0, 8), meshName.substr(9)); */
                    }
                }

            }
        }



        scene.onPointerMove = (event) => {
            /* console.log("onPointerMove event: ", event); */
            const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera);

            const hit = scene.pickWithRay(ray);

            if (hit?.pickedMesh && this._isValidPointId(hit.pickedMesh.name) && this.lastRayCastName !== hit.pickedMesh.name) {
                this.rayOverlayClean = false;
                this.lastRayCastName = hit.pickedMesh.name;
                this._addOverlay(hit.pickedMesh as Mesh);
                this.currentRayCastMesh = hit.pickedMesh as Mesh;
            }
            else {
                if (hit?.pickedMesh) {
                    if (this.lastRayCastName !== hit.pickedMesh.name && !this.rayOverlayClean) {
                        this._addOverlay(null);
                        this.lastRayCastName = "";
                        this.rayOverlayClean = true;
                        this.currentRayCastMesh = null;
                    }
                }
                else {
                    /* console.log("onPointerMove C"); */
                    this._addOverlay(null);
                    this.lastRayCastName = "";
                    this.rayOverlayClean = true;
                    this.currentRayCastMesh = null;
                }

            }
        }

    }

    public focusOnPoint(id: string) {
        const camera = this.scene.activeCamera as ArcRotateCamera;
        const e: InstancedMesh = this.scene.getMeshByName(id) as InstancedMesh;
        Animation.CreateAndStartAnimation("camtarget", camera, "target", 30, 15, camera.target, (new Vector3(e.position.x, e.position.y, e.position.z)), Animation.ANIMATIONLOOPMODE_RELATIVE);
    }

    private _createNewLink(box1Name: string, box2Name: string) {
        let box1: InstancedMesh = this.scene.getMeshByName(box1Name) as InstancedMesh;
        let box2: InstancedMesh = this.scene.getMeshByName(box2Name) as InstancedMesh;


        // #1 add link to box1:
        box1.metadata.links.push(box2Name);

        // #2 add link to box2:
        box2.metadata.links.push(box1Name);

        const box1MetadataLinks: string[] = [...box1.metadata.links] as string[];
        const box2MetadataLinks: string[] = [...box2.metadata.links] as string[];


        const newPointObjectBox1: PointObject = {
            id: box1Name,
            links: box1MetadataLinks,
            position: { x: box1.position.x, y: box1.position.y, z: box1.position.z }
        }

        const newPointObjectBox2: PointObject = {
            id: box2Name,
            links: box2MetadataLinks,
            position: { x: box2.position.x, y: box2.position.y, z: box2.position.z }
        }

        box1.dispose();
        box2.dispose();

        this.buildPoint(newPointObjectBox1, false);
        this.buildPoint(newPointObjectBox2, false);

        this.oRouteHelper.addLink(box1Name + "-" + box2Name);
        this.oRouteHelper.addLink(box2Name + "-" + box1Name);

        console.log("this.oRouteHelper: ", this.oRouteHelper);

        const tempMesh: Mesh = this.scene.getMeshByName(box1Name) as Mesh;
        this._updateLinks(tempMesh, this.scene);
        this.gizmoManager.attachToMesh(tempMesh);
        this._showSelectedPoint(tempMesh);
        this.displayPointData(tempMesh);
    }

    public _showSelectedPoint(e: Mesh | null) {
        console.log("_showSelectedPoint CALLED");
        try {
            this.guiTexture.dispose();
        } catch (error) {
            // mach nix
        }

        this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.guiTexture.idealWidth = 600;

        if (e !== null) {
            var rect1 = new Rectangle();
            rect1.width = 0.1;
            rect1.height = "15px";
            rect1.cornerRadius = 10;
            rect1.color = "#666666";
            rect1.thickness = 2;
            rect1.background = "rgba(201,211,15, 1)";
            this.guiTexture.addControl(rect1);
            rect1.linkWithMesh(e);
            rect1.linkOffsetY = -40;

            var label = new TextBlock();
            label.text = e.name;
            rect1.addControl(label);

            var target = new Ellipse();
            target.width = "5px";
            target.height = "5px";
            target.color = "#666666";
            target.thickness = 1;
            target.background = "rgba(201,211,15, 1)";
            this.guiTexture.addControl(target);
            target.linkWithMesh(e);

            var line = new Line();
            line.lineWidth = 2;
            line.color = "#666666";
            line.y2 = 7.5;
            line.linkOffsetY = -2.5;
            this.guiTexture.addControl(line);
            line.linkWithMesh(e);
            line.connectedControl = rect1;
        }
    }

    public _copyIDtoclipboard(id: string) {
        let temp = document.createElement('textarea');
        temp.value = id;
        document.body.appendChild(temp);
        temp.select();
        document.execCommand('copy');
        document.body.removeChild(temp);
    }

    private _addOverlay(e: Mesh | null) {
        /* console.log("_addOverlay CALLED!"); */
        try {
            this.guiRayTexture.dispose();
        } catch (error) {
            // mach nix
        }
        this.guiRayTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.guiRayTexture.idealWidth = 600;
        if (e !== null) {
            var rect1 = new Rectangle();
            /* rect1.width = 0.06; */
            rect1.height = "14px";
            rect1.adaptWidthToChildren = true;
            rect1.cornerRadius = 5;
            rect1.color = "#666666";
            rect1.thickness = 1;
            rect1.background = "white";
            this.guiRayTexture.addControl(rect1);
            rect1.linkWithMesh(e);
            rect1.linkOffsetY = -10;

            var label = new TextBlock();
            label.text = e.name;
            label.paddingLeft = 2;
            label.paddingRight = 2;
            label.textHorizontalAlignment = 0;
            label.resizeToFit = true;
            rect1.addControl(label);

        }

    }

}