import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button"; 
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import LayersIcon from '@mui/icons-material/Layers';
import AddIcon from '@mui/icons-material/Add';
import { RouteHelper } from "../../../classes/RouteHelper";
import { GeomBuilder } from "../../../classes/GeomBuilder";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import closedIcon from '../../../res/svg/closed.svg';
import escalatorDownIcon from '../../../res/svg/escalator-down.svg';
import escalatorUpIcon from '../../../res/svg/escalator-up.svg';
import notEscalatorDownIcon from '../../../res/svg/not-escalator-down.svg';
import notEscalatorUpIcon from '../../../res/svg/not-escalator-up.svg';
import liftDownIcon from '../../../res/svg/lift-down.svg';
import liftUpIcon from '../../../res/svg/lift-up.svg';
import rampDownIcon from '../../../res/svg/ramp-down.svg';
import rampUpIcon from '../../../res/svg/ramp-up.svg';
import otherIcon from '../../../res/svg/other.svg';
import disabledIcon from '../../../res/svg/disabled.svg';
import stairDownIcon from '../../../res/svg/stair-down.svg';
import stairUpIcon from '../../../res/svg/stair-up.svg';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Icon from "@mui/material/Icon";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { ISnackProvider } from "../../../interfaces/commonInterfaces";

const CssTextField = styled(TextField)({
    width: '4.7em',
    marginRight: '0.5em',
    marginTop: '0',
    marginBottom: '12px',
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

interface IRouteEditPoint {
    pointId: string,
    pointMesh: Mesh,
    linksList: string[],
    rh: RouteHelper,
    gb: GeomBuilder,
    setPointSelected: React.Dispatch<React.SetStateAction<boolean>>,
    setLayerYtoPoint: () => void,
    setSelectedLink: React.Dispatch<React.SetStateAction<string>>,
    detachGizmo: () => void,
    setSnackState: React.Dispatch<React.SetStateAction<ISnackProvider>>
}

let pointToDelete: string = "";

export default function RouteEditPoint(props: React.PropsWithChildren<IRouteEditPoint>) {
    const { t } = useTranslation();
    const [openDeletePointWarning, setOpenDeletePointWarning] = useState<boolean>(false);


    const getPointColor = (id: string): string => {
        let colorString: string = "";
        try {
            colorString = props.rh.getPointTypeColor(id);
        } catch (error) {
            // mach nix
        }
        return colorString;
    }

    const getLinkType = (id: string): string => {
        let typeString: string = "";
        try {
            typeString = props.rh.getLinkType(id);
        } catch (error) {
            // mach nix
        }
        return typeString;
    }

    const focusAndEditLink = (index: number) => {
        props.gb.focusOnMesh(props.linksList[index]);
        props.gb.deHighlightMesh(props.linksList[index]);
        props.setSelectedLink(props.linksList[index]);
        props.setPointSelected(false);
        props.detachGizmo();
    }

    const renderRowLinks = (propsRow: ListChildComponentProps) => {
        const { index, style } = propsRow;

        return (
            <ListItem style={style} key={index} component="div" disablePadding sx={{ backgroundColor: '#092935', height: '41px !important', width: '97% !important', justifyContent: 'center !important' }} onPointerLeave={() => props.gb.deHighlightMesh(props.linksList[index])}>
                <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}
                    onPointerOver={() => props.gb.highlightMesh(props.linksList[index])}
                    onClick={() => focusAndEditLink(index)}>
                    <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(props.linksList[index].substring(0, 8)), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                    <ListItemText primary={props.linksList[index].substring(0, 8)} sx={{ textAlign: 'center' }} />
                    <ListItemIcon sx={{ textAlign: 'center', justifyContent: 'center' }} >
                        {(() => {
                            switch (getLinkType(props.linksList[index])) {
                                case "default":
                                    return <SwapHorizIcon />
                                case "other":
                                    return <Icon>
                                        <img alt='Icon' src={otherIcon} height={24} width={24} />
                                    </Icon>
                                case "stair-up":
                                    return <Icon>
                                        <img alt='Icon' src={stairUpIcon} height={24} width={24} />
                                    </Icon>
                                case "stair-down":
                                    return <Icon>
                                        <img alt='Icon' src={stairDownIcon} height={24} width={24} />
                                    </Icon>
                                case "escalator-up":
                                    return <Icon>
                                        <img alt='Icon' src={escalatorUpIcon} height={24} width={24} />
                                    </Icon>
                                case "not-escalator-up":
                                    return <Icon>
                                        <img alt='Icon' src={notEscalatorUpIcon} height={24} width={24} />
                                    </Icon>
                                case "not-escalator-down":
                                    return <Icon>
                                        <img alt='Icon' src={notEscalatorDownIcon} height={24} width={24} />
                                    </Icon>
                                case "escalator-down":
                                    return <Icon>
                                        <img alt='Icon' src={escalatorDownIcon} height={24} width={24} />
                                    </Icon>
                                case "lift-up":
                                    return <Icon>
                                        <img alt='Icon' src={liftUpIcon} height={24} width={24} />
                                    </Icon>
                                case "lift-down":
                                    return <Icon>
                                        <img alt='Icon' src={liftDownIcon} height={24} width={24} />
                                    </Icon>
                                case "ramp-up":
                                    return <Icon>
                                        <img alt='Icon' src={rampUpIcon} height={24} width={24} />
                                    </Icon>
                                case "ramp-down":
                                    return <Icon>
                                        <img alt='Icon' src={rampDownIcon} height={24} width={24} />
                                    </Icon>
                                case "disabled":
                                    return <Icon>
                                        <img alt='Icon' src={disabledIcon} height={24} width={24} />
                                    </Icon>
                                case "closed":
                                    return <Icon>
                                        <img alt='Icon' src={closedIcon} height={24} width={24} />
                                    </Icon>
                                default:
                                    return null
                            }
                        })()}
                    </ListItemIcon>
                    <ListItemText primary={props.linksList[index].substring(9)} sx={{ textAlign: 'center' }} />
                    <Box sx={{ width: '0.7vw', height: '0.7vw', backgroundColor: getPointColor(props.linksList[index].substring(9)), borderColor: '#000000', borderWidth: '2px', borderStyle: 'solid', marginRight: '0.6em' }}></Box>
                </ListItemButton>
            </ListItem>
        );
    }

    const handleCloseEditpoint = () => {
        props.setPointSelected(false);
        props.detachGizmo();
    }



    const initLinkAdd = () => {
        props.gb.initLinkAdd();
    }

    const highlightLink = (id: string) => {
        props.gb.highlightMesh(id);
    }

    const deHighlightLink = (id: string) => {
        props.gb.deHighlightMesh(id);
    }

    const handleLinkDeleteDialog = (pointId: string) => {
        pointToDelete = pointId;
        setOpenDeletePointWarning(true);
    }


    const handleDeletePoint = (pointId: string) => {
        const allLinksOfPoint: string[] = props.rh.getLinksForPoint(pointId);
        let clearForDeletion: boolean = false;
        if (allLinksOfPoint.length === 1) {
            // single link, no routecheck needed. Deletion without issues:
            clearForDeletion = true;
            /* console.log("netValid: ", clearForDeletion); */
            props.rh.deletePoint(pointId); 
            props.gb.deleteMesh(pointId + "-" + allLinksOfPoint[0]);
            props.gb.deleteMesh(pointId);
            props.gb.deleteMesh(allLinksOfPoint[0] + "-" + pointId);
            props.gb.deleteLinkFromMetadata(pointId + "-" + allLinksOfPoint[0]);
            props.gb.deleteLinkFromMetadata(allLinksOfPoint[0] + "-" + pointId);

            props.setSnackState({
                open: true,
                message: t('tools.routes.point_delete_success'),
                vertical: "bottom",
                horizontal: 'center',
                alert: 'success'
            })
            handleCloseEditpoint();
        }
        else {
            // multiple links, tests needed for net-integrity:           

            // Kill links for testing:
            allLinksOfPoint.forEach(linkPoint => {
                props.rh.deleteLink(pointId + "-" + linkPoint);
                props.rh.deleteLink(linkPoint + "-" + pointId);
            });

            // RouteCheck:
            let netValid: boolean = true;
            allLinksOfPoint.forEach(fromPoint => {
                allLinksOfPoint.forEach(toPoint => {
                    if (fromPoint !== toPoint) {
                        const routeCheck: string[] = props.rh.checkPath(fromPoint, toPoint) as string[];
                        if (routeCheck.length === 1) {
                            netValid = false;
                        }
                    }
                });
            });

            /* console.log("netValid: ", netValid); */

            if (netValid === true) {
                allLinksOfPoint.forEach(linkPoint => {
                    /* props.rh.addLink(pointId + "-" + linkPoint);
                    props.rh.addLink(linkPoint + "-" + pointId); */
                    props.gb.deleteMesh(pointId + "-" + linkPoint);
                    props.gb.deleteMesh(linkPoint + "-" + pointId);
                    props.gb.deleteLinkFromMetadata(pointId + "-" + linkPoint);
                    props.gb.deleteLinkFromMetadata(linkPoint + "-" + pointId);
                });

                props.rh.deletePoint(pointId); 
                props.gb.deleteMesh(pointId);

                props.setSnackState({
                    open: true,
                    message: t('tools.routes.point_delete_success'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'success'
                });

                handleCloseEditpoint();
            }
            else {
                allLinksOfPoint.forEach(linkPoint => {
                    props.rh.addLink(pointId + "-" + linkPoint);
                    props.rh.addLink(linkPoint + "-" + pointId);
                });
                props.setSnackState({
                    open: true,
                    message: t('tools.routes.point_delete_fail'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'warning'
                })
                
            }            
        }
        handleClose();
        /* props.rh.deletePoint(pointId); */
    }

    const handleLinkDelete = (link: string) => {
        const fromId: string = link.substring(0, 8);
        const toId: string = link.substring(9);
        props.rh.deleteLink(link);
        props.rh.deleteLink(toId + "-" + fromId);

        const routeCheck: string[] = props.rh.checkPath(fromId, toId) as string[];

        /* console.log("routeCheck: ", routeCheck); */

        if (routeCheck !== undefined) {
            if (routeCheck.length > 1) {
                // all good valid net

                props.gb.deleteMesh(link);
                props.gb.deleteMesh(toId + "-" + fromId);
                props.gb.deleteLinkFromMetadata(link);
                /* props.attachGizmo();
                linkToDelete = "";
                handleCloseEditLink(); */
                props.setSnackState({
                    open: true,
                    message: t('tools.routes.link_delete_success'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'success'
                })
            }
            else {
                // net broken with unreachable points! Rollback...
                props.rh.addLink(link);
                props.rh.addLink(toId + "-" + fromId);
                props.setSnackState({
                    open: true,
                    message: t('tools.routes.link_delete_fail'),
                    vertical: "bottom",
                    horizontal: 'center',
                    alert: 'warning'
                })
                handleClose();
            }
        }


    }

    const handleClose = () => {
        setOpenDeletePointWarning(false);
    };

    const CssWhiteButton = styled(Button)({
        '& .MuiButton-root:hover': {
            color: '#ffffff',
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20vw', alignItems: 'flex-start', pl: '1em' }}>
            <Dialog
                open={openDeletePointWarning}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        backgroundColor: 'info.main',
                        backgroundImage: 'unset',
                        /* top: '-11%',
                        left: '-20%',*/
                        width: '50%'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    backgroundColor: 'info.main',
                    color: 'success.main',
                }}>
                    {t('tools.routes.point_delete_dialog_heading')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{
                        color: 'text.primary'
                    }}>
                        {t('tools.routes.point_delete_dialog_msg')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    backgroundColor: 'info.main'
                }}>
                    {/* <Button onClick={handleClose}>{t('tools.cancel')}</Button>
                    <Button onClick={() => handleLinkDelete(linkToDelete)} autoFocus>
                        {t('tools.save')}
                    </Button> */}
                    <CssWhiteButton
                        onClick={() => handleDeletePoint(props.pointId)}
                        /* type="submit" */
                        /* fullWidth */
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CheckCircleIcon sx={{
                            marginRight: '6px'
                        }}></CheckCircleIcon>
                        {t('tools.save')}
                    </CssWhiteButton>
                    <CssWhiteButton
                        onClick={handleClose}
                        /* type="submit" */
                        /* fullWidth */
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CancelIcon sx={{
                            marginRight: '6px'
                        }}></CancelIcon>
                        {t('tools.cancel')}
                    </CssWhiteButton>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '19vw' }}>
                <IconButton aria-label="delete" sx={{ width: '1.5em', height: '1.5em', padding: '1em' }} onClick={handleCloseEditpoint}>
                    <ClearIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-1.0em' }}>
                <Typography gutterBottom align='left' color='secondary' sx={{
                    fontWeight: '700', fontSize: '2.1rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                }}>{props.pointId}</Typography>
                <Button variant="contained" onClick={() => handleLinkDeleteDialog(props.pointId)} onPointerOver={() => highlightLink(props.pointId)} onPointerOut={() => deHighlightLink(props.pointId)} startIcon={<DeleteIcon />} sx={{ ml: '2em', pt: '0.0vh'/* , pl: '1vh' */, pb: '0vh'/* , pr: '1vh' */, height: '2em' }}>
                    {t('tools.routes.delete')}
                </Button>
            </Box>
            <FormControlLabel
                value="start"
                control={<Checkbox color="success" />}
                label={t('tools.routes.startpoint').toUpperCase()}
                labelPlacement="start"
                sx={{ ml: '0' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <CssTextField
                    margin="normal"
                    id="x_edit"
                    label={'x'}
                    name="x_position"
                    autoComplete=""
                    variant="standard"
                    /* onChange={handleChangeFirstName} */
                    value={props.pointMesh.position.x}
                />
                <CssTextField
                    margin="normal"
                    id="y_edit"
                    label={'y'}
                    name="y_position"
                    autoComplete=""
                    variant="standard"
                    /* onChange={handleChangeFirstName} */
                    value={props.pointMesh.position.y}
                />
                <CssTextField
                    margin="normal"
                    id="z_edit"
                    label={'z'}
                    name="z_position"
                    autoComplete=""
                    variant="standard"
                    /* onChange={handleChangeFirstName} */
                    value={props.pointMesh.position.z}
                />
                <IconButton aria-label="delete" sx={{ width: '1.5em', height: '1.5em', padding: '1em' }} onClick={() => props.gb.focusOnPoint(props.pointId)}>
                    <CenterFocusStrongIcon />
                </IconButton>
                <IconButton aria-label="delete" sx={{ width: '1.5em', height: '1.5em', padding: '1em' }} onClick={props.setLayerYtoPoint}>
                    <LayersIcon />
                </IconButton>
                <CssTextField
                    margin="normal"
                    id="rot_edit"
                    label={'rotation'}
                    name="rotation_value"
                    autoComplete=""
                    variant="standard"
                    /* onChange={handleChangeFirstName} */
                    value={props.pointMesh.rotation.x}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography gutterBottom align='left' sx={{
                    fontWeight: '700', fontSize: '1.8rem', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0.3em'
                }}>{t('tools.routes.links')}</Typography>
                <IconButton aria-label="delete" sx={{ width: '1.5em', height: '1.5em', padding: '0em', ml: '0.5em', backgroundColor: '#092935' }} onClick={initLinkAdd}>
                    <AddIcon />
                </IconButton>
            </Box>
            <FixedSizeList
                height={250}
                width={'100%'}
                itemSize={46}
                itemCount={props.linksList.length}
                overscanCount={10}
                className="virtcontent"
            >
                {renderRowLinks}
            </FixedSizeList>
        </Box>
    )
}