import { iAreasData } from '../../../interfaces/area_interfaces';
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import DraggableBox from './areas_dragable_box';
import { SortableContext } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import zIndex from '@mui/material/styles/zIndex';
import { DroppableContainersMap } from '@dnd-kit/core/dist/store/constructors';


const accordionStyleSx = {
  width: '100%',
  backgroundColor: '#0D3B4D',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
};
const accordionSummarySx = {
  backgroundColor: '#0D3B4D',
  padding: '0',
  height: '24px',
  '.MuiAccordionSummary-content': {
    margin: 0,
  },
}
const accordionDetailsSx = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#0D3B4D',
  boxShadow: 'none',
};
const accordionListStyle = {
  width: '100%',
  backgroundColor: '#092935',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 15px',
  marginBottom: '5px',
  borderRadius: '4px',
};
const accordionType = {
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
};
const listFontSx = { fontWeight: '700', fontSize: '1rem' };


export default function DropLayerAccordion(props: { Areas: iAreasData[], layer: string }) {

  const { setNodeRef } = useDroppable({
    id: props.layer,
  });

  return (

    <div ref={setNodeRef}>
      <Accordion disableGutters sx={accordionStyleSx}>
        <AccordionSummary sx={accordionSummarySx}
          expandIcon={<ExpandMoreIcon />}

        >
          <Typography sx={accordionType}>{props.layer} ({props.Areas.length})</Typography>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsSx}>

          {/* <SortableContext items={props.Areas.map(area => area.id)}> */}
            {props.Areas.map(area => <DraggableBox key={area.uID} id={area.uID} name={area.name} layer={area.layer}></DraggableBox>)}
          {/* </SortableContext> */}

        </AccordionDetails>
      </Accordion>
      </div>
  )
}