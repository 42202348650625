import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { ChangeEvent } from "react";
import { ApiProject, langObject } from "../../../interfaces/commonInterfaces";
import { MapData } from "../../../interfaces/interfaceGuiController";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { GeomBuilder } from "../../../classes/GeomBuilder";

interface IRouteLayerStepperProps {
    /* title: string; */
    mapData: MapData;
    apiProject: ApiProject;
    selectedLayer: string;
    setselectedLayer: React.Dispatch<React.SetStateAction<string>>;
    layerNodes: TransformNode[];
    gb: GeomBuilder;
    setSelectedNetIds: React.Dispatch<React.SetStateAction<string[]>>;
    selectedNetIds: string[];
    /* url: string;
    hexBackgroundColor: string;
    closeIframe: Dispatch<SetStateAction<boolean>>; */
}


export default function RouteLayerStepper(props: React.PropsWithChildren<IRouteLayerStepperProps>) {
    /* const [selectedNetIds, setSelectedNetIds] = useState<string[]>([]); */
    const handlePreviewLayerChange = (layerId: string) => {
        props.setselectedLayer(layerId);
    }

    React.useEffect(() => {
        /*         console.log("WTF???"); */
        if (props.gb !== undefined) {
            /* console.log("selectedNetIds: ", props.selectedNetIds); */
            props.gb.updateNetVis(props.selectedNetIds, props.layerNodes);
        }

    }, [props.selectedNetIds]);

    const getLayerShortName = (index: number): string => {
        let layerShortName: string = "";
        let germanIndexPosition: number = -1;
        let englishIndexPosition: number = -1;
        let selectedLanguageIndex: number = -1;
        if (props.mapData.layerNames !== undefined) {
            const langObjectList: langObject[] = props.apiProject.languages;
            const shortNameList: string[] = props.mapData.layerNames[index].shortName;
            langObjectList.forEach((element, indexPos) => {
                if (element.code === "de") {
                    germanIndexPosition = indexPos;
                }
                if (element.code === "en") {
                    englishIndexPosition = indexPos;
                }
            });
            if (germanIndexPosition !== -1) {
                selectedLanguageIndex = germanIndexPosition;
            }
            if (germanIndexPosition === -1 && englishIndexPosition !== -1) {
                selectedLanguageIndex = englishIndexPosition;
            }
            if (germanIndexPosition === -1 && englishIndexPosition === -1) {
                selectedLanguageIndex = 0;
            }
            layerShortName = shortNameList[selectedLanguageIndex]
        }
        return layerShortName;
    }


    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checkedId = event.target.value;
        if (event.target.checked) {
            props.setSelectedNetIds([...props.selectedNetIds, checkedId])
        } else {
            props.setSelectedNetIds(props.selectedNetIds.filter(id => id !== checkedId))
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 10, top: '50%', left: '2%', transform: 'translateY(-50%)' }}>
            {props.mapData.layerIds?.map((item, index) => {
                return (
                    <Box key={item + "_" + index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" color={props.selectedLayer === item ? 'secondary' : 'primary'} key={"buttonFloor_" + index}
                            sx={{ mb: '0.5em', borderRadius: '100%', minWidth: '3em', width: '3.5em', height: '3.5em', padding: '0.5em', boxShadow: 3 }}
                            onClick={() => handlePreviewLayerChange(item)}>{getLayerShortName(index)}</Button>
                        <FormControlLabel
                            value="bottom"
                            control={<Checkbox /* size="small" */ sx={{
                                color: '#092935',
                                filter: "drop-shadow(0px 2px 4px #111111)",
                                '&.Mui-checked': {
                                    color: '#E1E77B',
                                    textShadow: "0px 2px 4px #111111",
                                    "&, & + .MuiFormControlLabel-label": {
                                        color: "#E1E77B",
                                        textShadow: "0px 2px 6px #000000"
                                    }
                                },
                            }}
                                value={item}
                                checked={props.selectedNetIds.includes(item)}
                                onChange={(event) => { handleCheckboxChange(event) }}
                            />}
                            label={item}
                            labelPlacement="bottom"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    color: '#092935',
                                    fontSize: '0.9em',
                                    textShadow: "0px 2px 4px #111111",
                                    transform: "translateY(-12px)"
                                },
                            }}
                        />
                    </Box>

                );
            })}
        </Box>
    );

}