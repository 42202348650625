import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

interface FilterArray {
    menuFilter: string[]
    selectedSetter?: React.Dispatch<React.SetStateAction<string[]>>
}

export default function FilterButton(props: FilterArray) {
    const filterBtnSmall = {
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
        borderRadius: '1.5em',
        marginRight: '0.6em',
        marginBottom: '0.5em',
        height: '2.45em',
        backgroundColor: '#0D3B4D', //'primary.main',
        color: 'primary.contrastText',
        width: 'fit-content',
        '&:hover': {
            color: 'primary.contrastText',
        },
        borderColor: '#ffffff !important'
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentFilters, setCurrentFilters] = React.useState<string[]>([]);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleParentSetter = (filters: string[]) => {
        if (props.selectedSetter !== undefined) {
            props.selectedSetter(filters);
        }
    }

    const handleFilterSelect = (filterName: string) => {
        let tempFilters: string[] = [...currentFilters];
        if (currentFilters?.indexOf(filterName) === -1) {
            tempFilters.push(filterName);
            tempFilters.sort();
            setCurrentFilters(tempFilters);
            handleParentSetter(tempFilters);
        }
        else {
            handleDeleteFilter(filterName);
        }
        setAnchorEl(null);
    }

    const handleDeleteFilter = (filterName: string) => {
        let tempFilters: string[] = [...currentFilters];
        tempFilters?.splice(tempFilters.indexOf(filterName), 1);
        tempFilters.sort();
        setCurrentFilters(tempFilters);
        handleParentSetter(tempFilters);
    }


    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '20vw', flexWrap: 'wrap' }}>
                <Button
                    id="basic-button"
                    size="small"
                    variant="outlined"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={filterBtnSmall}
                    startIcon={<FilterAltIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Filter
                </Button>
                {currentFilters?.map((filterName, index) =>
                    <Chip key={filterName + "_" + index}
                        label={filterName}
                        onDelete={() => handleDeleteFilter(filterName)}
                        sx={{ marginRight: '0.6em', marginBottom: '0.5em', backgroundColor: '#092935' }}
                    />
                )}
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    disablePadding: true
                }}
            >
                {props.menuFilter.map(filterName =>
                    <MenuItem
                        /* dense      */
                        key={filterName}
                        onClick={() => handleFilterSelect(filterName)}
                        sx={{ backgroundColor: '#092935' }}
                    >
                        {filterName}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
