
import Box from "@mui/material/Box";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AccessibleIcon from '@mui/icons-material/Accessible';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { RouteHelper } from "../../../classes/RouteHelper";
import { useState } from "react";
import { ModeObject } from "../../../interfaces/commonInterfaces";

interface IRouteModeDisplay {
    linkId: string,
    /* pointMesh: Mesh,
    linksList: string[], */
    rh: RouteHelper,
    /* gb: GeomBuilder,
    setLinkSelected: React.Dispatch<React.SetStateAction<string>>,
    setPointSelected: React.Dispatch<React.SetStateAction<boolean>>,
    attachGizmo: () => void,
    updateLinks: () => void */
}

export default function RouteModeDisplay(props: React.PropsWithChildren<IRouteModeDisplay>) {

    const getModesForLink = (linkId: string): ModeObject => {
        const modeObject: ModeObject = props.rh.getModesForLink(linkId);
        return modeObject;
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '0.5em', alignItems: 'center', width: '19vw' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '10vw', padding: '0.6em', marginBottom: '0.3em', backgroundColor: '#092935' }}>
                    <DirectionsWalkIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }} ></DirectionsWalkIcon>
                    {getModesForLink(props.linkId).m0000 === true ?
                        <CheckIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }}></CheckIcon>
                        :
                        <CloseIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }}></CloseIcon>
                    }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '10vw', padding: '0.6em', backgroundColor: '#092935' }}>
                    <AccessibleIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }}></AccessibleIcon>
                    {getModesForLink(props.linkId).m0001 === true ?
                        <CheckIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }}></CheckIcon>
                        :
                        <CloseIcon sx={{ color: '#FFFFFF99', fontSize: '1.7em' }}></CloseIcon>
                    }
                </Box>
            </Box>
        </Box>
    )
}