import * as React from 'react';
import { DndContext, closestCenter, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayerAccordion from './areas_layerAccordion';
import DropLayerAccordion from './areas_dropable_layerAccordion';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { iAreasData, IAreasMap } from '../../../interfaces/area_interfaces';
import { AreasDataContext } from '../tool_areas';
import { useTranslation } from 'react-i18next';
import { MapData } from '../../../interfaces/interfaceGuiController';

const accordionBox = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
}
const accordionStyleSx = {
    width: '100%',
    backgroundColor: '#0D3B4D',
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
};
const accordionSummarySx = {
    backgroundColor: '#0D3B4D',
    padding: '0',
}
const accordionDetailsSx = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#0D3B4D',
    boxShadow: 'none',
    maxHeight: '40vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '12px',
        backgroundColor: '#00000051',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ffffff82',
        borderRadius: '10px',
        transition: '.4s linear',
        '&-webkit-transition': {
            transition: '.4s linear',
        }
    },
};
const accordionType = {
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
};
const draggedBoxStyle = {
    width: '100%',
    backgroundColor: '#114F66',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    marginBottom: '5px',
    borderRadius: '4px',
    zIndex: '99',
};
const listFontSx = { fontWeight: '700', fontSize: '1rem' };
const listFontSmallSx = { fontWeight: '500', fontSize: '0.5rem' };

interface iAreasMapData {
    areaMapData: MapData,
}

export default function MatchAccordions(props: iAreasMapData ) {
    const { t } = useTranslation();
    const {areasData, setAreasData} = React.useContext(AreasDataContext);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [draggedArea, setDraggedArea] = React.useState<iAreasData | null>(null);


    // ANCHOR accordion functions / Drag and drop
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };



    const handleDragStart = (event: any) => {
        const { active } = event;
        const dragged = areasData.find(area => area.uID === active.id);
        setDraggedArea(dragged || null);
    };


    const handleDragEnd = (event: any) => {
        // console.log(event); //TODO delete
        const { active, over } = event;
        if (over && active.id !== over.id) {
            over.id.length === 3 ? dragEndOverAccordionSummary(active, over) : dragEndOverAccordionDetails(active, over);
        }
    };


    const dragEndOverAccordionSummary = (active: any, over: any) => {
        const newLayer = over.id;
        if (newLayer) {
            dropInLayer(active.id, newLayer);
        }
    }


    const dragEndOverAccordionDetails = (active: any, over: any) => {
        const newLayer = areasData.find(area => area.uID === over.id)?.layer;
        const oldLayer = areasData.find(area => area.uID === active.id)?.layer;
        if (oldLayer && newLayer) {
            dropInLayer(active.id, newLayer)
        }
    }


    const dropInLayer = (activeId: string, newLayer: string) => {
        setAreasData(prev =>
            prev.map(area => (area.uID === activeId ? { ...area, layer: newLayer } : area))
        );
    };

    return (
        <Box sx={accordionBox}>


            <Accordion disableGutters sx={accordionStyleSx} expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                <AccordionSummary sx={accordionSummarySx}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography sx={accordionType}>{t('tools.areas.assigned')} ({areasData.filter(hl => hl.match === true).length})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsSx}>
                    {props.areaMapData?.layerIds !== undefined ?
                        props.areaMapData.layerIds.map(layer => (
                            <LayerAccordion key={layer} Areas={areasData.filter(hl => hl.match === true && hl.layer === layer)} layer={layer} />
                        )) : null}

                </AccordionDetails>
            </Accordion>

            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >

                <Accordion disableGutters sx={accordionStyleSx} expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary sx={accordionSummarySx}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography sx={accordionType}>{t('tools.areas.notAssigned')} ({areasData.filter(hl => hl.match === false).length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionDetailsSx}>

                        {props.areaMapData?.layerIds !== undefined ?
                            props.areaMapData.layerIds.map(layer => (
                                <DropLayerAccordion key={layer + '_DnD_true'} Areas={areasData.filter(hl => hl.match === false && hl.layer === layer)} layer={layer} />
                            )) : null}

                    </AccordionDetails>
                </Accordion>
                <DragOverlay>
                    {draggedArea ? (
                        <Box sx={draggedBoxStyle}>
                            <Typography sx={listFontSx}>{draggedArea.name}</Typography>
                        </Box>
                    ) : null}
                </DragOverlay>
            </DndContext>
        </Box>
    )
}