import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MapsUploadElement from './maps_upload_element';
import Slider from '@mui/material/Slider';
import RasterOverlay from "../../../raster.png";
import React, { Dispatch, SetStateAction } from 'react';
import { setCurrentGuiController_mapsAssistant, setCurrentGuiController_mapsManualSetup, setCurrentGuiController_mapsAssistant_layerImages_index, setCurrentGuiController_editedLayerNames, setCurrentGuiController_valueTabs, setCurrentGuiController_mapsStep, setCurrentGuiController_editedLayerIds, setCurrentGuiController_mapsAssistant_uploads, setCurrentGuiController_mapsFiles } from '../../slices/guiControllerSlice';
import LocatorMap from '../../smallComponents/locatorMap';
import ReferenceMap from '../../smallComponents/referenceMap';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LayerNames, MapData, UploadedFile, UploadedFiles, UploadedImage } from '../../../interfaces/interfaceGuiController';
import { useGetProjectQuery, useEditMapMutation, useGetMapQuery } from '../../slices/apiSlice';
import { ApiProject, langObject } from '../../../interfaces/commonInterfaces';
/* import { Project } from '../../../interfaces/interfaceProject'; */
import { setCurrentMapDataController, setCurrentMapDataController_layerIds, setCurrentMapDataController_layerNames, setCurrentMapDataController_rotation } from '../../slices/MapDataSlice';


const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

const CssTextField = styled(TextField)({
    /* width: '30%', */
    margin: 'unset',
    marginLeft: '2vh',
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

interface IAssistantProps {
    apiProject: ApiProject;
    mapData: MapData;
    mapFiles: UploadedFiles;
    setMainMapData: Dispatch<SetStateAction<MapData | undefined>>;
    setMainMapFiles: Dispatch<SetStateAction<UploadedFiles | undefined>>;
    requeryMap: () => Promise<void>;
}


export default function Maps_assistant(props: React.PropsWithChildren<IAssistantProps>) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    /* const apiProject = props.apiProject; */
    const [assistantNumber, setAssistantNumber] = React.useState(1);
    const [layerNumber, setLayerNumber] = React.useState(1);
    const [rasterView, setRasterView] = React.useState(0);
    const [layerArray, setLayerArray] = React.useState([0]);
    const [inputErrors, setInputErrors] = React.useState([999]);
    const [languagesShort, setLanguagesShort] = React.useState([""]);
    const [languagesLong, setLanguagesLong] = React.useState([""]);
    // const [editedLayerNames, seteditedLayerNames] = React.useState([""]);
    /* const [rastermapimage, setrastermapimage] = React.useState(""); */
    /* const [editedLayerNamesTest, seteditedLayerNamesTest] = React.useState([""]); */
    // const [languageNamesTest, setlanguageNamesTest] = React.useState([""]);
    /* const mapData = useAppSelector((state) => state.mapDataController.value); */
    const layerNames = useAppSelector((state) => state.mapDataController.value.layerNames);
    const mapData = props.mapData;

    console.log("props.mapData: ", props.mapData);
    const mapsAssistant_uploads = useAppSelector((state) => state.guicontroller.value.mapsAssistant_uploads);
    /* const mapsAssistant_rotation = useAppSelector((state) => state.guicontroller.value.mapsAssistant_rotation); */
    const mapsAssistant_layerImages = useAppSelector((state) => state.guicontroller.value.mapsAssistant_layers);
    const editedLayerNames = useAppSelector((state) => state.guicontroller.value.editedLayerNames);
    const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
    const mapRotation = useAppSelector((state) => state.mapDataController.value.rotation);
    const mapsPixelA = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_A);
    const mapsPixelB = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_B);
    /*  const [refMapKey, setRefMapKey] = React.useState(0); */
    /* const loadedImages: UploadedImages = useAppSelector((state) => state.guicontroller.value.mapsAssistant_layers); */
    const editedLayerIds = useAppSelector((state) => state.guicontroller.value.editedLayerIds);
    const [surroundingView, setSurroundingView] = React.useState(false);
    /* const { data, error, isLoading } = useGetProjectQuery(currentProject);
    const { data: dataMap, error: erroMap, isLoading: isLoadingMap } = useGetMapQuery(props.apiProject.mapUuid); */
    const [selectedLayer, setselectedLayer] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editMap] = useEditMapMutation();
    const open = Boolean(anchorEl);

    /*  const [rotationValue, setRotationValue] = React.useState<number | string | Array<number | string>>(
         0,
     ); */

    const marks = [
        {
            value: -360,
            label: '-360°',
        },
        {
            value: -270,
            label: '-270°',
        },
        {
            value: -180,
            label: '-180°',
        },
        {
            value: -90,
            label: '-90°',
        },
        {
            value: 0,
            label: '0°',
        },
        {
            value: 90,
            label: '90°',
        },
        {
            value: 180,
            label: '180°',
        },
        {
            value: 270,
            label: '270°',
        },
        {
            value: 360,
            label: '360°',
        },
    ];

    const nullCheck = (str: string | null): boolean => {
        let bNullValue = false;
        if (str === null) {
            bNullValue = true;
        }
        if (str === undefined) {
            bNullValue = true;
        }
        if (str === "") {
            bNullValue = true;
        }
        return bNullValue;
    }

    /* React.useEffect(() => {
        if (mapsAssistant_uploads > 0 && mapsAssistant_uploads >= layerNumber) {
            console.log("mapsAssistant_uploads: ", mapsAssistant_uploads);
            console.log("layerNumber: ", layerNumber);
        }
        else {
            console.log("mapsAssistant_uploads: ", mapsAssistant_uploads);
            console.log("layerNumber: ", layerNumber);
        }
    }, [layerNumber, mapsAssistant_uploads]) */

    /* let layerNames: string[] = []; */
    /* let inputErrors: number[] = []; */

    /* React.useEffect(() => {
        setRefMapKey(refMapKey + 1);
    }, [mapsPixelA, mapsPixelB]) */

    const saveChangesToMap = () => {
        console.log("mapData altered: ", mapData);
        const mapDataJSON = JSON.stringify(mapData);
        callEditMapMutation(mapDataJSON);
    }

    const saveUpdatesToMap = (data: MapData) => {
        console.log("mapData altered update: ", mapData);
        const mapDataJSON = JSON.stringify(data);
        callEditMapMutation(mapDataJSON);
    }

    const callEditMapMutation = async (jsonString: string) => {
        const data: Object = { mapUuid: props.apiProject.mapUuid, bodydata: { "mapData": jsonString } }
        await editMap(data)
            .then((response: any) => {
                console.log("updated server mapData: ", response);
                props.setMainMapData(response.data.data.mapData);
                if (response.data.data.files !== null) {
                    let uf: UploadedFiles = { files: [] };
                    response.data.data.mapData.layerIds.forEach((id: string) => {
                        let fileData: UploadedFile = response.data.data.files[id] as UploadedFile;
                        uf.files.push(fileData);
                    });
                    // console.log("setMainMapFiles: ", uf);
                    props.setMainMapFiles(uf);
                }
            }
            );
    }

    const constructLayerIds = () => {
        const tmpLayerArray: number[] = [...layerArray];
        const layerIds: string[] = [];
        tmpLayerArray.reverse();
        tmpLayerArray.forEach(element => {
            const layerId: string = getLayerIdbyIndex(element);
            layerIds.push(layerId);
        });
        if (surroundingView === true) {
            if (layerIds.indexOf("L00") === -1) {
                layerIds.push("L00");
                let newArr = [...editedLayerNames];
                if (newArr.length < layerIds.length) {
                    newArr.push("Umgebung");
                    dispatch(setCurrentGuiController_editedLayerNames(newArr));
                }
            }
        }
        if (surroundingView === false) {
            let newArr = [...editedLayerNames];
            if (newArr.indexOf("Umgebung") >= 0) {
                const index = newArr.indexOf("Umgebung");
                newArr.splice(index, 1);
                dispatch(setCurrentGuiController_editedLayerNames(newArr));
            }
        }
        dispatch(setCurrentGuiController_editedLayerIds(layerIds));
        dispatch(setCurrentMapDataController_layerIds(layerIds));
        constructLayerNamesObjects(layerIds);
        /* console.log("layerIds: ", layerIds); */
    }

    const constructLayerNamesObjects = (layerIdsArray: string[]) => {
        let layerNamesObjects: LayerNames[] = [];
        console.log("constructLayerNamesObjects, editedLayerNames: ", editedLayerNames);
        layerIdsArray.forEach((layer: string, index: number) => {
            let layerObject: LayerNames = {
                layerId: layer,
                longName: [],
                shortName: []
            };
            if (layer === "L00") {
                props.apiProject.languages.forEach((language: langObject) => {
                    layerObject.shortName.push("Out");
                    layerObject.longName.push("");
                });
            }
            else {
                props.apiProject.languages.forEach((language: langObject) => {
                    layerObject.shortName.push(editedLayerNames[index]);
                    layerObject.longName.push("");
                });
            }

            layerNamesObjects.push(layerObject);
        });
        let mapUpdateData: MapData = structuredClone(mapData);
        mapUpdateData.layerIds = layerIdsArray;
        mapUpdateData.layerNames = layerNamesObjects;
        saveUpdatesToMap(mapUpdateData);
        dispatch(setCurrentMapDataController_layerNames(layerNamesObjects));
    }

    const getLayerIdbyIndex = (index: number): string => {
        let id: string = "";
        index = index + 10;
        if (index <= 9) {
            id = "L0" + index;
        }
        else {
            id = "L" + index;
        }
        return id;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (param: string) => {
        setAnchorEl(null);
    };

    const handleImageSelect = (name: string, index: number) => {
        setAnchorEl(null);
        console.log(name + " selected");
        /* setrastermapimage(imgsrc); */
        setselectedLayer(name);
        dispatch(setCurrentGuiController_mapsAssistant_layerImages_index(index));
        /* setMenuTitleProj(id + " - " + name);
        dispatch(setCurrentGuiController_currentProject(id)); */
    }



    const handleAssistantNext = () => {
        console.log("handleAssistantNext called");
        console.log("mapData: ", mapData);
        switch (assistantNumber) {
            case 1:
                setAssistantNumber(assistantNumber + 1);
                setEditedNamesArray();
                break;
            case 2:
                if (validateInputsFloors()) {
                    setAssistantNumber(assistantNumber + 1);
                    console.log("handleAssistantNext / layerNames: ", layerNames);
                    console.log("handleAssistantNext / editedLayerNames: ", editedLayerNames);
                    /* let mapUpdateData: MapData = structuredClone(mapData);
            mapUpdateData.layerNames = layerNames;
            saveUpdatesToMap(mapUpdateData); */
                    /* seteditedLayerNames(layerNames); */
                    constructLayerIds();
                }
                break;
            case 3:
                if (validateInputsUploads()) {
                    // seteditedLayerNamesTest(["L00", "L01", "L02", "L03", "L04", "L05", "L06", "L07", "L08", "L09", "L10", "L11", "L12", "L13"]);
                    // setlanguageNamesTest(["de", "en", "es"]) /* , "fr", "nl", "it", "pl"]) */
                    const langShorts: string[] = [];
                    const langLongs: string[] = [];
                    props.apiProject.languages.forEach(element => {
                        langShorts.push(element.code);
                        langLongs.push(element.name);
                    });
                    setLanguagesShort(langShorts);
                    setLanguagesLong(langLongs);
                    setAssistantNumber(assistantNumber + 1);
                }
                break;
            case 4:
                setAssistantNumber(assistantNumber + 1);
                let mapUpdateData: MapData = structuredClone(mapData);
                mapUpdateData.layerNames = layerNames;
                saveUpdatesToMap(mapUpdateData);
                break;
            case 5:
                /* setrastermapimage(mapsAssistant_layerImages.images[0].imagesrc); */
                setselectedLayer(mapsAssistant_layerImages.images[0].name);
                setAssistantNumber(assistantNumber + 1);
                break;
            case 6:
                /* setrastermapimage(mapsAssistant_layerImages.images[0].imagesrc);
                setselectedLayer(mapsAssistant_layerImages.images[0].name); */
                setAssistantNumber(assistantNumber + 1);
                setRasterView(0);
                break;
            case 7:
                /* setrastermapimage(mapsAssistant_layerImages.images[0].imagesrc);
                setselectedLayer(mapsAssistant_layerImages.images[0].name); */
                let mapUpdateData2: MapData = structuredClone(mapData);
                /* mapUpdateData2.layerNames = layerNames; */
                saveUpdatesToMap(mapUpdateData2);
                props.setMainMapData(mapData);
                setAssistantNumber(assistantNumber + 1);
                break;
            case 8:
                /* dispatch(setCurrentGuiController_valueTabs(4));
                dispatch(setCurrentGuiController_mapsStep(8)); */

                console.log("mapData Assistant 8: ", mapData);

                dispatch(setCurrentGuiController_mapsStep(6));
                dispatch(setCurrentGuiController_valueTabs(0));
                dispatch(setCurrentGuiController_mapsAssistant(false));
                dispatch(setCurrentGuiController_mapsManualSetup(true));

                break;

            default:
                break;
        }
    }

    const handleChangeFloornameShort = (event: React.ChangeEvent<HTMLInputElement>, entryIndex: number, langIndex: number) => {
        if (layerNames !== undefined) {
            let newArr = [...layerNames as LayerNames[]];
            let ln1: LayerNames = newArr[entryIndex];
            let newShort: string[] = [];
            ln1.shortName.forEach((element, index) => {
                if (index !== langIndex) {
                    newShort.push(element)
                }
                else {
                    newShort.push(event.target.value);
                }
            });
            const ln = layerNames.map((entry, index) => {
                if (index !== entryIndex) {
                    return entry;
                }
                else {
                    return {
                        ...entry,
                        shortName: newShort,
                    }
                }
            });
            dispatch(setCurrentMapDataController_layerNames(ln));
        }
    };

    const handleChangeFloornameLong = (event: React.ChangeEvent<HTMLInputElement>, entryIndex: number, langIndex: number) => {
        if (layerNames !== undefined) {
            let newArr = [...layerNames as LayerNames[]];
            let ln1: LayerNames = newArr[entryIndex];
            let newLong: string[] = [];
            ln1.longName.forEach((element, index) => {
                if (index !== langIndex) {
                    newLong.push(element)
                }
                else {
                    newLong.push(event.target.value);
                }
            });
            const ln = layerNames.map((entry, index) => {
                if (index !== entryIndex) {
                    return entry;
                }
                else {
                    return {
                        ...entry,
                        longName: newLong,
                    }
                }
            });
            dispatch(setCurrentMapDataController_layerNames(ln));
        }


        // dispatch(setCurrentGuiController_editedLayerNames(newArr));
        // seteditedLayerNames(newArr);
    };

    const validateInputsUploads = (): boolean => {
        let bSuccess: boolean = false
        console.log("mapsAssistant_uploads: ", mapsAssistant_uploads);
        console.log("layerNumber: ", layerNumber);
        if (mapsAssistant_uploads >= layerNumber) {
            bSuccess = true
        }
        return bSuccess;
    }

    const validateInputsFloors = (): boolean => {
        let bSuccess: boolean = false
        let layerSuccessCount: number = 0;
        let errorArray: number[] = [];
        setInputErrors([]);
        let namesArray: string[] = [];
        for (let index = 0; index < layerArray.length; index++) {
            const layerString: string = editedLayerNames[index];

            console.log("layerString: " + layerString);
            if (layerString && layerString.length > 0) {
                layerSuccessCount++;
                namesArray.push(layerString);
            }
            else {
                errorArray.push(index);
                namesArray.push("");
            }
        }
        dispatch(setCurrentGuiController_editedLayerNames(namesArray));
        // seteditedLayerNames(namesArray);
        setInputErrors(errorArray);
        if (layerSuccessCount === layerArray.length) {
            bSuccess = true;
        }
        return bSuccess
    }

    const indexhasErrors = (index: number): boolean | undefined => {
        if (inputErrors.indexOf(index) >= 0) {
            return true;
        }
        return undefined;
    }

    const handleChangeFloorname = (event: React.ChangeEvent<HTMLInputElement>, item: number) => {
        let newArr = [...editedLayerNames];
        if (newArr.length !== layerArray.length) {
            for (let index = 0; index < layerArray.length; index++) {
                newArr[index] = "";
            }
        }
        newArr[item] = event.target.value;
        dispatch(setCurrentGuiController_editedLayerNames(newArr));
        // seteditedLayerNames(newArr);
    };

    const setEditedNamesArray = () => {
        let newArr = [...editedLayerNames];
        if (newArr.length !== layerArray.length) {
            for (let index = 0; index < layerArray.length; index++) {
                newArr[index] = "";
            }
        }
        dispatch(setCurrentGuiController_editedLayerNames(newArr));
        // seteditedLayerNames(newArr);
    };

    const handleAssistantQuit = () => {
        console.log("handleAssistantQuit called");
        dispatch(setCurrentGuiController_mapsAssistant(false));
        dispatch(setCurrentGuiController_mapsManualSetup(false));
    }

    const handleAssistantBack = () => {
        console.log("handleAssistantBack called");
        if (assistantNumber === 1) {
            dispatch(setCurrentGuiController_mapsAssistant(false));
            dispatch(setCurrentGuiController_mapsManualSetup(false));
        }
        else {
            setAssistantNumber(assistantNumber - 1);
            console.log("handleAssistantBack: assistantNumber = " + assistantNumber);
            setTimeout(() => {
                if (assistantNumber <= 6) {
                    setRasterView(0);
                }
            }, 100);

        }
    }

    const handleChangeFloors = (value: string) => {
        const floorNum: number = parseInt(value);
        let arrayNums: number[] = [];
        setLayerNumber(floorNum);
        for (let index = 0; index < floorNum; index++) {
            arrayNums.push(index);
        }
        setLayerArray(arrayNums);
    }

    const handleAssistantDeleteFloor = (item: number) => {
        console.log("handleAssistantDeleteFloor called");
        const backupArray: number[] = [];
        for (let index = 0; index < layerArray.length; index++) {
            backupArray[index] = layerArray[index];
        }
        const foundIndex = layerArray.indexOf(item);
        /*         console.log("item: ", item);
                console.log("backupArray: ", backupArray);
                console.log("index: ", foundIndex); */
        if (foundIndex > -1) { // only splice array when item is found
            backupArray.splice(foundIndex, 1); // 2nd parameter means remove one item only
            layerArray.splice(foundIndex, 1);
            /* layerNames.splice(foundIndex, 1);
            dispatch(setCurrentGuiController_editedLayerNames(layerNames)); */
            // seteditedLayerNames(layerNames);
            setLayerArray(backupArray);
            console.log("layerArray: ", layerArray);
            console.log("editedLayerNames: ", editedLayerNames);
        }
    }



    const handleRotationSliderChange = (event: Event, newValue: number | number[]) => {
        /* setRotationValue(newValue); */
        dispatch(setCurrentMapDataController_rotation(Number(newValue))
        )
    };

    const handleRotationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setRotationValue(event.target.value === '' ? '' : Number(event.target.value));
        dispatch(setCurrentMapDataController_rotation(Number(event.target.value)));
    };

    const handleBlur = () => {
        if (Number(mapRotation) < -360) {
            /* setRotationValue(0); */
            dispatch(setCurrentMapDataController_rotation(-360))
        } else if (Number(mapRotation) > 360) {
            dispatch(setCurrentMapDataController_rotation(360))
        }
    };


    /* const getImageSource = () => {
        let srcString = "";
        srcString = mapsAssistant_layerImages.images[0].imagesrc;
        return srcString;
    }
 */
    const handleRasterChange = () => {
        if (rasterView === 0) {
            setRasterView(1);
        }
        else {
            setRasterView(0);
        }
    }

    const handleSurroundingViewChange = () => {
        if (surroundingView === false) {
            setSurroundingView(true);
        }
        else {
            setSurroundingView(false);
        }
    }

    return (
        <Box sx={{ pl: '2vh', position: 'relative', maxWidth: '108rem', minHeight: '10rem', width: 'fit-content' }}>
            <Paper elevation={6} sx={{ padding: '1.4em', minHeight: '10rem', minWidth: '75vh', backgroundImage: 'unset', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ArrowBackIcon sx={{
                        fontSize: '1.8rem', mt: '0.5vh'
                    }} cursor='pointer' onClick={handleAssistantBack}></ArrowBackIcon>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography gutterBottom align="left" component="div" sx={{
                            pl: '2vh', fontSize: '28px'
                        }}>
                            {t('tools.maps.setup_assistant')}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                            pl: '2vh'
                        }}>
                            {t('tools.maps.floorsetup_' + assistantNumber) + " (" + (assistantNumber) + "/8)"}
                        </Typography>
                    </Box>
                    <CloseIcon cursor='pointer' sx={{ fontSize: '1.8rem', position: 'absolute', top: '2vh', right: '2vh' }} onClick={handleAssistantQuit}></CloseIcon>
                </Box>
                {assistantNumber === 1
                    ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '2.5vh' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {t('tools.maps.howmanyfloors')}
                            </Typography>
                            <InfoIcon sx={{ ml: '1vh', mt: '0.4vh', fontSize: '20px' }}></InfoIcon>
                        </Box>
                        <TextField color='success' type='number' value={layerNumber}
                            InputProps={{ inputProps: { min: 1, max: 20 } }}
                            sx={{ pl: '2vh', mb: '4vh', width: '10vh' }}
                            onChange={(e) => {
                                handleChangeFloors(e.target.value);
                            }}>1</TextField>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {t('tools.maps.surrounding')}
                            </Typography>
                            <InfoIcon sx={{ ml: '1vh', mt: '0.4vh', fontSize: '20px' }}></InfoIcon>
                        </Box>
                        <Switch color='success' sx={{
                            ml: '1vh'
                        }}
                            checked={surroundingView}
                            onChange={handleSurroundingViewChange}
                        />
                    </Box>
                    : null
                }
                {assistantNumber === 2
                    ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '2.5vh', maxHeight: '40rem', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {t('tools.maps.setLayerShorts')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                mt: '2vh', minWidth: '75vh', maxHeight: '40vh', overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'white',
                                    outline: '1px solid white'
                                }
                            }}>
                            <Box sx={{ display: 'flex', width: '35vh', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                    pl: '2vh',
                                }}>
                                    {t('tools.maps.floorname')}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                    pl: '2vh',
                                }}>
                                    {t('tools.maps.visible')}
                                </Typography>
                            </Box>
                            {layerArray.map((item, index) => {
                                return (
                                    <Box key={"floorListVlue_" + item} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {index === 0 && index !== (layerArray.length - 1)
                                            ?
                                            <CssTextField
                                                error={indexhasErrors(item)}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="floor_edit"
                                                label={t('tools.maps.topfloor')}
                                                name="firstName"
                                                autoComplete=""
                                                variant="standard"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                value={editedLayerNames[item] === undefined ? "" : editedLayerNames[item]}
                                            /* value={firstNameValue} */
                                            />
                                            : null
                                        }
                                        {index !== 0 && index !== (layerArray.length - 1)
                                            ?
                                            <CssTextField
                                                /* {...indexhasErrors(item) && ({error})} */
                                                error={indexhasErrors(item)}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="floor_edit"
                                                label={t('tools.maps.floor')}
                                                name="firstName"
                                                autoComplete=""
                                                variant="standard"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                value={editedLayerNames[item]}
                                            /* value={firstNameValue} */
                                            />
                                            : null
                                        }
                                        {index === (layerArray.length - 1)
                                            ?
                                            <CssTextField
                                                error={indexhasErrors(item)}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="floor_edit"
                                                label={t('tools.maps.lowfloor')}
                                                name="firstName"
                                                autoComplete=""
                                                variant="standard"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                value={editedLayerNames[item]}
                                            /* value={firstNameValue} */
                                            />
                                            : null
                                        }
                                        <Switch color='success' sx={{
                                            ml: '6vh',
                                            mt: '2vh'
                                        }} defaultChecked />
                                        <DeleteIcon cursor='pointer' sx={{
                                            ml: '7vh',
                                            mt: '2vh'
                                        }} onClick={() => { handleAssistantDeleteFloor(item) }}></DeleteIcon>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    : null
                }
                {assistantNumber === 3
                    ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '2.5vh', maxHeight: '40rem', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {t('tools.maps.uploadmaps')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                mt: '2vh', minWidth: '75vh', maxHeight: '40vh', overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'white',
                                    outline: '1px solid white'
                                }
                            }}>
                            {mapData.layerNames?.map((layername, index) => {
                                return (
                                    <Box key={"floorMapUploader_" + index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5vh', justifyContent: 'center' }}>
                                        <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                            pl: '2vh', width: '20vh'
                                        }}>
                                            {layername.shortName[0]}
                                        </Typography>
                                        <MapsUploadElement uploadProp={{ layernameString: layername.shortName[0], layerId: editedLayerIds[index], layerNamesArray: editedLayerNames, mapUuid: props.apiProject.mapUuid as string, requeryMap: props.requeryMap, mapFiles: props.mapFiles }}></MapsUploadElement>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    : null
                }
                {assistantNumber === 4
                    ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '2.5vh', maxHeight: '35rem', maxWidth: '108rem', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '108rem', mb: '2.5vh' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {t('tools.maps.floorsetup_4_heading')}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', pl: '0vh', maxHeight: '30rem', maxWidth: '103rem', overflowY: 'auto', overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                                height: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 0.4em rgba(255,255,255,0.64)',
                                webkitBoxShadow: 'inset 0 0 0.4em rgba(255,255,255,0.64)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'white',
                                /* outline: '1px solid white' */
                            }
                        }}>
                            <Box sx={{ zIndex: '50', transform: 'translateY(-0.8vh) translateX(7vh)', position: 'sticky', top: 0, width: 0 }}>
                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                    pl: '2vh', fontWeight: 'bold', width: '10em'
                                }}>
                                    {t('tools.maps.floorsetup_4_short')}
                                </Typography>
                            </Box>
                            <Box sx={{ position: 'absolute', backgroundColor: '#092935', width: '7vh', height: '6vh', zIndex: '100' }}>
                            </Box>
                            <Box sx={{ position: 'sticky', left: 0, backgroundColor: '#092935', zIndex: 20, height: '100%' }}>
                                {editedLayerNames.map((item, index) => {
                                    return (
                                        <Box key={item + "_" + index}>
                                            {index === 0 ?
                                                <Box sx={{ minWidth: '7vh', height: '4vh', mb: '1vh', mt: '5vh' }}>
                                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{
                                                        fontWeight: 'bold', pt: '0.5vh', mb: '0'
                                                    }}>
                                                        {item}
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box sx={{ minWidth: '7vh', height: '4vh', mb: '1vh' }}>
                                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{
                                                        fontWeight: 'bold', pt: '0.5vh', mb: '0'
                                                    }}>
                                                        {item}
                                                    </Typography>
                                                </Box>
                                            }
                                        </Box>
                                    );
                                })
                                }
                            </Box>
                            {languagesShort.map((itemLang, indexLang) => {
                                return (
                                    <Box key={itemLang + "_" + indexLang} sx={{ ml: '1vh', minWidth: '7vh', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ minWidth: '7.05vh', height: '4vh', mb: '1vh', position: 'sticky', top: 0, backgroundColor: '#092935', zIndex: 30 }}>
                                            <Typography gutterBottom variant="subtitle2" component="div" align="left" sx={{
                                                fontWeight: 'bold', pt: '0.5vh', pl: '1vh', mb: '0', opacity: 0.6, transform: 'translateY(1.2vh)'
                                            }}>
                                                {itemLang.toLocaleUpperCase()}
                                            </Typography>
                                        </Box>
                                        {editedLayerNames.map((item, index) => {
                                            return (
                                                <TextField key={item + "_names_" + indexLang} id="filled-basic" hiddenLabel defaultValue={item} /* value={item} */ size="small" variant="filled" sx={{ mb: '1vh', minWidth: '7vh', maxWidth: '14vh', maxHeight: '4vh' }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloornameShort(event, index, indexLang) }}></TextField>
                                            );
                                        })
                                        }
                                    </Box>
                                );
                            })}
                            <Box sx={{ minWidth: '7vh', height: '100%', backgroundColor: 'red' }}>
                            </Box>
                            <Box sx={{ zIndex: '50', transform: 'translateY(-0.8vh) translateX(-1vh)', position: 'sticky', top: 0, width: 0 }}>
                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                    pl: '2vh', fontWeight: 'bold', width: '10em'
                                }}>
                                    {t('tools.maps.floorsetup_4_long')}
                                </Typography>
                            </Box>
                            {languagesLong.map((languageString, indexLang) => {
                                return (
                                    <Box key={languageString + "_languageString_" + indexLang} sx={{ mr: '1vh', minWidth: '19vh', maxWidth: '19vh' }}>
                                        <Box sx={{ minWidth: '19vh', maxWidth: '19vh', height: '4vh', mb: '1vh', position: 'sticky', top: 0, backgroundColor: '#092935', zIndex: 10 }}>
                                            <Typography gutterBottom variant="subtitle2" component="div" align="left" sx={{
                                                fontWeight: 'bold', pt: '0.5vh', pl: '1vh', mb: '0', opacity: 0.6, transform: 'translateY(1.2vh)'
                                            }}>
                                                {languageString.toLocaleUpperCase()}
                                            </Typography>
                                        </Box>
                                        {editedLayerNames.map((item, index) => {
                                            return (
                                                <TextField key={item + "_layerNames_" + index} id="filled-basic" hiddenLabel defaultValue={item} /* value={item} */ size="small" variant="filled" sx={{ mb: '1vh', minWidth: '19vh', maxHeight: '4vh' }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloornameLong(event, index, indexLang) }}></TextField>
                                            );
                                        })
                                        }
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    : null
                }
                {assistantNumber >= 5 && assistantNumber < 9
                    ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '0.5vh', maxHeight: '45rem', maxWidth: '108rem', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '108rem', mb: '0.5vh' }}>
                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                pl: '2vh', opacity: '0.6'
                            }}>
                                {assistantNumber === 5 ? t('tools.maps.floorsetup_5_heading') : null}
                                {assistantNumber === 6 ? t('tools.maps.floorsetup_6_heading') : null}
                                {assistantNumber === 7 ? t('tools.maps.floorsetup_7_heading') : null}
                                {assistantNumber === 8 ? t('tools.maps.floorsetup_8_heading') : null}
                            </Typography>
                        </Box>
                        <Box sx={{ width: 'auto', display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', top: '1vh', ml: '2vh' }}>
                                {assistantNumber === 6
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', top: '1vh', ml: '2vh' }}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', ml: '0vh', mb: '0.5vh' }}>
                                            <Slider sx={{ color: '#24a3d3', ml: '2vh', mr: '4vh', width: '40vh' }} step={1} onChange={handleRotationSliderChange} marks={marks} value={typeof mapRotation === 'number' ? mapRotation : 0} min={-360} max={360} aria-label="Default" valueLabelDisplay="auto" />
                                            <Input
                                                value={mapRotation}
                                                size="small"
                                                onChange={handleRotationInputChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    min: -360,
                                                    max: 360,
                                                    type: 'number',
                                                    'aria-labelledby': 'input-slider',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    : null
                                }
                                {assistantNumber >= 6
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <FormControlLabel
                                            value="top"
                                            control={<Switch
                                                color='success'
                                                onChange={handleRasterChange}
                                            />}
                                            label="Raster"
                                            labelPlacement="start"
                                        />
                                        {assistantNumber >= 6
                                            ?
                                            <Box>
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                    sx={{
                                                        marginLeft: '0',
                                                        color: 'primary.contrastText',
                                                        /* backgroundColor: 'info.main', */
                                                        textTransform: 'unset !important',
                                                        fontSize: '1em',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    {/* {userStatus.firstName} */}
                                                    {selectedLayer}
                                                    {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                        sx: {
                                                            backgroundColor: 'info.main',
                                                            paddingBottom: '0px !important'
                                                        }
                                                    }}
                                                >
                                                    {
                                                        mapsAssistant_layerImages.images.map((element: UploadedImage, index: number) => {
                                                            /* console.log("WTFdfgsdf", element); */
                                                            return <div key={element.name}>
                                                                <Divider sx={{
                                                                    borderColor: 'primary.contrastText',
                                                                    marginTop: '0px !important',
                                                                    marginBottom: '0px !important',
                                                                }}></Divider>
                                                                <MenuItem onClick={() => handleImageSelect(element.name, index)}>{element.name}</MenuItem>
                                                            </div>

                                                        })
                                                    }
                                                </Menu>
                                            </Box>
                                            :
                                            null
                                        }
                                    </Box>
                                    : null
                                }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <LocatorMap locatorProp={{ assistNumber: assistantNumber, mapObject: mapData }}></LocatorMap>
                                {rasterView === 1
                                    ?
                                    <img src={RasterOverlay} alt='previewRaster' height={"500px"} width={'auto'} style={{ position: 'absolute', pointerEvents: 'none', marginLeft: '2vh' }}></img>
                                    : null
                                }
                                {assistantNumber >= 6
                                    ?
                                    <Box sx={{ ml: "1em" }}>
                                        {/* <img src={rastermapimage} alt='preview' height={"500px"} width={'auto'}></img> */}
                                        <ReferenceMap referenceProp={{ assistNumber: assistantNumber, mapObject: mapData, mapFiles: props.mapFiles }}></ReferenceMap>
                                    </Box>
                                    : null
                                }
                            </Box>
                        </Box>
                    </Box>
                    :
                    null
                }
                <CssWhiteButton
                    type="submit"
                    /* fullWidth */
                    variant="contained"
                    onClick={handleAssistantNext}
                    sx={{
                        /* position: 'absolute',
                        bottom: '4vh',
                        left: '7vh', */
                        mt: '0.5vh',
                        ml: '2vh',
                        backgroundColor: 'primary.contrastText',
                        color: 'primary.main',
                        width: 'fit-content',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}
                >
                    {assistantNumber < 8 ? t('tools.maps.proceed') : t('tools.maps.finished')}
                    {assistantNumber < 8
                        ?
                        <NavigateNextIcon sx={{
                            marginLeft: '6px'
                        }}></NavigateNextIcon>
                        :
                        <CheckIcon sx={{
                            marginLeft: '6px'
                        }}></CheckIcon>
                    }

                </CssWhiteButton>
            </Paper>
        </Box>
    );
}