import { iAreasData } from '../../../interfaces/area_interfaces';
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';


const accordionStyleSx = {
  width: '100%',
  backgroundColor: '#0D3B4D',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },        
};
const accordionSummarySx = {
  backgroundColor: '#0D3B4D',
  padding: '0',
  height: '24px',
  '.MuiAccordionSummary-content': {
      margin: 0, 
    },
}
const accordionDetailsSx = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#0D3B4D',
  boxShadow: 'none',
};
const accordionListStyle = {
  width: '100%',
  backgroundColor: '#092935',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 15px',
  marginBottom: '5px',
  borderRadius: '4px',
};
const accordionType = {
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
};
const listFontSx = { fontWeight: '700', fontSize: '1rem' };


export default function LayerAccordion(props: {Areas:iAreasData[], layer:string}) {
    

    return (
        <Accordion disableGutters sx={accordionStyleSx}>
        <AccordionSummary sx={accordionSummarySx}
          expandIcon={<ExpandMoreIcon />}

        >
          <Typography sx={accordionType}>{props.layer} ({props.Areas.length})</Typography>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsSx}>
          {props.Areas.map(area => <Box key={area.uID} sx={accordionListStyle}>
            <Typography align='left' sx={listFontSx} >{area.name}</Typography>
            
          </Box>)}

        </AccordionDetails>
      </Accordion>
    )
}