import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import { useTranslation } from 'react-i18next';
import "@babylonjs/core/Materials/standardMaterial";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Scene } from "@babylonjs/core/scene";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import SceneComponent from './projectComponents/route_babylon_basic';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import { RouteHelper } from "../../classes/RouteHelper";
import { useState } from 'react';
import { GeomBuilder } from '../../classes/GeomBuilder';
import { ISnackProvider, LayersBabylonDataObject, PointObject } from '../../interfaces/commonInterfaces';
import { ApiProject } from '../../interfaces/commonInterfaces';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { useLazyGetMapQuery, useLazyGetProjectQuery } from '../slices/apiSlice';
import React from 'react';
import { LayerFile, MapData } from '../../interfaces/interfaceGuiController';
import RouteLayerStepper from './projectComponents/route_LayerStepper';
import RouteNetAccordion from './projectComponents/route_net_accordion';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import Route_LoadingScreen from './projectComponents/route_loadingScreen';
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Animation } from "@babylonjs/core/Animations/animation";
import "@babylonjs/core/Animations/animatable";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { ICamValues } from '../../interfaces/babylonInterfaces';
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { SmartArray } from '@babylonjs/core/Misc/smartArray';
import FilterButton from '../smallComponents/FilterButton';
import RouteEditPoint from './projectComponents/route_editPoint';
import RouteEditLink from './projectComponents/route_editLink';
import RouteDisplaySettings from './projectComponents/route_dispSettings';
import SnackProvider from '../smallComponents/SnackProvider';


/* import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector"; */

const CssTextField = styled(TextField)({
  /* width: '30%', */
  marginTop: "1em",
  backgroundColor: '#114F66',
  '& .MuiInputBase-root.MuiFilledInput-root:before': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiInputBase-root.MuiFilledInput-root:after': {
    borderBottomColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
    borderBottomColor: '#ffffff',
  },
  '& .MuiInput-underline:hover': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiInputBase-input': {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  '& .MuiInputAdornment-root': {
    paddingBottom: '16px'
  }
});



let currentMapUuid: string = "";
let camera: ArcRotateCamera;
let oRouteHelper: RouteHelper;
let _oGeomBuilder: GeomBuilder;
let gizmoManager: GizmoManager;
let pointerStatus: string = "";
let radiusStored: number = 0;
let timeoutWheel: number;
let timeoutUp: number;
let currentFilters: string[] = [];
let currentScene: Scene;
let lastGizmoMesh: Mesh;
let _layerMaterials!: Map<string, StandardMaterial>;
let _masterPlane: Mesh;



export default function Tool_routes() {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  const [valueTabs, setValueTabs] = useState(0);
  const [mainMapData, setMainMapData] = useState<MapData>();
  const [mapLayerFiles, setMapLayerFiles] = useState<{ [key: string]: LayerFile }>();
  const [getProject] = useLazyGetProjectQuery();
  const [getMapCall] = useLazyGetMapQuery();
  const [selectedLayer, setselectedLayer] = useState("");
  const [selectedNetIds, setSelectedNetIds] = useState<string[]>([]);
  const [apiProject, setApiProject] = useState<ApiProject>({
    "projectId": 0,
    "name": "",
    "shortName": "",
    "description": "",
    "comment": "",
    "city": "",
    "openingTime": "10:00:00",
    "closingTime": "20:00:00",
    "country": "DE",
    "active": true,
    "hasService": false,
    "market": "",
    "tags": [],
    "links": [],
    "products": [],
    "addons": [],
    "demoGalleryEnabled": false,
    "demoGalleryPublic": false,
    "mapUuid": "",
    "languages": [
      {
        "code": "de",
        "name": "German"
      },
    ],
    "createdAt": "2023-08-12T15:29:39.000000Z",
    "modifiedAt": "2023-08-12T15:29:39.000000Z",
    "timezone": null
  })
  const [layerArray, setLayerArray] = useState<string[]>();
  const [layerNodesArray, setLayerNodesArray] = useState<TransformNode[]>();
  const [oGeomBuilder, setGeomBuilder] = useState<GeomBuilder>();
  const [layersBabylonData, setLayersBabylonData] = useState<LayersBabylonDataObject>();
  const [netLoaded, setNetLoaded] = useState<boolean>(false);
  const [prepsForBabylon, setPrepsForBabylon] = useState<boolean>(false);
  const [userMode, setUserMode] = useState<string>("net");
  const [openDispSettings, setOpenDispSettings] = useState<boolean>(false);
  const [currentPoints, setCurrentPoints] = useState<string[]>([]);
  const [currentLinks, setCurrentLinks] = useState<string[]>([]);
  const [filterSelected, setFilterSelected] = useState<string[]>([]);
  const [pointSelected, setPointSelected] = useState<boolean>(false);
  const [selectedPointLinks, setSelectedPointLinks] = useState<string[]>([]);
  const [selectedPointId, setSelectedPointId] = useState<string>('');
  const [selectedPointMesh, setSelectedPointMesh] = useState<Mesh>();
  const [linkSelected, setLinkSelected] = useState<string>("");
  const [snackState, setSnackState] = useState<ISnackProvider>({
    open: false,
    message: "",
    vertical: "top",
    horizontal: 'center',
    alert: 'success',
  });

  let cameraValues: ICamValues = {
    alpha: 0,
    beta: 0,
    radius: 0,
    target: new Vector3(0, 0, 0)
  }

  const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_PATH_TO_API) {
      case 'production ':
        url = 'https://creator.guide3d.com';
        break;
      case 'development ':
      default:
        url = 'http://api.creator.local';
    }

    return url;
  }

  const apiUrl: string = getBaseUrl(); // http://api.creator.local | https://creator.guide3d.com

  const nullCheck = (str: string | null): boolean => {
    let bNullValue = false;
    if (str === null) {
      bNullValue = true;
    }
    if (str === undefined) {
      bNullValue = true;
    }
    if (str === "") {
      bNullValue = true;
    }
    return bNullValue;
  }

  let box: Mesh;




  const queryProject = async () => {
    await getProject(currentProject)
      .then((response: any) => {
        const apiTempProject: ApiProject = response.data.data as ApiProject;
        setApiProject(apiTempProject);
        /* console.log("apiTempProject ", apiTempProject); */
        if (nullCheck(apiTempProject.mapUuid) === true) {
          /* console.log("NO map in project " + apiTempProject.projectId); */
        }
        else {
          currentMapUuid = apiTempProject.mapUuid as string;
          queryMap();

          // load map
        }
      });
  }

  const queryMap = async () => {
    /* console.log("activeMapUuid: ", activeMapUuid); */
    /* console.log("currentMapUuid: ", currentMapUuid); */
    await getMapCall(currentMapUuid)
      .then((response: any) => {
        /* console.log("queryMap response: ", response); */
        let mapData: MapData = response.data.data.mapData as MapData;
        setMainMapData(mapData);
        let filesData: { [key: string]: LayerFile } = response.data.data.files as { [key: string]: LayerFile };
        setMapLayerFiles(filesData);
        // console.log("queryMap mapData: ", mapData);

      }
      );
  }

  React.useEffect(() => {
    console.log("######################## ToolROUTES MOUNTED currentProjectTool: ", currentProjectTool);
    if (currentProjectTool !== 1) {
      setNetLoaded(false);
      setPrepsForBabylon(false)
    }
    else {
      oRouteHelper = new RouteHelper({ debug: true });
      oRouteHelper.initRouteService(100013);
      waitForRouteHelper();
    }
  }, [currentProjectTool]);

  const waitForRouteHelper = () => {
    console.log("######################## ToolROUTES MOUNTED waitForRouteHelper...");
    window.setTimeout(() => {
      if (oRouteHelper.getInitReady() === true && mainMapData !== undefined) {
        console.log("######################## ToolROUTES MOUNTED waitForRouteHelper SUCCESS!!!");
        setPrepsForBabylon(true);
      }
      else {
        waitForRouteHelper();
      }
    }, 1000);
  }

  React.useEffect(() => {
    if (pointSelected === true) {
      setLinkSelected("");
      _oGeomBuilder.deHighlightLastTube();
    }
  }, [pointSelected])

  React.useEffect(() => {
    /* console.log("######################## FILTER SET: " + filterSelected); */
    currentFilters = [...filterSelected];
    if (currentScene) {
      if (currentFilters.indexOf(t('tools.routes.map_area')) >= 0) {
        checkInstantMeshVisibility(currentScene);
      }
      else {
        const allPointIds: string[] = oRouteHelper.getAllPoints();
        const allLinks: string[] = oRouteHelper.getAllLinks();

        setCurrentPoints(allPointIds);
        setCurrentLinks(allLinks);

      }
    }
  }, [filterSelected]);

  React.useEffect(() => {
    console.log("######################## QUERY PROJECT: " + currentProject);
    currentMapUuid = "";
    if (currentProject !== 0) {
      queryProject();
    }
  }, [currentProject]);

  React.useEffect(() => {
    try {
      // .setLayerVisibility(id: string, masterPlane: Mesh, layerPositions: Map<string, Vector3>, layerMaterials: Map<string, StandardMaterial>):
      oGeomBuilder?.setLayerVisibility(
        selectedLayer,
        layersBabylonData?.masterPlane as Mesh,
        layersBabylonData?.layerPositions as Map<string, Vector3>,
        layersBabylonData?.layerMaterials as Map<string, StandardMaterial>,
        setselectedLayer
      );
    } catch (error) {
      console.log("ERROR => ", error);
    }
  }, [selectedLayer]);

  const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
      color: '#ffffff',
    },
  });

  if (currentProject === 0) {
    return null;
  }

  if (currentProject !== 0 && currentProjectTool !== 1) {
    return null;
  }

  interface StyledTabProps {
    label: string;
    disabled?: boolean;
  }

  const StyledTab = styled((props: StyledTabProps) => (
    <Tab {...props} />
  ))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 1.0)',
    '&.Mui-selected': {
      color: '#e1e77b',
    }
  }));

  const checkMeshVisibility = (scene: Scene) => {
    window.setTimeout(() => {
      const camValue: ICamValues = {
        alpha: camera.alpha,
        beta: camera.beta,
        radius: camera.radius,
        target: camera.target
      }

      /* console.log(Object.entries(camValue).toString());
      console.log(Object.entries(cameraValues).toString()); */

      if (Object.entries(camValue).toString() !== Object.entries(cameraValues).toString()) {
        /* console.log("camera changed!!!!!"); */

        /* console.log("scene.getActiveMeshes().length: ",scene.getActiveMeshes().length); */
        let pointList: string[] = [];
        scene.getActiveMeshes().forEach(element => {
          if (/^L[0-9]{2}P[0-9]{4}$/.test(element.name)) {
            pointList.push(element.name);
          }
          setCurrentPoints(pointList);
        });

        /* setCameraValues(camValue); */
        cameraValues = camValue;
      }
      else {
        /* console.log("camera NOT changed!!!!!"); */
      }
      checkMeshVisibility(scene);
    }, 2000);
  }

  const startMeshesCheck = (scene: Scene) => {
    let pointList: string[] = [];
    let linkList: string[] = [];
    const activeMeshes: SmartArray<AbstractMesh> = scene.getActiveMeshes();
    /* console.log("activeMeshes: ", activeMeshes); */
    activeMeshes.forEach(element => {
      if (/^L[0-9]{2}P[0-9]{4}$/.test(element.name)) {
        pointList.push(element.name);
        const allLinksForPoint = oRouteHelper.getLinksForPoint(element.name);
        allLinksForPoint.forEach(linkId => {
          linkList.push(element.name + "-" + linkId);
          if (linkList.indexOf(linkId + "-" + element.name) === -1) {
            linkList.push(linkId + "-" + element.name);
          }
        });
      }
    });

    setCurrentPoints(pointList);
    setCurrentLinks(linkList);
  }

  const checkInstantMeshVisibility = (scene: Scene) => {
    /* console.log("currentFilters: ", currentFilters); */
    if (currentFilters.indexOf(t('tools.routes.map_area')) >= 0) {
      let bStartMeshesCheck: boolean = false;
      /* window.setTimeout(() => { */
      if (pointerStatus === "up") {
        bStartMeshesCheck = true;
        window.clearTimeout(timeoutWheel);
        window.clearTimeout(timeoutUp);
        timeoutUp = window.setTimeout(() => {
          if (pointerStatus === "wheel") {
            window.clearTimeout(timeoutWheel);
            window.clearTimeout(timeoutUp);
          }
          else {
            /* console.log("pointerStatus is " + pointerStatus + ", UpTimeout! check Meshes!"); */
            startMeshesCheck(scene);
          }
        }, 300);
        //
      }
      if (pointerStatus === "wheel") {
        bStartMeshesCheck = false;
        window.clearTimeout(timeoutWheel);
        window.clearTimeout(timeoutUp);
        /* console.log("pointerStatus is " + pointerStatus + ", DONT check Meshes!"); */
        timeoutWheel = window.setTimeout(() => {
          if (pointerStatus === "down") {
            window.clearTimeout(timeoutWheel);
            window.clearTimeout(timeoutUp);
          }
          else {
            /* console.log("pointerStatus is " + pointerStatus + ", WheelTimeout! check Meshes!"); */
            startMeshesCheck(scene);
          }
        }, 300);

      }
      if (pointerStatus === "down") {
        window.clearTimeout(timeoutWheel);
        window.clearTimeout(timeoutUp);
      }
    }
    else {
      // mach nix
    }
  }

  const displayPointData = (selectedMesh: Mesh) => {
    setSelectedPointId(selectedMesh.name);
    setSelectedPointMesh(selectedMesh);
    let linkList: string[] = [];
    const allLinksForPoint = oRouteHelper.getLinksForPoint(selectedMesh.name);
    allLinksForPoint.forEach(linkId => {
      linkList.push(selectedMesh.name + "-" + linkId);
      /* if (linkList.indexOf(linkId + "-" + selectedMesh.name) === -1) {
        linkList.push(linkId + "-" + selectedMesh.name);
      } */
    });
    setSelectedPointLinks(linkList);
    setPointSelected(true);
    /* console.log("displayPointData: ", selectedMesh); */
  }

  const setLayerYtoPoint = () => {
    const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
    const yValue: number = selectedMesh.position.y;
    if (_masterPlane.material === _layerMaterials.get(selectedMesh.name.substring(0, 3))) {
      _masterPlane.position.y = yValue - 0.1;
    }
  }

  const detachGizmo = () => {
    gizmoManager.attachToMesh(null);
    oGeomBuilder?._showSelectedPoint(null);
  }

  const attachGizmo = () => {
    lastGizmoMesh = currentScene.getMeshByName(lastGizmoMesh.name) as Mesh;
    gizmoManager.attachToMesh(lastGizmoMesh)
  }

  const onSceneReady = (scene: Scene) => {
    console.log("onSceneReady CALLLED!!!!!!!!!!!!!!! camera: ", camera);
    currentScene = scene;
    camera = new ArcRotateCamera("camera", -Math.PI / 2, 1, 150, new Vector3(0, 0, 0), scene);
    /* setBjsCamera(camera);
    setBjsScene(scene); */
    camera.upperRadiusLimit = 600;
    camera.lowerRadiusLimit = 2;
    camera.panningAxis = new Vector3(1, 0, -1);
    camera.wheelDeltaPercentage = 0.01;
    camera.panningSensibility = 100;
    camera.angularSensibilityX = 3000;
    camera.angularSensibilityY = 3000;

    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
    scene.useRightHandedSystem = true;

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;

    // checkRouteService(scene, camera);

    /* scene.debugLayer.show() */

    // GUI
    const guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    guiTexture.idealWidth = 600;
    const guiRayTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    guiRayTexture.idealWidth = 600;

    guiTexture.dispose();

    const allPoints: PointObject[] = oRouteHelper.getAllPointObjects();



    const allLayers: string[] = mainMapData?.layerIds as string[];
    _oGeomBuilder = new GeomBuilder();
    _oGeomBuilder.init(scene, oRouteHelper, currentProject, mainMapData as MapData, mapLayerFiles as { [key: string]: LayerFile });
    /* setGeomBuilder(_oGeomBuilder); */


    let layerNodes: TransformNode[] = [];

    allLayers.forEach(layer => {
      const transformRoot = new TransformNode((layer + "_root"));
      layerNodes.push(transformRoot);
    });

    _oGeomBuilder.setLayerArrays(allLayers, layerNodes);
    setLayerArray(allLayers);
    setLayerNodesArray(layerNodes);

    allPoints.forEach(point => {
      _oGeomBuilder.buildPoint(point, false);
    });

    allPoints.forEach(point => {
      _oGeomBuilder.buildLinksForPoint(point);
    });

    const pathsParent: TransformNode = new TransformNode("paths_root");

    oRouteHelper.getX3dPositionFromSVGCoords([0, 4096]);
    oRouteHelper.getX3dPositionFromSVGCoords([4096, 0]);

    /* const lp: LayersBabylonDataObject = _oGeomBuilder.buildMasterPlane(
      allPoints,
      allLayers,
      mapLayerFiles as { [key: string]: LayerFile },
      layerNodes,
      setselectedLayer,
      selectedNetIds,
      setSelectedNetIds,
      setNetLoaded,
      scene,
      camera
    ) as LayersBabylonDataObject; */

    let _layerPositions: Map<string, Vector3> = new Map();
    _layerMaterials = new Map();


    const coordsA: number[] = oRouteHelper.getX3dPositionFromSVGCoords([0, 4096]);
    const coordsB: number[] = oRouteHelper.getX3dPositionFromSVGCoords([4096, 0]);

    const aX: number = coordsA[0];
    const aZ: number = coordsA[1];

    const bX: number = coordsB[0];
    const bZ: number = coordsB[1];
    let pointA: Vector3 = new Vector3(aX, 0, aZ);
    /* let pointA2: Vector3 = new Vector3(aX,0,bZ); */
    let pointB: Vector3 = new Vector3(bX, 0, bZ);

    let planewidth: number = Math.abs(pointA.x) + Math.abs(pointB.x);
    if (pointA.x > 0) {
      planewidth = Math.abs(pointB.x) - Math.abs(pointA.x);
    }


    _masterPlane = MeshBuilder.CreatePlane("plane", { width: planewidth, height: planewidth, sideOrientation: Mesh.DOUBLESIDE }, scene);
    /* this.masterPlane.position = new Vector3((pointA.x + (planewidth / 2)), yValue, (pointA.z - (planewidth / 2))); */
    _masterPlane.rotation.x = 4.712389;


    /* this.pathsParent = new TransformNode("paths_root"); */

    let _layerPointsCountMap: Map<string, number> = new Map();

    let lastLayerHeight: number = 0;

    const reverseLayers: string[] = [...allLayers];
    reverseLayers.reverse();

    reverseLayers.forEach(layer => {
      const layerPointIds: string[] = [];
      let sumY: number = 0.0123;
      let counter: number = 0;
      const layerId: string = layer;
      allPoints.forEach((point, index) => {
        const pointId: string = point.id;
        if (pointId.indexOf(layerId) === 0) {
          layerPointIds.push(pointId);
          const yCoord: number = Number(point.position.y);
          if (sumY === 0.0123) {
            sumY = yCoord;
          }
          else {
            sumY += yCoord;
          }
          counter++
        }
      });
      _layerPointsCountMap.set(layerId, counter);
      /* this.pointIdLists.push(layerPointIds); */
      let yValue: number = 0;
      if (sumY === 0.0123) {
        yValue = lastLayerHeight + 5;
      }
      else {
        yValue = (sumY / counter) - 0.2;
      }
      lastLayerHeight = yValue;
      const vec3: Vector3 = new Vector3((pointA.x + (planewidth / 2)), yValue, (pointA.z - (planewidth / 2)));
      _layerPositions.set(layer, vec3);



      if (sumY !== 987654) {
        const myMaterial = new StandardMaterial("myMaterial", scene);

        myMaterial.diffuseTexture = new Texture((apiUrl + (mapLayerFiles as { [key: string]: LayerFile })[layer].png.thumbnail), scene);

        myMaterial.specularColor = new Color3(0, 0, 0);
        myMaterial.emissiveColor = new Color3(1, 1, 1);
        _layerMaterials.set(layer, myMaterial);
      }
    });
    /* this.setLayerPositions(layerPositions); */
    /* this.setLayerArrays(this.layerIDs, this.layerNodes); */

    /* this.setStartupListVisibility(
        allLayers,
        _layerPositions,
        _masterPlane,
        layerNodes,
        _layerMaterials,
        _layerPointsCountMap,
        setSelectedLayer,
        selectedNetIds,
        setSelectedNetIds,
        setNetLoaded,
        bjsScene,
        camera
    ); */

    /* setTimeout(() => {
        setNetLoaded(true);
    }, 2500); */

    const layPosMat: LayersBabylonDataObject = {
      layerPositions: _layerPositions,
      layerMaterials: _layerMaterials,
      layerPointsCountMap: _layerPointsCountMap,
      masterPlane: _masterPlane,
      setSelectedLayer: setselectedLayer,
    }

    setLayersBabylonData(layPosMat);



    let startUpIndex: number = 0;
    let highestCount: number = 0;
    allLayers.forEach((layerID, index) => {
      const layerCount: number = _layerPointsCountMap.get(layerID) as number;
      /* console.log("layerCount for " + layerID + " = " + layerCount); */
      if (layerCount > highestCount) {
        highestCount = layerCount;
        startUpIndex = index;
      }
    });

    // camera.setTarget(_layerPositions.get(allLayers[startUpIndex]) as Vector3);
    camera.alpha = -Math.PI / 2;
    camera.beta = 1;
    camera.radius = 150;
    /* const vec3: Vector3 = _layerPositions.get(allLayers[startUpIndex]) as Vector3;
    camera.setTarget(vec3); */


    let activeNetIds: string[] = [];
    allLayers.forEach(element => {
      if (element === allLayers[startUpIndex]) {
        _oGeomBuilder._setNetVisibility(element, true, layerNodes);
        activeNetIds.push(element);
      }
      else {
        _oGeomBuilder._setNetVisibility(element, false, layerNodes);
      }
    });
    setSelectedNetIds(activeNetIds);

    setTimeout(() => {
      _oGeomBuilder.setLayerVisibility(allLayers[startUpIndex], _masterPlane, _layerPositions, _layerMaterials, setselectedLayer);

      /* camera.setTarget(_masterPlane); */
      Animation.CreateAndStartAnimation("camtarget", camera, "target", 30, 15, camera.target, (new Vector3(_masterPlane.position.x, _masterPlane.position.y, _masterPlane.position.z)), Animation.ANIMATIONLOOPMODE_RELATIVE);
      Animation.CreateAndStartAnimation("camAlpha", camera, "alpha", 30, 15, camera.alpha, -Math.PI / 2, Animation.ANIMATIONLOOPMODE_RELATIVE);
      Animation.CreateAndStartAnimation("camRadius", camera, "radius", 30, 15, camera.radius, 150, Animation.ANIMATIONLOOPMODE_RELATIVE);
      Animation.CreateAndStartAnimation("camBeta", camera, "beta", 30, 15, camera.beta, 1, Animation.ANIMATIONLOOPMODE_RELATIVE);
    }, 500);

    setTimeout(() => {
      setNetLoaded(true);
    }, 1000);

    // _oGeomBuilder.setupGizmoManager(scene, camera, displayPointData);




    // START setup GizmoManager
    /* this.scene = scene;
    this.displayPointData = displayPointData; */
    const placedPoints: InstancedMesh[] = _oGeomBuilder.getPointsArray();
    // console.log("setupGizmoManager, placedPoints: ", placedPoints);

    gizmoManager = new GizmoManager(scene);
    gizmoManager.clearGizmoOnEmptyPointerEvent = false;

    gizmoManager.attachableMeshes = placedPoints;
    gizmoManager.positionGizmoEnabled = true;
    if (gizmoManager.gizmos.positionGizmo !== null) {
      gizmoManager.gizmos.positionGizmo.planarGizmoEnabled = false;
    }



    gizmoManager.onAttachedToMeshObservable.add((e) => {
      if (e !== null && _oGeomBuilder.userMode === "net") {
        lastGizmoMesh = e as Mesh;

        _oGeomBuilder.setlastGizomMesh(e.name, scene);
        _oGeomBuilder._copyIDtoclipboard(e.name);
        const alpha: number = camera.alpha;
        const beta: number = camera.beta;

        _oGeomBuilder._showSelectedPoint(e as Mesh);

        displayPointData(e as Mesh);

        Animation.CreateAndStartAnimation("camtarget", camera, "target", 30, 15, camera.target, (new Vector3(e.position.x, e.position.y, e.position.z)), Animation.ANIMATIONLOOPMODE_RELATIVE);
      }
      else {
        /* pointDiv.setAttribute("style", "display: none;")
        pointIdSpan.innerHTML = null;

        const linksDiv: HTMLDivElement = window.document.getElementById("linkinfo") as HTMLDivElement;
        while (linksDiv.firstChild) {
            linksDiv.removeChild(linksDiv.firstChild);
        }
        if (e !== null && this.userMode === "paths") {
            if (this.bControlDown) {
                // set From
                this.lastGizmoMesh = e as Mesh;
                this.select_from.value = this.lastGizmoMesh.name;
                this._checkPathsValidity();
            }
            if (this.bShiftDown) {
                // set Start
                this.lastGizmoMesh = e as Mesh;
                this.select_to.value = this.lastGizmoMesh.name;
                this._checkPathsValidity();
            }
        } */
      }



    });

    // Planar Drag behaviour:
    gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragStartObservable.add(() => {
      _oGeomBuilder._addVisibleRays(scene);
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
      // TODO:
      /* this._savePositionForUndo(selectedMesh.name); */
    });
    gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragObservable.add(() => {
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
      _oGeomBuilder._updateLinks(selectedMesh, scene);
    });
    gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.dragBehavior.onDragEndObservable.add(() => {
      _oGeomBuilder._hideRays();
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.yPlaneGizmo.attachedMesh as Mesh;
      _oGeomBuilder.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
      /* this.dataVault.setPointPosition(selectedMesh.name, selectedMesh.position);
      // TODO:
      this._updateRotationForSingleLinks();
      this._renewGraphs(); */
    });

    // X-axis Drag behaviour:
    gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragStartObservable.add(() => {
      _oGeomBuilder._addVisibleRays(scene);
      /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo.xGizmo.attachedMesh as Mesh;
      this._savePositionForUndo(selectedMesh.name);
      if (this.bControlDown) {
          this._createNewPointMesh();
      } */
    });
    gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragObservable.add((e) => {
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.xGizmo.attachedMesh as Mesh;
      if (_oGeomBuilder.currentRayCastMesh) {
        // calculate angled position
        _oGeomBuilder.currentDragPlaneVector = e.dragPlaneNormal;
        _oGeomBuilder._calcAngles(selectedMesh, scene);
      }
      else {
        _oGeomBuilder._removeSnapPlane(selectedMesh.name, scene);
      }
      _oGeomBuilder._updateLinks(selectedMesh, scene);
    });
    gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragEndObservable.add(() => {
      _oGeomBuilder._hideRays();
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.xGizmo.attachedMesh as Mesh;
      _oGeomBuilder._removeSnapPlane(selectedMesh.name, scene);
      _oGeomBuilder.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
      /* 
      TODO:
      this._updateRotationForSingleLinks();
      this._renewGraphs(); */
    });


    // Z-axis Drag behaviour:
    gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragStartObservable.add(() => {
      _oGeomBuilder._addVisibleRays(scene);
      /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
      this._savePositionForUndo(selectedMesh.name);
      if (this.bControlDown) {
          this._createNewPointMesh();
      } */
    });
    gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragObservable.add((e) => {
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
      if (_oGeomBuilder.currentRayCastMesh) {
        _oGeomBuilder.currentDragPlaneVector = e.dragPlanePoint;
        _oGeomBuilder._calcAngles(selectedMesh, scene);
      }
      else {
        _oGeomBuilder._removeSnapPlane(selectedMesh.name, scene);
      }
      _oGeomBuilder._updateLinks(selectedMesh, scene);
    });
    gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragEndObservable.add(() => {
      _oGeomBuilder._hideRays();
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.zGizmo.attachedMesh as Mesh;
      _oGeomBuilder._removeSnapPlane(selectedMesh.name, scene);
      _oGeomBuilder.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });
      // TODO:

      /* this._removeSnapPlane(selectedMesh.name);            
      this._updateRotationForSingleLinks();
      window.setTimeout(() => {
          this._renewGraphs();
      }, 250); */
    });


    // Y-axis Drag behaviour:
    gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragStartObservable.add(() => {
      _oGeomBuilder._addVisibleRays(scene);
      /* const selectedMesh: Mesh = this.gizmoManager.gizmos.positionGizmo.yGizmo.attachedMesh as Mesh;
      this.dataVault.setPointPosition(selectedMesh.name, selectedMesh.position);
      this._savePositionForUndo(selectedMesh.name);
      if (this.bControlDown) {                
          this._createNewPointMesh();
      } */
    });
    gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragObservable.add(() => {
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.yGizmo.attachedMesh as Mesh;
      if (_oGeomBuilder.currentRayCastMesh) {
        selectedMesh.position.y = _oGeomBuilder.currentRayCastMesh.position.y;
        // calculate angled position
      }
      _oGeomBuilder._updateLinks(selectedMesh, scene);
    });
    gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragEndObservable.add(() => {
      _oGeomBuilder._hideRays();
      const selectedMesh: Mesh = gizmoManager.gizmos.positionGizmo?.yGizmo.attachedMesh as Mesh;
      _oGeomBuilder.oRouteHelper.setPointPositionForId(selectedMesh.name, { x: selectedMesh.position.x, y: selectedMesh.position.y, z: selectedMesh.position.z });

      /*  this._updateRotationForSingleLinks();
       window.setTimeout(() => {
           this._renewGraphs();
       }, 250); */
    });


    // END setup GizmoManager

    _oGeomBuilder.setupRayCaster(scene, camera);
    // checkMeshVisibility(scene);

    scene.onPointerObservable.add((pointerInfo) => {
      /* console.log("pointerInfo: ", pointerInfo); */
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          /* console.log("onPointerDown"); */
          pointerStatus = "down";
          break;
        case PointerEventTypes.POINTERUP:
          /* console.log("onPointerUp"); */
          pointerStatus = "up";
          checkInstantMeshVisibility(scene);
          break;
        case PointerEventTypes.POINTERWHEEL:
          /* console.log("onPointerWheel"); */
          pointerStatus = "wheel";
          radiusStored = camera.radius;
          checkInstantMeshVisibility(scene);
          break;
        case PointerEventTypes.POINTERPICK:
          /* console.log("onPointerUp"); */
          break;
        case PointerEventTypes.POINTERMOVE:
          /* BABYLON.Tools.Log("onPointerMove"); */
          break;
      }
    });

    initSearch();
    setGeomBuilder(_oGeomBuilder);
  }

  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = (scene: Scene) => {
    /* scene.getActiveMeshes().forEach(element => {
      console.log("element.name: ",element.name);
    }); */
    /* console.log(scene.getActiveMeshes().forEach */
    /* console.log(scene.getActiveMeshes());
    console.log(scene.getActiveMeshes().length); */
    /* if (box !== undefined) {
      var deltaTimeInMillis = scene.getEngine().getDeltaTime();
  
      const rpm = 10;
      box.rotation.y += ((rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    } */
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue as number);
  }

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    /* console.log("enteredName: ", enteredName); */

    let filteredWords: string[] = [];
    let filteredLinks: string[] = [];

    if (currentFilters.indexOf(t('tools.routes.map_area')) >= 0) {
      filteredWords = currentPoints.filter((point) => point.indexOf(enteredName) >= 0);
      filteredLinks = currentLinks.filter((link) => link.indexOf(enteredName) >= 0);
    }
    else {
      const allPointIds: string[] = oRouteHelper.getAllPoints();
      const allLinks: string[] = oRouteHelper.getAllLinks();
      filteredWords = allPointIds.filter((point) => point.indexOf(enteredName) >= 0);
      filteredLinks = allLinks.filter((link) => link.indexOf(enteredName) >= 0);
    }

    setCurrentPoints(filteredWords);
    setCurrentLinks(filteredLinks);

  }


  const initSearch = () => {
    const enteredName = "";
    /* console.log("enteredName: ", enteredName); */

    let filteredWords: string[] = [];
    let filteredLinks: string[] = [];

    const allPointIds: string[] = oRouteHelper.getAllPoints();
    const allLinks: string[] = oRouteHelper.getAllLinks();
    filteredWords = allPointIds.filter((point) => point.indexOf(enteredName) >= 0);
    filteredLinks = allLinks.filter((link) => link.indexOf(enteredName) >= 0);


    setCurrentPoints(filteredWords);
    setCurrentLinks(filteredLinks);

  }

  const updateLinks = () => {
    console.log("updateLinks CALLED");
    oGeomBuilder?._updateLinks(lastGizmoMesh, currentScene);
  }

  const handleOpenDispDialog = () => {
    setOpenDispSettings(true);
  }

  const handleCloseSnack = () => {
    setSnackState({
     ...snackState, open: false,
    })
  }

  return (
    <Container sx={{
      height: '100%',
      background: 'linear-gradient(#092935 0%, #092935 100%) 0% 0% no-repeat padding-box',
      maxWidth: 'unset !important',
      position: 'relative',
      paddingRight: 0
    }}>
      <SnackProvider open={snackState.open} message={snackState.message} vertical={snackState.vertical} horizontal={snackState.horizontal} alert={snackState.alert} handleClose={handleCloseSnack}></SnackProvider>
      {/*   const [openDispSettings, setOpenDispSettings] = useState<boolean>(false); */}
      <RouteDisplaySettings openDispSettings={openDispSettings} setOpenDispSettings={setOpenDispSettings} gb={oGeomBuilder as GeomBuilder}></RouteDisplaySettings>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography gutterBottom align='left' sx={{
          fontWeight: '700', fontSize: '2.25rem', pt: '0.5vh', pl: '1vh', pb: '0vh'
        }}>{t("sidebar.entry2")}</Typography>
        <Tabs
          value={valueTabs}
          onChange={handleChangeTabs}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="Routes menu"
          sx={{ ml: '6vh', height: '5vh', marginBottom: '0.9em' }}
        >

          <StyledTab label={t('tools.routes.editor')} />
          <StyledTab label={t('tools.routes.preview')} />
        </Tabs>
      </Box>
      <CssWhiteButton
        type="submit"
        /* fullWidth */
        /* disabled={determinDisabledSave()} */
        /* disabled={bDisableSave} */
        variant="contained"
        /* onClick={saveChangesToMap} */
        sx={{
          position: 'absolute',
          right: '3.5vh',
          top: '-1.15vh',
          mt: '2.5vh',
          ml: '2vh',
          backgroundColor: 'primary.contrastText',
          color: 'primary.main',
          width: 'fit-content',
          '&:hover': {
            color: 'primary.contrastText',
          },
        }}
      >
        {t('admin_users.save')}
        <SaveIcon sx={{
          marginLeft: '6px'
        }}></SaveIcon>
      </CssWhiteButton>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '-24px', marginRight: '-24px', marginTop: '-10px', height: '86.4vh' }}>
        <Box sx={{ position: 'absolute', left: '1.2%' }}>
          <CssWhiteButton
            type="submit"
            onClick={() => handleOpenDispDialog()}
            /* fullWidth */
            /* disabled={determinDisabledSave()} */
            /* disabled={bDisableSave} */
            variant="contained"
            /* onClick={saveChangesToMap} */
            sx={{
              /* position: 'absolute',
              left: '2%',
              top: '8vh', */
              mt: '1vh',
              ml: '1vh',
              backgroundColor: 'primary.contrastText',
              borderRadius: '1.5em',
              color: 'primary.main',
              width: 'fit-content',
              '&:hover': {
                color: 'primary.contrastText',
              },
            }}
            startIcon={<VisibilityIcon />}
          >
            {t('tools.routes.disp_settings')}
          </CssWhiteButton>
          {/* <IconButton
            size="small"
            sx={{
              mt: '1vh',
              ml: '1vh',
              backgroundColor: 'primary.contrastText',
              color: 'primary.main',
              '&:hover': {
                color: 'primary.contrastText',
                backgroundColor: 'primary.main',
              },
            }}
          >
            <SettingsIcon />
          </IconButton> */}
        </Box>
        {netLoaded === false ?
          <Route_LoadingScreen></Route_LoadingScreen>
          :
          null
        }
        {prepsForBabylon === true ?
          <SceneComponent style={{ width: '78.26%', height: '100%' }} antialias onSceneReady={onSceneReady} onRender={onRender} id='babylon-canvas' />
          :
          null
        }
        <Box sx={{ width: '21.74%', height: '100%', backgroundColor: '#0D3B4D', pt: '0', pl: '1em', pb: '0', pr: '1em', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {pointSelected === false && linkSelected === "" ?
            <Box>
              <CssTextField
                id="input-with-icon-textfield"
                sx={{
                  width: '100%',
                }}
                /* color="success.contrastText" */
                /* size='large' */
                /* value={searched} */
                onChange={requestSearch}
                placeholder='ID suchen'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="filled"
              />
              <Box sx={{ marginTop: '0.5em' }}>
                <FilterButton menuFilter={[t('tools.routes.map_area')]} selectedSetter={setFilterSelected} />
              </Box>
              <RouteNetAccordion pointsList={currentPoints as string[]} linksList={currentLinks} rh={oRouteHelper as RouteHelper} gb={oGeomBuilder as GeomBuilder}></RouteNetAccordion>
            </Box>
            :
            null
          }
          {pointSelected === true ?
            <Box>
              <RouteEditPoint pointId={selectedPointId} pointMesh={selectedPointMesh as Mesh} linksList={selectedPointLinks} rh={oRouteHelper as RouteHelper} gb={oGeomBuilder as GeomBuilder} setPointSelected={setPointSelected} setLayerYtoPoint={setLayerYtoPoint} setSelectedLink={setLinkSelected} detachGizmo={detachGizmo} setSnackState={setSnackState}></RouteEditPoint>
            </Box>
            :
            null
          }
          {linkSelected !== "" ?
            <Box>
              <RouteEditLink /* linkId='L12P3456-L78P9123' */ linkId={linkSelected} pointMesh={selectedPointMesh as Mesh} linksList={selectedPointLinks} rh={oRouteHelper as RouteHelper} gb={oGeomBuilder as GeomBuilder} setPointSelected={setPointSelected} setLinkSelected={setLinkSelected} attachGizmo={attachGizmo} updateLinks={updateLinks} setSnackState={setSnackState}></RouteEditLink>
            </Box>
            :
            null
          }
        </Box>
        <RouteLayerStepper apiProject={apiProject} mapData={mainMapData as MapData} selectedLayer={selectedLayer} setselectedLayer={setselectedLayer} layerNodes={layerNodesArray as TransformNode[]} gb={oGeomBuilder as GeomBuilder} setSelectedNetIds={setSelectedNetIds} selectedNetIds={selectedNetIds}></RouteLayerStepper>
      </Box>
    </Container>
  );
}