import { DndContext, closestCenter } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Typography } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';


const listFontSx = { fontWeight: '700', fontSize: '1rem' };
const accordionListStyle = {
    width: '100%',
    backgroundColor: '#092935',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    marginBottom: '5px',
    borderRadius: '4px',
  };

export default function DraggableBox({ id, name, layer }: { id: string; name: string; layer: string;}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id,
    });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  
    return (
      <Box ref={setNodeRef} {...attributes} {...listeners} style={style} sx={accordionListStyle}>
        <Typography align='left' sx={listFontSx} >{name}</Typography>
        {/* <Typography align='right' sx={listFontSmallSx}>{area.point}</Typography> */}
      </Box>
    );
  }