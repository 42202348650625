import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';




export default function MenuLeftSide(props: { searchPlaceholder: string, children?: React.ReactNode }) {
    const CssTextField = styled(TextField)({
        width: '30%',
        '& label.Mui-focused': {
            color: '#ffffff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ffffff',
        },
        '& .MuiInputBase-input': {
            paddingTop: '16px',
            paddingBottom: '16px',
        },
        '& .MuiInputAdornment-root': {
            paddingBottom: '16px'
        }

    });


    return (
        
            <Box sx={{
                width: '21.74%',
                minWidth:  '400px',
                height: '100%',
                backgroundColor: '#0D3B4D',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '41px 44px 24px 44px',
                
            }}>


                <CssTextField
                    variant="filled"
                    placeholder={props.searchPlaceholder}
                    id="filled-start-adornment"
                    sx={{
                        width: '100%',
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />

                <Box sx={{
                    backgroundColor: '#0D3B4D',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    alignItems: 'start',
                    paddingTop: '24px',
                }}>
                        {props.children}
                </Box>


            </Box>
        
    )

}
