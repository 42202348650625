import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import Container from '@mui/material/Container';
import MediaCard from '../smallComponents/mediaCard';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import { setCurrentGuiController_mapsAssistant, setCurrentGuiController_mapsManualSetup, setCurrentGuiController_mapsStep, setCurrentGuiController_valueTabs } from './../slices/guiControllerSlice';
import { useAppDispatch } from '../hooks';
import { useAddMapToProjectMutation, useCreateMapMutation, useGetProjectQuery, useLazyGetMapQuery, useLazyGetProjectQuery } from '../slices/apiSlice';
import MapsAssistant from './projectComponents/maps_assistant';
import MapsManualSetup from './projectComponents/maps_manualsetup'
import React, { useState } from 'react';
import { ApiProject } from '../../interfaces/commonInterfaces';
import { resetCurrentMapDataController } from '../slices/MapDataSlice';
import { MapData, UploadedFile, UploadedFiles } from '../../interfaces/interfaceGuiController';


const CssWhiteButton = styled(Button)({
  '& .MuiButton-root:hover': {
    color: '#ffffff',
  },
});

let currentMapUuid: string = "";

export default function Tool_maps() {
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  const showAssistant = useAppSelector((state) => state.guicontroller.value.mapsAssistant);
  const showManualSetup = useAppSelector((state) => state.guicontroller.value.mapsManualSetup);
  /* const [projectHasMap, setProjectHasMap] = React.useState(false); */
  /* const [activeMapUuid, setActiveMapUuid] = React.useState<string>(''); */
  /* const { data, error, isLoading } = useGetProjectQuery(currentProject); */
  const [mainMapData, setMainMapData] = React.useState<MapData>();
  const [mainMapFiles, setMainMapFiles] = React.useState<UploadedFiles>();
  const [createMap] = useCreateMapMutation();
  const [getProject] = useLazyGetProjectQuery();
  const [getMapCall] = useLazyGetMapQuery();
  const [addMapToProject] = useAddMapToProjectMutation();
  const [apiProject, setApiProject] = useState<ApiProject>({
    "projectId": 0,
    "name": "",
    "shortName": "",
    "description": "",
    "comment": "",
    "city": "",
    "openingTime": "10:00:00",
    "closingTime": "20:00:00",
    "country": "DE",
    "active": true,
    "hasService": false,
    "market": "",
    "tags": [],
    "links": [],
    "products": [],
    "addons": [],
    "demoGalleryEnabled": false,
    "demoGalleryPublic": false,
    "mapUuid": "",
    "languages": [
      {
        "code": "de",
        "name": "German"
      },
    ],
    "createdAt": "2023-08-12T15:29:39.000000Z",
    "modifiedAt": "2023-08-12T15:29:39.000000Z",
    "timezone": null
  })
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const nullCheck = (str: string | null): boolean => {
    let bNullValue = false;
    if (str === null) {
      bNullValue = true;
    }
    if (str === undefined) {
      bNullValue = true;
    }
    if (str === "") {
      bNullValue = true;
    }
    return bNullValue;
  }

  const callNewMapMutation = async (proj: ApiProject) => {
    await createMap({ "mapData": "{}", "tilesData": "{}" })
      .then((response: any) => {
        console.log("CREATE MAP response.data.data.uuid: ", response.data.data.uuid);
        /* setActiveMapUuid(response.data.data.uuid); */
        currentMapUuid = response.data.data.uuid as string;

        const newApiProj: ApiProject = structuredClone(proj);
        newApiProj.mapUuid = currentMapUuid;

        setApiProject(newApiProj);

        setMainMapData(response.data.data.mapData as MapData);
        callAddMapToProject(response)
      }
      );
  }

  const callAddMapToProject = async (response: any) => {
    await addMapToProject({ "productUuid": response.data.data.uuid, "projectId": currentProject })
      .then((response: any) => {
        setMainMapFiles(undefined);
        console.log("added MAP to Project ", response);
        /* queryProject(); */
      }
      );
  }


  const handleWoAssistant = (click: boolean = false) => {
    // console.log("handleWOAssistantStart WTF");
    // console.log("apiProject: ", apiProject);
    // console.log("currentProject: ", currentProject);
    if (click === true) {
      dispatch(setCurrentGuiController_mapsStep(6));
      dispatch(setCurrentGuiController_valueTabs(0));
      dispatch(setCurrentGuiController_mapsAssistant(false));
      dispatch(setCurrentGuiController_mapsManualSetup(true));
    }
    if (click === false) {
      setMainMapFiles({ files: [] });
      setMainMapData({ transform: {} })
      queryMap();
    }
  }

  const queryMap = async () => {
    /* console.log("activeMapUuid: ", activeMapUuid); */
    /* console.log("currentMapUuid: ", currentMapUuid); */
    await getMapCall(currentMapUuid)
      .then((response: any) => {
        /* console.log("queryMap response: ", response); */
        let mapData: MapData = response.data.data.mapData as MapData;
        setMainMapData(mapData);
        // console.log("queryMap mapData: ", mapData);
        if (response.data.data.files !== null) {
          let uf: UploadedFiles = { files: [] };
          response.data.data.mapData.layerIds.forEach((id: string) => {
            let fileData: UploadedFile = response.data.data.files[id] as UploadedFile;
            uf.files.push(fileData);
          });
          /* console.log("tool_maps, setMainMapFiles: ", uf); */
          setMainMapFiles(uf);
        }
        else {
          let uf2: UploadedFiles = { files: [] };
          setMainMapFiles(uf2);
        }
        // console.log("GEHTS HIER AB??? showAssistant: ", showAssistant);
        if (showAssistant === true) {
          // wait...
        }
        else {
          if (mapData.layerNames !== undefined) {
            /* console.log("Sufficient mapData in Project, set maptool"); */
            /* dispatch(setCurrentGuiController_mapsStep(6));
            dispatch(setCurrentGuiController_valueTabs(0)); */
            dispatch(setCurrentGuiController_mapsAssistant(false));
            dispatch(setCurrentGuiController_mapsManualSetup(true));
            // mach nix
          }
          else {
            /* console.log("No sufficient mapData in Project, reset maptool");     */
            dispatch(setCurrentGuiController_mapsAssistant(false));
            dispatch(setCurrentGuiController_mapsManualSetup(false));
            /* dispatch(setCurrentGuiController_mapsStep(6));
            dispatch(setCurrentGuiController_valueTabs(0)); */
          }
        }


      }
      );
  }

  React.useEffect(() => {
    /* console.log("######################## QUERY PROJECT: " + currentProject); */
    currentMapUuid = "";
    queryProject();
  }, [currentProject])

  const queryProject = async () => {
    await getProject(currentProject)
      .then((response: any) => {
        const apiTempProject: ApiProject = response.data.data as ApiProject;
        setApiProject(apiTempProject);
        /* console.log("apiTempProject ", apiTempProject); */
        if (nullCheck(apiTempProject.mapUuid) === true) {
          /* console.log("NO map in project " + apiTempProject.projectId); */
          dispatch(setCurrentGuiController_mapsStep(6));
          dispatch(setCurrentGuiController_valueTabs(0));
          dispatch(setCurrentGuiController_mapsAssistant(false));
          dispatch(setCurrentGuiController_mapsManualSetup(false));
          dispatch(resetCurrentMapDataController());
          callNewMapMutation(apiTempProject);
        }
        else {
          currentMapUuid = apiTempProject.mapUuid as string;
          handleWoAssistant();
          // load map
        }
      });
  }


  if (currentProject === 0) {
    return null;
  }

  if (currentProject !== 0 && currentProjectTool !== 0) {
    return null;
  }







  return (
    <Container sx={{
      /* position: 'absolute',
      top: '6.8%',
      left: '3.4%',
      width: '96.6%', */
      height: '100%',
      background: 'linear-gradient(#12526A 0%, #12526A 100%) 0% 0% no-repeat padding-box',
      maxWidth: 'unset !important'
    }}>
      <Typography gutterBottom align='left' sx={{
        fontWeight: '700', fontSize: '2.25rem', pt: '0.5vh', pl: '1vh', pb: '0vh'
      }}>{t("sidebar.entry1")}</Typography>
      {(showAssistant === false && showManualSetup === false)
        ?
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <MediaCard></MediaCard>
          <CssWhiteButton variant="text" sx={{
            mt: 1,
            ml: 1,
            mb: 2,
            color: 'primary.contrastText',
            width: 'fit-content',
            '&:hover': {
              color: 'primary.contrastText',
            },
          }} onClick={() => handleWoAssistant(true)}>{t("tools.maps.wo_assistant")}
            <NavigateNextIcon sx={{
              marginLeft: '6px'
            }}></NavigateNextIcon>
          </CssWhiteButton>
        </Box>
        : null
      }
      {(showAssistant === true && showManualSetup === false && currentProject === apiProject.projectId)
        ?
        <MapsAssistant apiProject={apiProject} mapData={mainMapData as MapData} mapFiles={mainMapFiles as UploadedFiles} setMainMapData={setMainMapData} setMainMapFiles={setMainMapFiles} requeryMap={queryMap}></MapsAssistant>
        : null
      }
      {(showAssistant === false && showManualSetup === true && currentProject === apiProject.projectId)
        ?
        <MapsManualSetup apiProject={apiProject} mapData={mainMapData as MapData} mapFiles={mainMapFiles as UploadedFiles} setMainMapData={setMainMapData} setMainMapFiles={setMainMapFiles} requeryMap={queryMap}></MapsManualSetup>
        : null
      }
    </Container>
  );
}