import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RouteHelper } from "../../../classes/RouteHelper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import closedIcon from '../../../res/svg/closed.svg';
import escalatorDownIcon from '../../../res/svg/escalator-down.svg';
import escalatorUpIcon from '../../../res/svg/escalator-up.svg';
import notEscalatorDownIcon from '../../../res/svg/not-escalator-down.svg';
import notEscalatorUpIcon from '../../../res/svg/not-escalator-up.svg';
import liftDownIcon from '../../../res/svg/lift-down.svg';
import liftUpIcon from '../../../res/svg/lift-up.svg';
import rampDownIcon from '../../../res/svg/ramp-down.svg';
import rampUpIcon from '../../../res/svg/ramp-up.svg';
import otherIcon from '../../../res/svg/other.svg';
import disabledIcon from '../../../res/svg/disabled.svg';
import stairDownIcon from '../../../res/svg/stair-down.svg';
import stairUpIcon from '../../../res/svg/stair-up.svg';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Icon from "@mui/material/Icon";
import React from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface IWeightsDropDown {
    /* pointId: string,
    pointMesh: Mesh,
    linksList: string[], */
    linkId: string,
    rh: RouteHelper,
    updateLinks: () => void
}



export default function WeightsDropDown(props: React.PropsWithChildren<IWeightsDropDown>) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const linkTypes: string[] = [
        "default",
        "other",
        "stair-up",
        "stair-down",
        "escalator-up",
        "not-escalator-up",
        "not-escalator-down",
        "escalator-down",
        "lift-up",
        "lift-down",
        "ramp-up",
        "ramp-down",
        "disabled",
        "closed",
    ];
    const linkColors: Map<string, string> = new Map();
    linkColors.set("default", "#ffffff");
    linkColors.set("other", "#d9a336");
    linkColors.set("stair-up", "#0288d1");
    linkColors.set("stair-down", "#0288d1");
    linkColors.set("escalator-up", "#0288d1");
    linkColors.set("not-escalator-up", "#0288d1");
    linkColors.set("not-escalator-down", "#0288d1");
    linkColors.set("escalator-down", "#0288d1");
    linkColors.set("lift-up", "#0288d1");
    linkColors.set("lift-down", "#0288d1");
    linkColors.set("ramp-up", "#0288d1");
    linkColors.set("ramp-down", "#0288d1");
    linkColors.set("disabled", "#d0bcfe");
    linkColors.set("closed", "#d32f2f");



    const getLinkType = (id: string): string => {
        let typeString: string = "";
        try {
            typeString = props.rh.getLinkType(id);
        } catch (error) {
            // mach nix
        }
        return typeString;
    }

    const getPointColor = (id: string): string => {
        let colorString: string = "";
        try {
            colorString = props.rh.getPointTypeColor(id);
        } catch (error) {
            // mach nix
        }
        return colorString;
    }

    const getLinkColor = (linkType: string): string => {
        let color: string = linkColors.get(linkType) as string
        return color;
    }

    const getLinkElement = (id: string) => {
        switch (id) {
            case "default":
                return <SwapHorizIcon />
            case "other":
                return <Icon>
                    <img alt='Icon' src={otherIcon} height={24} width={24} />
                </Icon>
            case "stair-up":
                return <Icon>
                    <img alt='Icon' src={stairUpIcon} height={24} width={24} />
                </Icon>
            case "stair-down":
                return <Icon>
                    <img alt='Icon' src={stairDownIcon} height={24} width={24} />
                </Icon>
            case "escalator-up":
                return <Icon>
                    <img alt='Icon' src={escalatorUpIcon} height={24} width={24} />
                </Icon>
            case "not-escalator-up":
                return <Icon>
                    <img alt='Icon' src={notEscalatorUpIcon} height={24} width={24} />
                </Icon>
            case "not-escalator-down":
                return <Icon>
                    <img alt='Icon' src={notEscalatorDownIcon} height={24} width={24} />
                </Icon>
            case "escalator-down":
                return <Icon>
                    <img alt='Icon' src={escalatorDownIcon} height={24} width={24} />
                </Icon>
            case "lift-up":
                return <Icon>
                    <img alt='Icon' src={liftUpIcon} height={24} width={24} />
                </Icon>
            case "lift-down":
                return <Icon>
                    <img alt='Icon' src={liftDownIcon} height={24} width={24} />
                </Icon>
            case "ramp-up":
                return <Icon>
                    <img alt='Icon' src={rampUpIcon} height={24} width={24} />
                </Icon>
            case "ramp-down":
                return <Icon>
                    <img alt='Icon' src={rampDownIcon} height={24} width={24} />
                </Icon>
            case "disabled":
                return <Icon>
                    <img alt='Icon' src={disabledIcon} height={24} width={24} />
                </Icon>
            case "closed":
                return <Icon>
                    <img alt='Icon' src={closedIcon} height={24} width={24} />
                </Icon>
            default:
                return null
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (param: string) => {
        setAnchorEl(null);
        /* console.log("menu selection: ", param); */
        if (linkTypes.indexOf(param) >= 0) {
            props.rh.setLinkType(props.linkId, param);
            window.setTimeout(() => {
                props.updateLinks();
            }, 300);
        }
    };

    const getLanguageLabel = (linkType: string): string => {
        let label: string = "tools.routes.link_" + (linkType.replaceAll("-", "_"))
        return label;
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '19vw', mt: '1em' }}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        color: 'primary.contrastText',
                        textTransform: 'unset !important',
                        fontWeight: 'bold'
                    }}
                >
                    {getLinkElement(getLinkType(props.linkId))}
                    <Typography align='left' color={getLinkColor(getLinkType(props.linkId))} sx={{
                        fontWeight: '700', whiteSpace: 'pre-line', fontSize: '0.9rem', marginLeft: '0.3em', marginRight: '0.6em', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh'
                    }}>{t(getLanguageLabel(getLinkType(props.linkId)))}</Typography>

                    {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                </Button>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        backgroundColor: '#0D3B4D',
                        paddingBottom: '0px !important',
                        paddingTop: '0px !important',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '20vw',
                        marginRight: '-2em'
                    }
                }}
            >
                {linkTypes.map((linktype, index) => (
                    <MenuItem sx={{
                        width: '9vw',
                        /* height: '3vw', */
                        display: 'flex',
                        backgroundColor: '#061C25',
                        padding: '0.5em',
                        marginRight: '0.3em',
                        marginBottom: '0.3em',
                    }}
                        key={linktype + "_" + index} onClick={() => handleClose(linktype)}>
                        {getLinkElement(linktype)}
                        <Typography align='left' color={getLinkColor(linktype)} sx={{
                            fontWeight: '700', whiteSpace: 'pre-line', fontSize: '0.9rem', marginLeft: '0.3em', pt: '0.0vh', pl: '0', pb: '0vh', mb: '0vh', width: '7vw'
                        }}>{t(getLanguageLabel(linktype))}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );

}