import Container from '@mui/material/Container';
/* import TextField from '@mui/material/TextField'; */
import Typography from '@mui/material/Typography';
/* import styled from '@mui/material/styles/styled'; */
import { useTranslation } from 'react-i18next';
import { /* useAppDispatch */ useAppSelector } from './hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import "./scrollDashboard.css";
import { useLazyGetProtocolForProjectQuery } from './slices/apiSlice';
import { useEffect, useState } from 'react';
import { ProjectProtocolEntry } from '../interfaces/commonInterfaces';
import 'dayjs/locale/de';
import dayjs from 'dayjs';

/* const CssTextField = styled(TextField)({
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:hover': {
        borderBottomColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
}); */


export default function Dashboard() {
    /* const currentProject = useAppSelector((state) => state.currentproject.value); */
    const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
    // const currentUser = useAppSelector((state) => state.userstatus.value);
    const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode);
    /* const { data, error, isLoading } = useGetProtocolForProjectQuery('' + currentProject); */
    const [getProtocolForProject] = useLazyGetProtocolForProjectQuery();
    /* const dispatch = useAppDispatch(); */
    const [apiProjectProtocols, setApiProjectProtocols] = useState<ProjectProtocolEntry[]>([{ createdAt: "", message: "" }]);
    const { t } = useTranslation();

    const randomDate = (start: Date, end: Date): Date => {
        const rDate: Date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        return rDate;
    }

    const randomIntFromInterval = (min: number, max: number) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    let dates: Date[] = [];
    for (let index = 0; index < randomIntFromInterval(3, 20); index++) {
        const element = randomDate(new Date(2022, 0, 1), new Date());
        dates.push(element);

    }
    // const sortedDates = dates.slice().sort((a, b) => a.getTime() - b.getTime());

    /* useEffect(() => {
        if (isLoading === false && error === undefined) {
            const apiProjectProtocolsList = data.data as ProjectProtocolEntry[];
            console.log("####################### API useGetProtocolForProjectQuery");
            console.log("currentAdminMode API useGetProtocolForProjectQuery: ", currentAdminMode);     
            setApiProjectProtocols(apiProjectProtocolsList);
        }
    }, [data, error, isLoading]); */

    useEffect(() => {
        if (currentProject !== 0) {
            queryProtocolForProject();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject]);

    const queryProtocolForProject = async () => {

        await getProtocolForProject('' + currentProject)
            .then((response: any) => {
                const apiProjectProtocolsList = response.data.data as ProjectProtocolEntry[];
                /* console.log("####################### API useGetProtocolForProjectQuery");
                console.log("currentAdminMode API useGetProtocolForProjectQuery: ", currentAdminMode); */
                /* console.log("########################")
                console.log("response.data.data: ", response.data.data); */
                setApiProjectProtocols(apiProjectProtocolsList);
            }
            );
    }



    return (
        <Container key={"rootDashboard"} component="main" maxWidth="sm"
            sx={{
                /* position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)', */
                marginRight: '2em',
                marginTop: '2rem',
                marginLeft: 'unset',
                left: '10%',
            }}>

            <Typography variant="h3" gutterBottom align='left' sx={{
                fontWeight: '600',
            }}>
                {t('dashboard.greeting') + " " + currentUser.firstname}
            </Typography>
            <Container key={"rootDateList"} sx={{
                overflow: 'auto',
                maxHeight: '80%'
            }}>
                {currentProject === 0
                    ?
                    <Container key={"rootGreeting"} sx={{
                        paddingLeft: '0px !important'
                    }}>
                        <Typography variant="subtitle1" gutterBottom align='left'>
                            {currentAdminMode === 0 ? t('dashboard.select') : null}
                        </Typography>
                    </Container>
                    :
                    <Container key={"rootDateEntry_" + Math.random()} sx={{
                        maxHeight: '75vh',
                        paddingLeft: '0px !important'
                    }}>
                        <Typography variant="subtitle1" gutterBottom align='left'>
                            {t('dashboard.changes')}
                        </Typography>
                        <div className="dashboard_scroll_area">
                            <PerfectScrollbar>
                                <div className="content">
                                    {(apiProjectProtocols as ProjectProtocolEntry[]).map((entry) => (
                                        <Container key={"DateEntry_" + Math.random()}>
                                            <Typography variant="subtitle1" gutterBottom align='left'>
                                                {dayjs(entry.createdAt).locale('de').format('LLL') + " Uhr:"}
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom align='left' sx={{ ml: '1em' }}>
                                                {entry.message}
                                            </Typography>
                                        </Container>
                                    ))
                                    }
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Container>

                }
            </Container>
        </Container>

    );
}