
/* import OSM from "ol/source/OSM"; */
import Map from 'ol/Map'

import { useEffect } from "react";
/* import TileLayer from "ol/layer/WebGLTile"; */
import View from "ol/View";
import React from "react";
import { useAppSelector, useAppDispatch } from '../hooks';
/* import {defaults as defaultControls} from "ol/control/defaults" */
import Attribution from "ol/control/Attribution"
import Fullscreen from "ol/control/FullScreen"
import Zoom from "ol/control/Zoom"
import { Modify, defaults } from 'ol/interaction';
import Projection from "ol/proj/Projection";
import ImageLayer from "ol/layer/Image";
import { ImageStatic } from "ol/source";
import { boundingExtent, getCenter } from "ol/extent";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import BaseLayer from 'ol/layer/Base';
import Collection from 'ol/Collection';
import { setCurrentGuiController_mapsPixelCoordinates_A } from "../slices/guiControllerSlice";
import { setCurrentGuiController_mapsPixelCoordinates_B } from "../slices/guiControllerSlice";
import { MapData, UploadedFiles } from '../../interfaces/interfaceGuiController';


interface IReferenceProps {
    referenceProp: {
        assistNumber: number;
        mapObject: MapData | undefined;
        mapFiles: UploadedFiles;
    }
}

const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_PATH_TO_API) {
        case 'production ':
            url = 'https://creator.guide3d.com';
            break;
        case 'development ':
        default:
            url = 'http://api.creator.local';
    }

    return url;
}



function ReferenceMap(props: React.PropsWithChildren<IReferenceProps>) {
    const dispatch = useAppDispatch();
    // pull refs
    const assistNumber: number = props?.referenceProp.assistNumber;
    /* const mapData: MapData = props?.referenceProp.mapObject as MapData; */
    const ref = React.useRef<HTMLDivElement>(null)
    const mapRef = React.useRef<Map>();
    const [mapRefCurr, setMapRefCurr] = React.useState(mapRef.current);
    const [aPlaced, setAPlaced] = React.useState(false);
    const [bPlaced, setBPlaced] = React.useState(false);
    /* let mapself: boolean = false; */

   /*  console.log("props: ", props); */

    /*  mapRef.current = map; */
    // const mapsAssistant_layerImages = useAppSelector((state) => state.guicontroller.value.mapsAssistant_layers);
    const mapsAssistant_layerImages_index = useAppSelector((state) => state.guicontroller.value.mapsAssistant_layers_index);
    const mapsPixelA = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_A);
    const mapsPixelB = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_B);

    /* const mapsFiles = useAppSelector((state) => state.guicontroller.value.mapsFiles); */

    const apiUrl: string = getBaseUrl(); // http://api.creator.local | https://creator.guide3d.com/

    const iconFeatureA = new Feature({
        geometry: new Point([0, 0]),
        name: 'PinA',
    });


    const iconFeatureB = new Feature({
        geometry: new Point([0, 0]),
        name: 'PinB',
    });

    const iconStyleA = new Style({
        image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'https://creator.guide3d.com/data/pin_a.png',
        }),
    });
    const iconStyleB = new Style({
        image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'https://creator.guide3d.com/data/pin_b.png',
        }),
    });

    iconFeatureA.setStyle(iconStyleA);
    iconFeatureB.setStyle(iconStyleB);


    const vectorSourceA = new VectorSource({
        features: [iconFeatureA],
    });

    const vectorLayerA = new VectorLayer({
        source: vectorSourceA,
    });

    const vectorSourceB = new VectorSource({
        features: [iconFeatureB],
    });

    const vectorLayerB = new VectorLayer({
        source: vectorSourceB,
    });

    /*  useEffect(() => {
         if (mapRef.current !== undefined) {
             console.log("mapRef.current: ", mapRef.current);
         }
         else{
             console.log("mapRef.current UNDEFINED: ", mapRef.current);
         }
       }, []); */

    /* useEffect(() => {
      console.log("mapRefCurr: ", mapRefCurr);        
    }, []); */

    useEffect(() => {
        /* console.log("assistNumber in ReferenceMap: ", assistNumber);
        console.log("aPlaced in ReferenceMap: ", aPlaced);
        console.log("bPlaced in ReferenceMap: ", bPlaced); */
        if (assistNumber === 7) {
            if (mapRef.current) {
                if (aPlaced === false) {
                    setAPlaced(true);
                    // Der Layer wurde noch nicht hinzugefügt, fügen Sie ihn zur Karte hinzu
                    iconFeatureA.setGeometry(new Point(mapRef.current.getView().getCenter() as Coordinate))
                    mapRef.current.addLayer(vectorLayerA);

                    const modify = new Modify({
                        hitDetection: vectorLayerA,
                        source: vectorSourceA,
                    });
                    modify.on(['modifystart', 'modifyend'], (evt: any) => {
                        if (ref.current !== null) {
                            ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';

                            if (evt.type === 'modifyend') {
                                const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                // const modifiedNormalizedCoordinatesA: [number, number] = [(modifiedCoordinates[0] - (8192 / 2)), (modifiedCoordinates[1] - (8192 / 2))];
                                dispatch(setCurrentGuiController_mapsPixelCoordinates_A(modifiedCoordinates));
                            }
                        }
                    });

                    mapRef.current?.addInteraction(modify);
                } else {
                    /* console.log("Der Layer wurde bereits zur Karte hinzugefügt."); */
                }
            }
        }
        if (assistNumber === 8) {
            /* console.log("assistNumber: ", assistNumber);
            console.log("mapsPixelB: ", mapsPixelB);
            console.log("bPlaced: ", bPlaced);
            console.log("mapsPixelA: ", mapsPixelA);
            console.log("aPlaced: ", aPlaced);
            console.log("mapRef.current: ", mapRef.current); */
            if (mapRef.current) {
                if (mapsPixelB[0] === -1 || mapsPixelB[1] === -1) {
                    if (bPlaced === false) {
                        setBPlaced(true);
                        iconFeatureB.setGeometry(new Point(mapRef.current.getView().getCenter() as Coordinate))
                        mapRef.current.addLayer(vectorLayerB);

                        const modify = new Modify({
                            hitDetection: vectorLayerB,
                            source: vectorSourceB,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates();
                                    // const modifiedNormalizedCoordinatesB: [number, number] = [(modifiedCoordinates[0] - (8192 / 2)), (modifiedCoordinates[1] - (8192 / 2))];
                                    dispatch(setCurrentGuiController_mapsPixelCoordinates_B(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                }
                if (mapsPixelA[0] !== -1 && mapsPixelA[1] !== -1 && mapsPixelB[0] !== -1 && mapsPixelB[1] !== -1) {
                    if (aPlaced === false) {
                        setAPlaced(true);
                        // Der Layer wurde noch nicht hinzugefügt, fügen Sie ihn zur Karte hinzu
                        // iconFeatureA.setGeometry(new Point([mapsPixelA[0] + (mapsFiles.files[0].png.width / 2), mapsPixelA[1] + (mapsFiles.files[0].png.width / 2)] as Coordinate))
                        iconFeatureA.setGeometry(new Point([mapsPixelA[0], mapsPixelA[1]] as Coordinate))
                        mapRef.current.addLayer(vectorLayerA);

                        const modify = new Modify({
                            hitDetection: vectorLayerA,
                            source: vectorSourceA,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                    // console.log('Modifizierte Pixel-Koordinaten A:', modifiedCoordinates);
                                    // const modifiedNormalizedCoordinatesA: [number, number] = [(modifiedCoordinates[0] - (8192 / 2)), (modifiedCoordinates[1] - (8192 / 2))];
                                    dispatch(setCurrentGuiController_mapsPixelCoordinates_A(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                    if (bPlaced === false) {
                        setBPlaced(true);
                        // iconFeatureB.setGeometry(new Point([mapsPixelB[0] + (mapsFiles.files[0].png.width / 2), mapsPixelB[1] + (mapsFiles.files[0].png.width / 2)] as Coordinate))
                        iconFeatureB.setGeometry(new Point([mapsPixelB[0], mapsPixelB[1]] as Coordinate))
                        mapRef.current.addLayer(vectorLayerB);

                        const modify = new Modify({
                            hitDetection: vectorLayerB,
                            source: vectorSourceB,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates();
                                    console.log('Modifizierte Pixel-Koordinaten B:', modifiedCoordinates);
                                    // const modifiedNormalizedCoordinatesB: [number, number] = [(modifiedCoordinates[0] - (8192 / 2)), (modifiedCoordinates[1] - (8192 / 2))];
                                    dispatch(setCurrentGuiController_mapsPixelCoordinates_B(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                    mapRef.current.once('loadend', () => {
                        setTimeout(() => {
                            // mapRef.current?.getView().fit(boundingExtent([[mapsPixelA[0] + (mapsFiles.files[0].png.width / 2), mapsPixelA[1] + (mapsFiles.files[0].png.width / 2)], [mapsPixelB[0] + (mapsFiles.files[0].png.width / 2), mapsPixelB[1] + (mapsFiles.files[0].png.width / 2)]]), { padding: [50, 50, 50, 50], duration: 2000 });
                            mapRef.current?.getView().fit(boundingExtent([[mapsPixelA[0], mapsPixelA[1]], [mapsPixelB[0], mapsPixelB[1]]]), { padding: [50, 50, 50, 50], duration: 1000 });
                        }, 1000);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [assistNumber, mapRef, mapRefCurr, mapsPixelA, mapsPixelB]);


    useEffect(() => {
        /* console.log("useEffect ReferenceMap: ",mapsPixelA); */
        if (mapsPixelA[0] !== -1 && mapsPixelA[1] !== -1 && mapsPixelB[0] !== -1 && mapsPixelB[1] !== -1) {
            /* console.log("############### A"); */
            if (aPlaced === true) {
                /* console.log("############### B"); */
                iconFeatureA.getGeometry()?.setCoordinates([mapsPixelA[0], mapsPixelA[1]] as Coordinate);
            }
            if (bPlaced === true) {
                /* console.log("############### C"); */
                iconFeatureB.getGeometry()?.setCoordinates([mapsPixelB[0], mapsPixelB[1]] as Coordinate);
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [mapsPixelA, mapsPixelB]);

    useEffect(() => {
        /* console.log("ref A use called!!!!"); */
        /* console.log("mapsFiles: ", mapsFiles);
        console.log("mapsAssistant_layerImages_index: ", mapsAssistant_layerImages_index); */
        const newlayerSource = new ImageStatic({
            url: '',
            projection: new Projection({
                code: 'xkcd-image',
                units: 'pixels',
                extent: [0, 0, (8192), (8192)],
            }),
            imageExtent: [0, 0, (8192), (8192)],
            imageLoadFunction: (image: any) => {
                image.getImage().src = apiUrl + props?.referenceProp.mapFiles.files[mapsAssistant_layerImages_index].png.thumbnail;

            }
        });


        const coll: Collection<BaseLayer> = mapRef.current?.getLayers() as Collection<BaseLayer>;
        if (mapRef.current?.getLayers()) {
            coll.forEach((element: any) => {
                if (element instanceof ImageLayer === true) {
                    element.setSource(newlayerSource);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [mapRef, mapsAssistant_layerImages_index]);

    useEffect(() => {
        /* console.log("ref B use called!!!!", mapsFiles); */
        const extent = [0, 0, (8192), (8192)];
        const imageProjection = new Projection({
            code: 'xkcd-image',
            units: 'pixels',
            extent: extent,
        });

        if (ref.current && !mapRef.current) {
            /* console.log("CREATING NEW MAP HERE..."); */
            mapRef.current = new Map({
                layers: [new ImageLayer({
                    source: new ImageStatic({
                        url: '',
                        projection: new Projection({
                            code: 'xkcd-image',
                            units: 'pixels',
                            extent: [0, 0, (8192), (8192)],
                        }),
                        imageExtent: [0, 0, (8192), (8192)],
                        imageLoadFunction: (image: any) => {
                            image.getImage().src = apiUrl + props?.referenceProp.mapFiles.files[mapsAssistant_layerImages_index].png.thumbnail;

                        }
                    })
                })],
                view: new View({
                    projection: imageProjection,
                    center: getCenter(extent),
                    zoom: 2,
                    maxZoom: 8,
                }),
                target: ref.current,
                controls: [new Attribution(), new Fullscreen(), new Zoom()],
                interactions: defaults({
                    altShiftDragRotate: false
                }),
            });
            setMapRefCurr(mapRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [ref, mapRef, mapsAssistant_layerImages_index]);

    return (
        <div style={{ height: '500px', width: '500px' }} className="map-container" ref={ref}></div>
    );
}

export default ReferenceMap;

