import { iAreasData, BuildAreaIngredients, svgToGeoTransformPropsObject } from "../interfaces/area_interfaces";
import { GeoPointObject } from "../interfaces/commonInterfaces";
import { Feature } from "ol";
import { Polygon } from "ol/geom";



export class AreasHelper {
  svgLongitudeConstant;
  svgLatitudeConstant;
  svgLongitudeSlope;
  svgLatitudeSlope;
  svgXRotationPoint;
  svgYRotationPoint;
  svgRotationAngle;

  constructor(svgToGeoTransformProperties: svgToGeoTransformPropsObject) {
    const { longitudeConstant, latitudeConstant, longitudeSlope, latitudeSlope, xRotationPoint, yRotationPoint, angle } = svgToGeoTransformProperties;
    this.svgLongitudeConstant = longitudeConstant;
    this.svgLatitudeConstant = latitudeConstant;
    this.svgLongitudeSlope = longitudeSlope;
    this.svgLatitudeSlope = latitudeSlope;
    this.svgXRotationPoint = xRotationPoint
    this.svgYRotationPoint = yRotationPoint;
    this.svgRotationAngle = angle;

  }


// ANCHOR Process Net Points
  public getNetFeatureCollections(pointObjects: GeoPointObject[], layerArray: string[]) {
    const netPointLayer: object[] = [];
    layerArray.forEach(layer => {
      const filteredPoints = pointObjects.filter(pointObject => pointObject.id.substring(0, 3) === layer);
      netPointLayer.push(this.BuildPointGeoJSON(filteredPoints));
    });
    return netPointLayer
  }


  public BuildPointGeoJSON(pointObjects: GeoPointObject[]) {
    const featureArray: object[] = [];
    pointObjects.forEach(pO => {
      featureArray.push(
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [pO.position.longitude, pO.position.latitude]
          },
          "properties": {
            "pointID": pO.id
          }
        }
      )
    });
    const geoJsonData = {
      "type": "FeatureCollection",
      "features": featureArray
    }
    return geoJsonData
  }


// ANCHOR Process SVG
  public parseSVGContent(fileContent: string, data: BuildAreaIngredients) {
    
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(fileContent, "image/svg+xml");
    let highlightersLayer = svgDoc.querySelector('g[id*="Highlighters"]');
    if (!highlightersLayer) highlightersLayer = svgDoc.querySelector('g[id*="highlighters"]');
    let highlightersData: iAreasData[] = [];

    if (highlightersLayer) {
      if (highlightersData.length === 0) {
        highlightersData = this.buildHighlighter(highlightersLayer, data)
      }

      if (highlightersData) {
        return highlightersData;
      }
    } else {
      console.error('No highlighters section found');
    }
  }


  public buildHighlighter(highlightersLayer: Element, data: BuildAreaIngredients): iAreasData[] {
    const subLayers = highlightersLayer.querySelectorAll("g");
    const polygonData: iAreasData[] = []
    subLayers.forEach(subLayer => {
      const svgLayerId = subLayer.getAttribute("id");
      if (!svgLayerId) {
        console.error('no id attribute');
        return
      }
      const polygons = subLayer.querySelectorAll("polygon");
      if (!polygons) {
        console.error('no polygons found');
        return
      }
      polygons.forEach((polygon, index) => {
        const polygonId = polygon.getAttribute("id");
        let highlighter;
        if (polygonId) {
          highlighter = this.buildPolygonObject(polygonId, index, data.netPoints, polygon, svgLayerId, data.mapLayers, data.selectedLayerIndex);
        } else {
          highlighter = this.buildPolygonObject('area_', index, data.netPoints, polygon, svgLayerId, data.mapLayers, data.selectedLayerIndex);
        }
        polygonData.push(highlighter);
      });
    });
    return polygonData;
  }


  public buildPolygonObject(polygonId: string, index: number, netIds: string[], polygon: SVGPolygonElement, svgLayerId: string, mapLayers: string[], selectedLayerIndex: number): iAreasData {
    const cleanPolygonId = this.searchForPointId(polygonId)
    let match = netIds.filter(point => point === cleanPolygonId).length > 0 || false;
    const highlighter: iAreasData = {
      name: match ? cleanPolygonId + '_' + index : polygonId,
      uID: cleanPolygonId + '_' + index,
      wayPoint: cleanPolygonId || '',
      coordinates: this.convertPolyStringToRealPolyGeoCoords(polygon.getAttribute("points")),
      match: match,
      layer: match ? cleanPolygonId.substring(0, 3) : this.validateLayer(svgLayerId, mapLayers, selectedLayerIndex),
    }
    return highlighter
  }

  
  public validateLayer(layerFromSvg: string, mapLayers: string[], selectedLayerIndex: number): string {
    if (mapLayers.includes(layerFromSvg.substring(0, 3))) {
      return mapLayers[mapLayers.indexOf(layerFromSvg.substring(0, 3))];
    } else {
      return mapLayers[selectedLayerIndex]
    }
  }


  public searchForPointId(id: string): string {
    const regex = /L\d{2}P\d{4}/;
    if (id.search(regex) > -1) {
      return id.substring(id.search(regex), id.search(regex) + 8);
    } else {
      return id.substring(0, 12) + '...'
    }
  }


  public convertPolyStringToRealPolyGeoCoords(polygonString: string | null): [number, number][] {
    let coordinates: [number, number][] = [];
    if (!polygonString) {
      return coordinates;
    }
    const cleanString = polygonString.trim().replace(/\s+/g, ' ');
    if (cleanString.indexOf(',') > -1 ) {
      coordinates = this.processCommaSeperatedPairs(cleanString);
    } else {
      coordinates = this.processCoordStringWithoutComma(cleanString);
    }
    const polyCoords = this.checkIfFirstCoordEqualsLast(coordinates)
    return polyCoords
  }


  private processCommaSeperatedPairs(cleanString: string) {
    let coordinates: [number, number][] = [];
    cleanString.split(' ').forEach(pair => {
      const pairArray = pair.split(',');
      const coordsArray: [number, number] = [Number(pairArray[0]), Number(pairArray[1])]
      const coordsRotated = this.rotateSvgPoints(coordsArray);
      const geoCoords = this.svgAreasToGeoCoords(coordsRotated)
      coordinates.push(geoCoords);
    });
    return coordinates
  }


  private processCoordStringWithoutComma(cleanString: string) {
    let coordinates: [number, number][] = [];
    const stringArray = cleanString.split(' ')   
    for (let i = 0; i < stringArray.length-1; i+=2) {
      const coordsArray: [number, number] = [Number(stringArray[i]), Number(stringArray[i+1])];
      const coordsRotated = this.rotateSvgPoints(coordsArray);
      const geoCoords = this.svgAreasToGeoCoords(coordsRotated)
      coordinates.push(geoCoords);
    }
    return coordinates;
  }


  public rotateSvgPoints(svgCoords: [number, number]):[number, number] {
    const [x, y] = svgCoords
    const newX = this.svgXRotationPoint + (x - this.svgXRotationPoint) * Math.cos(this.svgRotationAngle) - (y - this.svgYRotationPoint) * Math.sin(this.svgRotationAngle);
    const newY = this.svgYRotationPoint + (x - this.svgXRotationPoint) * Math.sin(this.svgRotationAngle) + (y - this.svgYRotationPoint) * Math.cos(this.svgRotationAngle);
    return [newX, newY]
  }


  public svgAreasToGeoCoords(pixelCoords: [number, number]): [number, number] {
  
    let rotatedPixelCoords: number[] = [...pixelCoords]
    let geoCoords: [number, number] = [0, 0];
    geoCoords[0] = (rotatedPixelCoords[0] * this.svgLongitudeSlope) + this.svgLongitudeConstant;
    geoCoords[1] = (rotatedPixelCoords[1] * this.svgLatitudeSlope) + this.svgLatitudeConstant;

    return geoCoords;
  }


  public checkIfFirstCoordEqualsLast(coords: [number, number][]): [number, number][]{
    const lastInArray = coords.length - 1;
    if (coords[0] !== coords[lastInArray]) {
      const newLast = coords[0]
      coords.push(newLast);
    }
    return coords
  }




  // ANCHOR Polygon olFeature conversion
  public convertToOLFeatures(areasData: iAreasData[], layerArray: string[]): Feature[][] {
    const featureArray: Feature[] = [];
    areasData.forEach((area, index) => {
      const properties = this.createFeaturePorpertiesObject(area.uID, area.wayPoint, area.match, area.layer)
      const coordinates: [number, number][][] = [area.coordinates];
      const polygon = new Polygon(coordinates);

      const olFeature = new Feature({
        geometry: polygon,
      });
      olFeature.setProperties(properties);
      featureArray.push(olFeature);
    });
    const featureArrayByLayers = this.getFeatureCollectionByLayer(featureArray,  layerArray);
    return featureArrayByLayers
  }


  createFeaturePorpertiesObject(uID: string, wayPoint: string, match: boolean, layer: string): object {
    return (
      {
        "uID": uID,
        "wayPoint": wayPoint,
        "match": match,
        "layer": layer
      }
    )
  }

  public getFeatureCollectionByLayer(areaFeatures: Feature[], layerArray: string[]): Feature[][] {
    const areasByLayer: Feature[][] = [];
    layerArray.forEach(layer => {
      const filteredByLayer: Feature[] = areaFeatures.filter(area => area.getProperties().layer === layer);
      areasByLayer.push(filteredByLayer)
    });
    return areasByLayer
  }


}