/// <reference path="../dts/Guide3DRouteService_V2.2.0.d.ts"/>

import { LayerObject, PointObject, GeoPointObject, ModeObject } from "../interfaces/commonInterfaces";



interface IRouteHelperOptions {
    "debug"?: boolean;
}

export class RouteHelper {
    private _routeService: Guide3DRouteService | undefined;
    private _routeServiceOptions: IGuide3DRouteServiceOptions | undefined;
    private _bInitSuccess: boolean = false;
    private DEBUG: boolean = false;

    constructor(public oMyClassTemplateOptions: IRouteHelperOptions = {}) {

        this.DEBUG = !!oMyClassTemplateOptions.debug;

    }

    public initRouteService(projectID: number) {

        const routeServiceCallback = (oEvent: IGuide3DRouteServiceEventLoaded) => {

            /* console.log("routeServiceCallback: ", oEvent); */

            switch (oEvent.info) {
                case "Guide3D Route Service V2.2.0":
                    console.log(oEvent.info + " / last modified: " + oEvent.lastModified);
                    this._bInitSuccess = true;
                    break;
                default:
                    console.log("Unsupported Guide3D Route Service event " + oEvent.info);
            }
        };

        this._routeServiceOptions = {
            "debug": false,
            "format": Guide3DRouteService.FORMAT_NONE,
            "redirect": Guide3DRouteService.REDIRECT_MODE_DURATION,
            "onload": routeServiceCallback,
            "preload-url": "./data/Guide3D-RoutePreload-" + projectID + "_V1.0.json",  // optional
            // "service-url": "./res/php/services.guide3d.com/route/index.php"  // cors support
        };

        this._routeService = new Guide3DRouteService(this._routeServiceOptions);
        this._routeService.setFormat(Guide3DRouteService.FORMAT_X3D);
    }

    public getInitReady(): boolean {
        return this._bInitSuccess;
    }

    public getAllPoints(): string[] {
        return (this._routeService?.retrieve(Guide3DRouteService.TYPE_POINT) as string[]);
    }

    public getAllLinks(): string[] {
        return (this._routeService?.retrieve(Guide3DRouteService.TYPE_LINK) as string[]);
    }

    public getModesForLink(linkId: string): ModeObject {
        let modesInfos: ModeObject = {
            m0000: false,
            m0001: false
        }
        switch (this.getLinkType(linkId)) {
            case "default":
                modesInfos.m0000 = true;
                modesInfos.m0001 = true;
                break;
            case "other":
                modesInfos.m0000 = true;
                modesInfos.m0001 = true;
                break;
            case "stair-up":
                modesInfos.m0000 = true;
                modesInfos.m0001 = false;
                break;
            case "stair-down":
                modesInfos.m0000 = true;
                modesInfos.m0001 = false;
                break;
            case "escalator-up":
                modesInfos.m0000 = true;
                modesInfos.m0001 = false;
                break;
            case "not-escalator-up":
                modesInfos.m0000 = false;
                modesInfos.m0001 = false;
                break;
            case "not-escalator-down":
                modesInfos.m0000 = false;
                modesInfos.m0001 = false;
                break;
            case "escalator-down":
                modesInfos.m0000 = true;
                modesInfos.m0001 = false;
                break;
            case "lift-up":
                modesInfos.m0000 = false;
                modesInfos.m0001 = true;
                break;
            case "lift-down":
                modesInfos.m0000 = false;
                modesInfos.m0001 = true;
                break;
            case "ramp-up":
                modesInfos.m0000 = false;
                modesInfos.m0001 = true;
                break;
            case "ramp-down":
                modesInfos.m0000 = false;
                modesInfos.m0001 = true;
                break;
            case "disabled":
                modesInfos.m0000 = false;
                modesInfos.m0001 = false;
                break;
            case "closed":
                modesInfos.m0000 = false;
                modesInfos.m0001 = false;
                break;
            default:
                modesInfos.m0000 = false;
                modesInfos.m0001 = false;
                break;
        }
        return modesInfos;
    }

    public getAllPointObjects(): PointObject[] {
        let pointObjectList: PointObject[] = [];
        const allPointIds: string[] = this.getAllPoints();
        allPointIds.forEach(pointId => {
            let pointObject: PointObject = {
                id: pointId,
                position: (this._routeService?.getPointPosition(pointId, Guide3DRouteService.FORMAT_X3D) as IPositionX3dType),
                links: (this._routeService?.retrievePointLinks(pointId) as string[])
            }
            pointObjectList.push(pointObject);
        });
        return pointObjectList;
    }

    public getGeoPointObjects(): GeoPointObject[] {
        let pointObjectList: GeoPointObject[] = [];
        const allPointIds: string[] = this.getAllPoints();
        allPointIds.forEach(pointId => {
            let pointObject: GeoPointObject = {
                id: pointId,
                position: (this._routeService?.getPointPosition(pointId, Guide3DRouteService.FORMAT_GEO) as IPositionGeoType),
            }
            pointObjectList.push(pointObject);
        });
        return pointObjectList;
    }

    public getLinksForPoint(id: string): string[] {
        let links: string[] = [];
        const retrievedLinks: string[] = this._routeService?.retrievePointLinks(id) as string[];
        if (retrievedLinks.length > 0) {
            retrievedLinks.forEach(element => {
                links.push(element);
            });
        }
        return links;
    }

    public getPointTypeColor(id: string): string {
        let colorString: string = "";
        let bPointTerminal: boolean = false;
        bPointTerminal = this._routeService?.isPointTerminal(id) as boolean;
        bPointTerminal ? colorString = "#0139FF" : colorString = "#4FCA23";
        return colorString;
    }

    public addLink(sLinkId: string) {
        this._routeService?.addLink(sLinkId);
    }

    public deleteLink(sLinkId: string) {
        this._routeService?.removeLink(sLinkId);
    }

    public deletePoint(sPointId: string) {
        this._routeService?.removePoint(sPointId);
    }

    public checkPath(from: string, to: string): string[] | undefined {
        let routeList: string[] | undefined = this._routeService?.findPath(from, to);
        return routeList;
    }

    public export() {
        this._routeService?.export();
    }

    public getPointPositionForId(id: string): IPositionX3dType {
        const pointPosition: IPositionX3dType = (this._routeService?.getPointPosition(id, Guide3DRouteService.FORMAT_X3D) as IPositionX3dType);
        return pointPosition;
    }

    public getGeoPointPositionForId(id: string): IPositionX3dType {
        const pointPosition: IPositionX3dType = (this._routeService?.getPointPosition(id, Guide3DRouteService.FORMAT_GEO) as IPositionX3dType);
        return pointPosition;
    }

    public setPointPositionForId(id: string, pos: IPositionX3dType) {
        // const pointPosition: IPositionX3dType = (this._routeService?.getPointPosition(id, Guide3DRouteService.FORMAT_X3D) as IPositionX3dType);
        this._routeService?.setPointPosition(id, Guide3DRouteService.FORMAT_X3D, pos);
    }

    public getAllLayers(): string[] {
        return (this._routeService?.retrieve(Guide3DRouteService.TYPE_LAYER) as string[]);
    }

    public getAllLayerObjects(): LayerObject[] {
        let layerObjectList: LayerObject[] = [];
        const allLayerIds: string[] = this.getAllLayers();
        allLayerIds.forEach(layerId => {
            let layerObject: LayerObject = {
                height: (this._routeService?.getLayerHeight(layerId) as number)
            }
            layerObjectList.push(layerObject);
        });
        return layerObjectList;
    }

    public getLinkType(linkId: string): string {
        const linkType: string = this._routeService?.getLinkType(linkId) as string;
        return linkType;
    }

    public setLinkType(linkId: string, linkType: string) {
        this._routeService?.setLinkType(linkId, linkType);
    }

    public getX3dPositionFromSVGCoords(pixelCoords: number[]): number[] {
        /* 
        oConvertedCoordinates = {
                    x: (oSvgPointPosition.x - this._oPositionTransform.x3dToSvg.xConstant) / this._oPositionTransform.x3dToSvg.xSlope,
                    y: oSvgPointPosition.layer,
                    z: (oSvgPointPosition.y - this._oPositionTransform.x3dToSvg.yConstant) / this._oPositionTransform.x3dToSvg.ySlope,
                };
        */
        let x3dCoords: number[] = []
        const xConstant: number = this._routeService?.getX3dToSvgTransformProperty("xConstant") as number;
        const xSlope: number = this._routeService?.getX3dToSvgTransformProperty("xSlope") as number;
        const yConstant: number = this._routeService?.getX3dToSvgTransformProperty("yConstant") as number;
        const ySlope: number = this._routeService?.getX3dToSvgTransformProperty("ySlope") as number;

        x3dCoords[0] = (pixelCoords[0] - xConstant) / xSlope;
        x3dCoords[1] = (pixelCoords[1] - yConstant) / ySlope;

        console.log("x3dCoords(" + pixelCoords[0] + "/" + pixelCoords[1] + ") => " + x3dCoords[0] + " / " + x3dCoords[1]);

        return x3dCoords;
    }



    public getSvgToGeoTransformProperties() {
        return (
            {
                longitudeConstant: this._routeService?.getSvgToGeoTransformProperty("longitudeConstant") as number,
                latitudeConstant: this._routeService?.getSvgToGeoTransformProperty("latitudeConstant") as number,
                longitudeSlope: this._routeService?.getSvgToGeoTransformProperty("longitudeSlope") as number,
                latitudeSlope: this._routeService?.getSvgToGeoTransformProperty("latitudeSlope") as number,
                xRotationPoint: this._routeService?.getSvgToGeoTransformProperty("xRotationPoint") as number,
                yRotationPoint: this._routeService?.getSvgToGeoTransformProperty("yRotationPoint") as number,
                angle: this._routeService?.getSvgToGeoTransformProperty("angleRotation") as number,
            }
        )
    }



    public degrees_to_radians = (radians: number): number => {
        /* const pi = Math.PI; */
        return radians * (Math.PI / 180);
    }

}