import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import CancelIcon from '@mui/icons-material/Cancel';
/* import SaveIcon from '@mui/icons-material/Save'; */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
/* import DeleteIcon from '@mui/icons-material/Delete'; */
import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import mainlogo from '../res/svg/creatorMainLogo.svg';
import { useAppDispatch, useAppSelector } from './hooks';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import DropDownAvatarMenu from './DropDownAvatarMenu';
import DropDownProjectsMenu from './DropDownProjectsMenu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getI18n } from 'react-i18next';
import Dashboard from './Dashboard';
import { resetCurrentAdminEditUser } from './slices/currentAdminEditUserSlice';
import ToolMaps from './projectTools/tool_maps';
import ToolRoutes from './projectTools/tool_routes';
import ToolAreas from './projectTools/tool_areas';
import ToolDestinations from './projectTools/tool_destinations';
import ToolSignage from './projectTools/tool_signage';
import ToolHomescreen from './projectTools/tool_homescreen';
import ToolStyle from './projectTools/tool_style';
import ToolDeployment from './projectTools/tool_deployment';
import ToolUsers from './adminTools/tool_users';
import ToolProjects from './adminTools/tool_projects';
import ToolRoles from './adminTools/tool_roles';
import ToolGroups from './adminTools/tool_groups';
import { setCurrentGuiController_currentAdminMode, setCurrentGuiController_currentProject, setCurrentGuiController_currentProjectTool, setCurrentGuiController_mapsAssistant, setCurrentGuiController_mapsManualSetup } from './slices/guiControllerSlice';
import { resetCurrentAdminEditProject } from './slices/currentAdminEditProjectSlice';
import ProjectInfos from './ProjectInfos';
import DashboardAdmin from './adminTools/adminComponents/DashboardAdmin';

const drawerWidth = 240;

const mainIconArray = ["layers", "rebase_edit", "auto_awesome_mosaic", "format_list_bulleted_add", "format_size", "center_focus_strong", "brush", "published_with_changes"];
const adminIconArray = ["manage_accounts", "folder", "school", "groups"];



const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  hidden?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    marginTop: 64,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}));



export default function MiniDrawer() {
  /* const theme = useTheme(); */
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedButton, setSelected] = React.useState(-1);
  const [goingToAdmin, setGoingToAdmin] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [exitAdmin, setExitAdmin] = React.useState(false);
  const [toolUsed, setToolUsed] = React.useState(false);
  const [adminMode, setAdminMode] = React.useState(false);
  const [toolNumber, setToolNumber] = React.useState(-1);
  const [toolNumberPre, setToolNumberPre] = React.useState(-1);
  /* const [hidden, setHidden] = React.useState(false); */
  // const guiController = useAppSelector((state) => state.guicontroller.value);
  const guiStatus = useAppSelector((state) => state.guicontroller.value.guiStatus);
  const globalLoader = useAppSelector((state) => state.guicontroller.value.modalLoading);
  const userStatus = useAppSelector((state) => state.guicontroller.value.currentUser);
  const roleStatus = useAppSelector((state) => state.rolestatus.value);
  /* const currentProject = useAppSelector((state) => state.currentproject.value); */
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode);
  // const currentAdminMode = useAppSelector((state) => state.currentadminmode.value) 


  React.useEffect(() => {
    /* console.log("WTF setCurrentGuiController_currentProjectTool: " + selectedButton); */
    dispatch(setCurrentGuiController_currentProjectTool(selectedButton));
    dispatch(resetCurrentAdminEditUser());
    dispatch(resetCurrentAdminEditProject());
  }, [selectedButton, currentProject, dispatch]);

  React.useEffect(() => {
    /* setToolUsed(false);
    setSelected(-1);
    setToolNumber(-1);
    setToolNumberPre(-1); */
    setOpen(false);
  }, [currentProject])

  React.useEffect(() => {
    if (userStatus.uuid !== "0") {
      const i18 = getI18n();
      const currentLanguage: string = i18.language;
      if (userStatus.language !== currentLanguage) {
        i18.changeLanguage(userStatus.language);
      }
    }
  }, [userStatus]);

  React.useEffect(() => {
    if (guiStatus === "login") {
      console.log("process.env.REACT_APP_PATH_TO_API: ", process.env.REACT_APP_PATH_TO_API);

      setOpen(false);
      setSelected(-1);
      let lang: string = (navigator.language).substring(0, 2);
      const i18 = getI18n();
      const currentLanguage: string = i18.language;
      if (lang !== currentLanguage) {
        i18.changeLanguage(lang);
      }
    }
    if (guiStatus === "logout") {
      setAdminMode(false);
      dispatch(setCurrentGuiController_currentProject(0));
      dispatch(setCurrentGuiController_mapsAssistant(false));
      dispatch(setCurrentGuiController_mapsManualSetup(false));

      setToolUsed(false);
      setSelected(-1);
      setToolNumber(-1);
      setToolNumberPre(-1);
      setOpen(false);
      dispatch(setCurrentGuiController_currentAdminMode(0));
      dispatch(resetCurrentAdminEditUser());
    }
  }, [guiStatus, dispatch]);

  /* const randomfloatFromInterval = (min: number, max: number) => { // min and max included 
    return (Math.random() * (max - min + 1) + min);
  } */

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /* const handleCloseDialog = () => {
    if (goingToAdmin === true) {
      console.log("handleCloseDialog -> goingToAdmin === true");
      setGoingToAdmin(false);
      dispatch(setCurrentGuiController_currentProject(0));
      dispatch(resetCurrentAdminEditUser());
      setOpen(false);
      setAdminMode(true);
      dispatch(setCurrentGuiController_currentAdminMode(1));
      setToolUsed(false);
      setSelected(-1);
      setToolNumber(-1);
      setToolNumberPre(-1);
    }
    if (exitAdmin === true) {
      console.log("handleCloseDialog -> exitAdmin === true");
      setToolUsed(false);
      setSelected(-1);
      setToolNumber(-1);
      setToolNumberPre(-1);
      setOpen(false);
      setAdminMode(false);
      dispatch(setCurrentGuiController_currentAdminMode(0));
      dispatch(resetCurrentAdminEditUser());
    }
    if (goingToAdmin === false && exitAdmin === false) {
      setToolUsed(false);
      setSelected(toolNumber);
    }
  } */

  const handleSaveDialog = () => {
    if (goingToAdmin === true) {
      setShowLoader(true);
      setToolUsed(false);
      setTimeout(
        () => {
          setShowLoader(false);
          setGoingToAdmin(false);
          dispatch(setCurrentGuiController_currentProject(0));
          dispatch(resetCurrentAdminEditUser());
          setOpen(false);
          setAdminMode(true);
          dispatch(setCurrentGuiController_currentAdminMode(1));
          setToolUsed(false);
          setSelected(-1);
          setToolNumber(-1);
          setToolNumberPre(-1);
        }
        , 250);
    }
    if (exitAdmin === true) {
      setShowLoader(true);
      setToolUsed(false);
      setTimeout(
        () => {
          setShowLoader(false);
          setToolUsed(false);
          setSelected(-1);
          setToolNumber(-1);
          setToolNumberPre(-1);
          setOpen(false);
          setAdminMode(false);
          dispatch(setCurrentGuiController_currentAdminMode(0));
          dispatch(resetCurrentAdminEditUser());
        }
        , 250);
    }
    if (goingToAdmin === false && exitAdmin === false) {
      setShowLoader(true);
      setToolUsed(false);
      setTimeout(
        () => {
          setShowLoader(false);
          setToolUsed(false);
          setSelected(toolNumber);
        }
        , 250);
    }
  }

  const handleCancelDialog = () => {
    // do nothing, close modal only
    if (goingToAdmin === true) {
      setGoingToAdmin(false);
      setToolUsed(false);
    }
    if (exitAdmin === true) {
      setExitAdmin(false);
      setToolUsed(false);
    }
    if (goingToAdmin === false && exitAdmin === false) {
      setToolUsed(false);
    }
  };

  const handleAdminEnter = () => {
    if (adminMode === true) {
      // already admin mode
    }
    else {
      if (selectedButton === -1) {
        setGoingToAdmin(false);
        dispatch(setCurrentGuiController_currentProject(0));
        setOpen(false);
        setAdminMode(true);
        dispatch(setCurrentGuiController_currentAdminMode(1));
        setToolUsed(false);
        setSelected(-1);
        setToolNumber(-1);
        setToolNumberPre(-1);
      }
      else {
        setGoingToAdmin(true);
        checkForSaving();
      }
    }
  }

  const handleExitAdminClick = () => {
    if (selectedButton === -1) {
      setToolUsed(false);
      setSelected(-1);
      setToolNumber(-1);
      setToolNumberPre(-1);
      setOpen(false);
      setAdminMode(false);
      dispatch(setCurrentGuiController_currentAdminMode(0));
    }
    else {
      setExitAdmin(true);
      checkForSaving();
    }
  }

  const handleNotificationEnter = () => {
    checkForSaving();
  }

  const handleNotificationEnterAdmin = () => {
    checkForSavingAdmin();
  }

  const checkForSavingAdmin = () => {
    if (selectedButton === -1) {
      // mach nix
    }
    else {
      /* setToolUsed(true); */
      setSelected(-1);
      /* setToolNumber(-1);
      setToolNumberPre(selectedButton); */
      /* dispatch(setCurrentGuiController_mapsManualSetup(false));
      dispatch(setCurrentGuiController_mapsAssistant(false)); */
    }
  }

  const checkForSaving = () => {
    if (selectedButton === -1) {
      // mach nix
    }
    else {
      setToolUsed(true);
      setToolNumber(-1);
      setToolNumberPre(selectedButton);
      /* dispatch(setCurrentGuiController_mapsManualSetup(false));
      dispatch(setCurrentGuiController_mapsAssistant(false)); */
    }
  }


  const setSelectedHandler = (num: number) => {
    /* console.log("setSelectedHandler(" + num + ")"); */
    /* console.log("select num: " + num);
    console.log("pre select num: " + selectedButton); */
    if (getDisabledStatus(num) === false) {
      if (selectedButton === -1) {
        setSelected(num);
        setToolNumber(num);
      }
      else {
        setToolUsed(true);
        setToolNumber(num);
        setToolNumberPre(selectedButton);
      }
    }
  }

  const getDisabledStatus = (num: number): boolean => {
    let isDisabled: boolean = false;
    if (adminMode === false) {
      const toolSwitch: number = roleStatus.tools[num];
      if (toolSwitch === 0) {
        isDisabled = true;
      }
      const disabledTools: number[] = [3, 5, 6];
      if (disabledTools.indexOf(num) >= 0) {
        isDisabled = true;
      }
    }
    return isDisabled;
  }

  const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
      color: '#ffffff',
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      background: 'transparent linear-gradient(180deg, #24A3D333 0%, #12526A 100%) 0% 0% no-repeat padding-box;'
    }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} sx={{
        position: 'absolute',
        width: '100vw',
        marginLeft: 0,
        background: '#000000',
      }}>
        <Toolbar sx={{
          marginLeft: 0,
          paddingLeft: '10px !important',
        }}>
          {React.createElement('img', { src: mainlogo })}
          {
            (guiStatus === "login_success" && adminMode === false)
              ?
              <Container sx={{
                display: 'flex',
                marginLeft: 0,
                paddingLeft: 0,
              }}>
                <DropDownProjectsMenu></DropDownProjectsMenu>
                {(currentProject !== 0 && adminMode === false)
                  ?
                  <NotificationsIcon sx={{
                    width: '40px',
                    height: 'auto',
                    marginBottom: '3px',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    cursor: 'pointer',
                    backgroundColor: selectedButton === -1 ? 'secondary.main' : 'unset',
                    color: selectedButton === -1 ? 'secondary.contrastText' : 'unset',
                  }} onClick={handleNotificationEnter}></NotificationsIcon>
                  : null}
              </Container>
              : null
          }
          {(guiStatus === "login_success" && adminMode === true)
            ?
            <Container sx={{
              display: 'flex',
              marginLeft: 0,
              paddingLeft: 0,
            }}>
              <Button
                id="basic-button"
                /* aria-controls={open ? 'basic-menu' : undefined} */
                aria-haspopup="true"
                /* aria-expanded={open ? 'true' : undefined} */
                onClick={handleExitAdminClick}
                sx={{
                  marginLeft: '50px',
                  color: 'primary.contrastText',
                  textTransform: 'unset !important',
                  fontSize: '1.3em',
                  fontWeight: 'normal'
                }}
              >
                {/* {userStatus.firstName} */}
                {"Administration"}
                <CloseIcon sx={{
                  paddingLeft: '5px',
                  width: '35px',
                  height: 'auto'
                }}></CloseIcon>
              </Button>
              <NotificationsIcon sx={{
                width: '40px',
                height: 'auto',
                marginBottom: '3px',
                marginLeft: '3em',
                paddingLeft: '6px',
                paddingRight: '6px',
                cursor: 'pointer',
                backgroundColor: selectedButton === -1 ? 'secondary.main' : 'unset',
                color: selectedButton === -1 ? 'secondary.contrastText' : 'unset',
              }} onClick={handleNotificationEnterAdmin}></NotificationsIcon>
            </Container>
            : null
          }

          {
            guiStatus === "login_success"
              ? <Container sx={{
                position: 'absolute',
                right: '0%',
                width: 'auto',
                display: 'flex',
                flexDirection: 'row'
              }}>
                <Avatar sx={{
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText'
                }}>
                  <PersonIcon />
                </Avatar>
                <DropDownAvatarMenu></DropDownAvatarMenu>
                {userStatus.firstname === "Björn"
                  ? <AdminPanelSettingsIcon sx={{
                    width: '40px',
                    height: 'auto',
                    marginBottom: '3px',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    cursor: 'pointer',
                    backgroundColor: adminMode === true ? 'secondary.main' : 'unset',
                    color: adminMode === true ? 'secondary.contrastText' : 'unset',
                  }} onClick={handleAdminEnter}></AdminPanelSettingsIcon>
                  : null
                }
              </Container>
              : null
          }
        </Toolbar>
      </AppBar>
      <>
        {
          (currentProject !== 0 && adminMode === false)
            ? <Drawer variant="permanent" open={open}
              PaperProps={{
                sx: {
                  boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.50); !important"
                }
              }}
            >
              <ListItem disablePadding sx={{ opacity: 0, backgroundColor: '#FF0000 !important', }}>
                <ListItemButton
                  sx={{
                    minHeight: 64,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <DrawerHeader>
                <IconButton onClick={handleDrawerOpen} sx={{
                  ...(open && { display: 'none' }),
                }}>
                  <ChevronRightIcon />
                </IconButton>
                <IconButton onClick={handleDrawerClose} sx={{
                  ...(!open && { display: 'none' }),
                }}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {['Maps', 'Routes', 'Areas', 'Destinations', 'Signage', 'Home screen', 'Style', 'Deployment'].map((text, index) => (
                  <HtmlTooltip key={"tooltip_" + index} placement="right"
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{t('sidebar.entry' + (index + 1) + "_tooltip_heading")}</Typography>
                        {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
                        {t('sidebar.entry' + (index + 1) + "_tooltip_text")}
                      </React.Fragment>
                    }
                  >
                    <ListItem key={text + "_" + index} disablePadding sx={{ display: 'block' }} onClick={() => {
                      setSelectedHandler(index)
                    }}>
                      <ListItemButton disabled={getDisabledStatus(index)}/* style={{backgroundColor: selectedButton===index ? 'primary.contrastText' : 'red'}} */
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          backgroundColor: selectedButton === index ? 'secondary.main' : 'unset',
                          '&:hover': {
                            backgroundColor: selectedButton === index ? 'secondary.main' : 'unset',
                          },
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            color: selectedButton === index ? 'secondary.contrastText' : 'unset',
                            justifyContent: 'center',
                          }}
                        >
                          {<Icon>{mainIconArray[index]}</Icon>}
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.entry' + (index + 1))} sx={{
                          opacity: open ? 1 : 0,
                          color: selectedButton === index ? 'secondary.contrastText' : 'unset',
                        }} />
                      </ListItemButton>
                    </ListItem>

                  </HtmlTooltip>
                ))}
              </List>
              <Dialog
                open={toolUsed}
                /* onClose={handleCloseDialog} */
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  sx: {
                    backgroundColor: 'info.main',
                    backgroundImage: 'unset',
                    top: '-11%',
                    left: '-20%',
                    width: '50%'
                  }
                }}
              >
                <DialogTitle id="alert-dialog-title" sx={{
                  backgroundColor: 'info.main',
                  color: 'success.main',
                }}>
                  {t('tools.heading_1') + " " + (t('sidebar.entry' + (toolNumberPre + 1))) + "" + t('tools.heading_2')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{
                    color: 'text.primary'
                  }}>
                    {t('tools.subtitle')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                  backgroundColor: 'info.main'
                }}>
                  <CssWhiteButton
                    onClick={handleSaveDialog}
                    /* type="submit" */
                    /* fullWidth */
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: 'primary.contrastText',
                      color: 'primary.main',
                      width: 'fit-content',
                      '&:hover': {
                        color: 'primary.contrastText',
                      },
                    }}
                  >
                    <CheckCircleIcon sx={{
                      marginRight: '6px'
                    }}></CheckCircleIcon>
                    {t('tools.save')}
                  </CssWhiteButton>
                  <CssWhiteButton
                    onClick={handleCancelDialog}
                    /* type="submit" */
                    /* fullWidth */
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: 'primary.contrastText',
                      color: 'primary.main',
                      width: 'fit-content',
                      '&:hover': {
                        color: 'primary.contrastText',
                      },
                    }}
                  >
                    <CancelIcon sx={{
                      marginRight: '6px'
                    }}></CancelIcon>
                    {t('tools.cancel')}
                  </CssWhiteButton>
                </DialogActions>
              </Dialog>
            </Drawer>
            : null
        }
        {
          (adminMode === true)
            ? <Drawer variant="permanent" open={open}
              PaperProps={{
                sx: {
                  boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.50); !important"
                }
              }}
            >
              <ListItem disablePadding sx={{ opacity: 0, backgroundColor: '#FF0000 !important', }}>
                <ListItemButton
                  sx={{
                    minHeight: 64,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <DrawerHeader>
                <IconButton onClick={handleDrawerOpen} sx={{
                  ...(open && { display: 'none' }),
                }}>
                  <ChevronRightIcon />
                </IconButton>
                <IconButton onClick={handleDrawerClose} sx={{
                  ...(!open && { display: 'none' }),
                }}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {['Users', 'Projects', 'Roles', 'Groups'].map((text, index) => (
                  <HtmlTooltip key={"tooltip_" + index} placement="right"
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{t('sidebar.admin_entry' + (index + 1) + "_tooltip_heading")}</Typography>
                        {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
                        {t('sidebar.admin_entry' + (index + 1) + "_tooltip_text")}
                      </React.Fragment>
                    }
                  >
                    <ListItem key={text + "_" + index} disablePadding sx={{ display: 'block' }} onClick={() => {
                      setSelectedHandler(index)
                    }}>
                      <ListItemButton disabled={getDisabledStatus(index)}/* style={{backgroundColor: selectedButton===index ? 'primary.contrastText' : 'red'}} */
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          backgroundColor: selectedButton === index ? 'secondary.main' : 'unset',
                          '&:hover': {
                            backgroundColor: selectedButton === index ? 'secondary.main' : 'unset',
                          },
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            color: selectedButton === index ? 'secondary.contrastText' : 'unset',
                            justifyContent: 'center',
                          }}
                        >
                          {<Icon>{adminIconArray[index]}</Icon>}
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.admin_entry' + (index + 1))} sx={{
                          opacity: open ? 1 : 0,
                          color: selectedButton === index ? 'secondary.contrastText' : 'unset',
                        }} />
                      </ListItemButton>
                    </ListItem>

                  </HtmlTooltip>
                ))}
              </List>
              <Dialog
                open={toolUsed}
                /* onClose={handleCloseDialog} */
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  sx: {
                    backgroundColor: 'info.main',
                    backgroundImage: 'unset',
                    top: '-11%',
                    left: '-20%',
                    width: '50%'
                  }
                }}
              >
                <DialogTitle id="alert-dialog-title" sx={{
                  backgroundColor: 'info.main',
                  color: 'success.main',
                }}>
                  {t('tools.heading_1') + " " + (t('sidebar.admin_entry' + (toolNumberPre + 1))) + "" + t('tools.heading_2')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{
                    color: 'text.primary'
                  }}>
                    {t('tools.subtitle')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                  backgroundColor: 'info.main'
                }}>
                  <CssWhiteButton
                    onClick={handleSaveDialog}
                    /* type="submit" */
                    /* fullWidth */
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: 'primary.contrastText',
                      color: 'primary.main',
                      width: 'fit-content',
                      '&:hover': {
                        color: 'primary.contrastText',
                      },
                    }}
                  >
                    <CheckCircleIcon sx={{
                      marginRight: '6px'
                    }}></CheckCircleIcon>
                    {t('tools.save')}
                  </CssWhiteButton>
                  <CssWhiteButton
                    onClick={handleCancelDialog}
                    /* type="submit" */
                    /* fullWidth */
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: 'primary.contrastText',
                      color: 'primary.main',
                      width: 'fit-content',
                      '&:hover': {
                        color: 'primary.contrastText',
                      },
                    }}
                  >
                    <CancelIcon sx={{
                      marginRight: '6px'
                    }}></CancelIcon>
                    {t('tools.cancel')}
                  </CssWhiteButton>
                </DialogActions>
              </Dialog>
            </Drawer>
            : null
        }
      </>


      <Box component="main" sx={{
        flexGrow: 1,
        p: 3,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: '4rem',
        height: '100vh'
      }}>
        {guiStatus === "login_success" && selectedButton === -1 && currentAdminMode === 0
          ?
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '90vh' }}>
            <Dashboard />
            {currentProject === 0
              ?
              null
              :
              <ProjectInfos projId={currentProject}></ProjectInfos>
            }
          </Box>
          : null
        }
        {guiStatus === "login_success" && currentProject !== 0
          ?
          <ToolMaps />
          :
          null
        }
        <ToolRoutes />
        <ToolAreas />
        <ToolDestinations />
        <ToolSignage />
        <ToolHomescreen />
        <ToolStyle />
        {guiStatus === "login_success" && currentProject !== 0
          ?
          <ToolDeployment />
          :
          null
        }
        {guiStatus === "login_success" && selectedButton === -1 && currentAdminMode === 1
          ?
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '90vh' }}>
            <DashboardAdmin />
          </Box>
          : null
        }

        <ToolUsers />
        <ToolProjects />
        <ToolRoles />
        <ToolGroups />
      </Box>
      <Modal
        open={showLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CircularProgress size='8rem' thickness={3} sx={{
          position: 'absolute',
          top: '40%',
          left: '45%',
          transform: 'translateY(-50%) translateX(50%)',
          color: 'success.main'
        }} />
      </Modal>
      <Modal
        open={globalLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CircularProgress size='8rem' thickness={3} sx={{
          position: 'absolute',
          top: '40%',
          left: '45%',
          transform: 'translateY(-50%) translateX(50%)',
          color: 'success.main'
        }} />
      </Modal>
    </Box>
  );
}